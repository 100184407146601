import React, { useEffect,useState } from "react";
import config from "../config";
import axios from "axios";
import { useNavigate,Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader"; 
import "./login.css";
import { Header } from "./header";
import { Aside } from "./aside";
import { Footer } from "./footer";
import CKEditor from "react-ckeditor-component";

function Addproductfile() {
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  const par = queryParams.get("folder");
  const ids = queryParams.get("ids");
  const index = queryParams.get("index");
  const parentTitle = queryParams.get("parentFloder");
  const navigate = useNavigate();
  let   [loadingInProgress, setLoading] = useState(false);
  const [users, setUsers] = useState("All Users");
  const [folder, setFolder] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const [productImage, setProductimage] = useState("");

  
  let handleSubmit =  (e) => {
    e.preventDefault();
    setLoading(true)
    
  
    if(par == "category"){
        let formData = new FormData(); //formdata object
        formData.append("file", productImage);
        formData.append("title", title);
        formData.append("showUser", users);
        formData.append("id", id);
        formData.append("description", description);
        axios.post(`${config.backend_URL}/addProductCat`, formData)
        .then( (data) => {
         
          setLoading(false)
          toast(data.data.msz);
          e.target.reset();
        }).catch((err) => {
          console.log("error", err);
        });
    }else if(par == "subcat"){
        let formData = new FormData(); //formdata object
    formData.append("file", productImage);
    formData.append("title", title);
    formData.append("showUser", users);
    formData.append("id", id);
    formData.append("ids", ids);
    formData.append("description", description);
        axios.post(`${config.backend_URL}/addProductSub`, formData)
        .then( (data) => {
         
          setLoading(false)
          toast(data.data.msz);
          e.target.reset();
        }).catch((err) => {
          console.log("error", err);
        });
    }else if(par == "finalcat"){
        //alert(par);
        let formData = new FormData(); //formdata object
    formData.append("file", productImage);
    formData.append("title", title);
    formData.append("showUser", users);
    formData.append("id", id);
    formData.append("ids", ids);
    formData.append("index", index);
    formData.append("description", description);
        axios.post(`${config.backend_URL}/addProductFinal`, formData)
        .then( (data) => {
         
          setLoading(false)
          toast(data.data.msz);
          e.target.reset();
        }).catch((err) => {
          console.log("error", err);
        });
    }
  };
  const onFileChange = async (e) => {
    e.preventDefault();
    

      setProductimage(e.target.files[0]);
   
    
  };
  useEffect(() => {
    //setLoading(true) ;
    //alert(id)
    //fetchData();
  }, []);
  return (
    <div className="container-scroller">
      {loadingInProgress ? <div className="parentdiv"><div className="loaderclsdiv"><ClipLoader className="loadercls" text-align="center" color={'#000'} loading={loadingInProgress}  size={35} /></div></div>: ""}
      {" "}
      <ToastContainer />
    
      <div class="layout-wrapper layout-content-navbar">
      <div class="layout-container">
    
<Aside/>
<div class="layout-page">


<Header/>
<div class="content-wrapper">


<div class="container-xxl flex-grow-1 container-p-y">
  
  
<div class="row">
<div class="col-md-6">
<h4 class="py-3 mb-4">
<span class="text-muted fw-light">Add Product File</span> 
</h4>

</div>
<div class="col-md-6" >

<Link class="dt-button add-new btn btn-primary ms-2 ms-sm-0" tabindex="0" aria-controls="DataTables_Table_0" to={config.front_URL +"/listProductfile?id="+id}><span><span class="d-none d-sm-inline-block">Back</span></span></Link>&nbsp;

</div>
</div>



<form onSubmit={(e) => handleSubmit(e)}>
<div class="row">
<div class="col-md-12">
    <div class="card mb-4">
      {/* <h5 class="card-header">Add Content</h5> */}
      <div class="card-body">
        <div class="row mrgBtm4">
              <div class="col-md-6">
                <div>
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Parent Folder</label>
          <input type="text" readOnly class="form-control" value={(parentTitle)?parentTitle:'None'} />
          {/* <input type="text" required class="form-control" placeholder="Enter Type" onChange={(e) => setType(e.target.value)}/> */}
          
        </div>
        </div>
              <div class="col-md-6">
              <div>
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Show to users</label>
          <select  class="form-control"  onChange={(e) => setUsers(e.target.value)} >
            <option value="All Users">All Users</option>
        
            <option value="USA users">USA users</option>
            <option value="Non USA users">Non USA users</option>

          </select>
          
        </div>
              </div>
        </div>
        
        
        
        
        <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Title</label>
          <input type="type" required class="form-control" placeholder="Enter Title" onChange={(e) => setTitle(e.target.value)} value={title}/>
          
        </div>
        {/* <div class="mrgBtm4">
          <label for="exampleFormControlTextarea1" class="form-label">Description</label>
          <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" onChange={(e) => setDescription(e.target.value)} value={description}>{description}</textarea>
        </div> */}
        <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label"> File </label>
          
          <input type="file" required={(par == 'category')?false:(par == 'subcat')?false:true} class="form-control" onChange={(e) => onFileChange(e)} accept="application/pdf"/>
          
        </div>
        

        <div class="row mt-3">
          <div class="d-grid gap-2 col-lg-6 mx-auto">
            <button class="btn btn-primary btn-lg waves-effect waves-light" type="submit">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</div>
</form>
    
            </div>
           
            <Footer/>

  
 
</div>
</div>
           
            
</div>



</div>
</div>
  );
}

export { Addproductfile };
