import React, { useState } from "react";
import config from "../config";
import axios from "axios";
import { useNavigate,Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader"; 
import "./login.css";

function Changeforgotpasswordadmin() {
    const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("case");
  const navigate = useNavigate();
  let   [loadingInProgress, setLoading] = useState(false);
  const [newpassword, setnewpassword] = useState("");
  const [confirmpassword, setconfirmpassword] = useState("");
  const [isVisible, setVisible] = useState(false);
  const [isVisible1, setVisible1] = useState(false);
  let handleSubmit =  (e) => {
    e.preventDefault()
   setLoading(true) ;
   // navigate("/user");
    if(newpassword == confirmpassword){

   
    let getloginuser = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        password: confirmpassword,
        id:id
      }),
    };
    fetch(config.backend_URL + "/resetPassword", getloginuser)
    .then((response) => response.json())
    .then((responseJson) => {
      console.log(responseJson);
      
      setconfirmpassword("");
      setnewpassword("");
         
        

          if (responseJson.status) {
            /*        localStorage.setItem('Id',data.data.data.ADMIN_ID); */
            setLoading(false)
            
            
            toast(responseJson.msz);
            setTimeout(navigate("/login"), 5000);
            
          
        } else {
          toast(responseJson.msz);
          e.target.reset();
          setLoading(false)
          
        }
      
    })
    .catch((error) => {
      toast('It will take some time!');
      setLoading(false)
      console.error(error);
    });
}else{
    setLoading(false)
    toast('New password or confirm password not matched!');
}
  };

  const toggle = () => {
    setVisible(!isVisible);
  };
  const toggle1 = () => {
    setVisible1(!isVisible1);
  };
  return (
    <div className="container-scroller">
      {loadingInProgress ? <div className="parentdiv"><div className="loaderclsdiv"><ClipLoader className="loadercls" text-align="center" color={'#000'} loading={loadingInProgress}  size={35} /></div></div>: ""}
      {" "}
      <ToastContainer />
      <div class="authentication-wrapper authentication-cover authentication-bg">
  <div class="authentication-inner row">
    
    <div class="d-none d-lg-flex col-lg-7 p-0">
      <div class="auth-cover-bg auth-cover-bg-color d-flex justify-content-center align-items-center">
        <img src="images/unamed.png" alt="auth-login-cover" class="img-fluid my-5 auth-illustration" data-app-light-img="illustrations/auth-login-illustration-light.png" data-app-dark-img="illustrations/auth-login-illustration-dark.png"/>

       
      </div>
    </div>
   
    <div class="d-flex col-12 col-lg-5 align-items-center p-sm-5 p-4">
      <div class="w-px-400 mx-auto">
       
        
        
        <h3 class="mb-1">Welcome to Carbofix 👋</h3>
        <p class="mb-4">Change password</p>

        <form onSubmit={(e) => handleSubmit(e)}>
        <div class="mb-3 form-password-toggle">
            <div class="d-flex justify-content-between">
              <label class="form-label" for="password">New Password</label>
              
            </div>
          <div class="input-group input-group-merge">
            {/* <label for="email" class="form-label">New Password</label> */}
            <input type={!isVisible1 ? "password" : "text"} required class="form-control"  onChange={(e) => setnewpassword(e.target.value)}  placeholder="Enter your New Password" autofocus/>
            <a class="input-group-text cursor-pointer" onClick={toggle1}><i class={!isVisible1 ? "fa fa-eye" : "fa fa-eye-slash"}></i></a>
          </div>
          </div>
          <div class="mb-3 form-password-toggle">
            <div class="d-flex justify-content-between">
              <label class="form-label" for="password">Confirm Password</label>
              
            </div>
          <div class="input-group input-group-merge">
            {/* <label for="email" class="form-label">Confirm Password</label> */}
            <input  type={!isVisible ? "password" : "text"} required class="form-control" onChange={(e) => setconfirmpassword(e.target.value)}  placeholder="Enter your Confirm Password" autofocus/>
            <a class="input-group-text cursor-pointer" onClick={toggle}><i class={!isVisible ? "fa fa-eye" : "fa fa-eye-slash"}></i></a>
                    
          </div>
          </div>
        
          <button class="btn btn-primary d-grid w-100">
            Change
          </button>
          
          
            
           
        </form>
       
       
      </div>
    </div>

  </div>
</div>

</div>
    
  );
}

export { Changeforgotpasswordadmin };
