import React, { useEffect,useState } from "react";
import config from "../config";
import axios from "axios";
import { useNavigate,Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader"; 
import "./login.css";
import { Header } from "./header";
import { Aside } from "./aside";
import { Footer } from "./footer";
import CKEditor from "react-ckeditor-component";
import Select,{
  components,
  MultiValueGenericProps,
  MultiValueProps,
  OnChangeValue,
  Props,
} from 'react-select'
function Settings() {
   
  const navigate = useNavigate();
  let   [loadingInProgress, setLoading] = useState(false);
  const   [user, setuser] = useState([]);
  const   [user_1, setuser_1] = useState([]);
  const [Officename, setOfficename] = useState("");
  const [address, setAddress] = useState("");
  const [teltitle, setTeltitle] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [faxtitle, setFaxtitle] = useState("");
  const [fax, setFax] = useState("");
  const [description, setDescription] = useState("");
  const [tel2title, setTel2title] = useState("");
  const [phone2number, setPhone2number] = useState("");
  const [id, setId] = useState("");
  const [website, setWebsite] = useState("");
  const [userregister_userid, setuserregister_userid] = useState("");
  const [userregister_formName, setuserregister_formName] = useState("");
  const [userregister_replyTo, setuserregister_replyTo] = useState("");
  const [userregister_subject, setuserregister_subject] = useState("");
  const [userregister_message, setuserregister_message] = useState("");

  const [contactadmin_formName, setcontactadmin_formName] = useState("");
  const [contactadmin_replyTo, setcontactadmin_replyTo] = useState("");
  const [contactadmin_subject, setcontactadmin_subject] = useState("");
  const [contactadmin_message, setcontactadmin_message] = useState("");


  const [newapproval_formName, setnewapproval_formName] = useState("");
  const [newapproval_replyTo, setnewapproval_replyTo] = useState("");
  const [newapproval_subject, setnewapproval_subject] = useState("");
  const [newapproval_message, setnewapproval_message] = useState("");

  const [userapproval_notification_subject, setuserapproval_notification_subject] = useState("");
  const [userapproval_notification_message, setuserapproval_notification_message] = useState("");

  const [resetpassword_formName, setresetpassword_formName] = useState("");
  const [resetpassword_replyTo, setresetpassword_replyTo] = useState("");
  const [resetpassword_subject, setresetpassword_subject] = useState("");
  const [resetpassword_message, setresetpassword_message] = useState("");

  const [presentation_formName, setpresentation_formName] = useState("");
  const [presentation_replyTo, setpresentation_replyTo] = useState("");
  const [presentation_subject, setpresentation_subject] = useState("");
  const [presentation_message, setpresentation_message] = useState("");

  const [presentation_notification_subject, setpresentation_notification_subject] = useState("");
  const [presentation_notification_message, setpresentation_notification_message] = useState("");

  const [product_formName, setproduct_formName] = useState("");
  const [product_replyTo, setproduct_replyTo] = useState("");
  const [product_subject, setproduct_subject] = useState("");
  const [product_message, setproduct_message] = useState("");

  const [product_notification_subject, setproduct_notification_subject] = useState("");
  const [product_notification_message, setproduct_notification_message] = useState("");

  const [traning_formName, settraning_formName] = useState("");
  const [traning_replyTo, settraning_replyTo] = useState("");
  const [traning_subject, settraning_subject] = useState("");
  const [traning_message, settraning_message] = useState("");

  const [traning_notification_subject, settraning_notification_subject] = useState("");
  const [traning_notification_message, settraning_notification_message] = useState("");

  const [forms_formName, setforms_formName] = useState("");
  const [forms_replyTo, setforms_replyTo] = useState("");
  const [forms_subject, setforms_subject] = useState("");
  const [forms_message, setforms_message] = useState("");

  const [forms_notification_subject, setforms_notification_subject] = useState("");
  const [forms_notification_message, setforms_notification_message] = useState("");

  const [phoneimage, setphoneimage] = useState("");
  const [phoneimage1, setphoneimage1] = useState("");
  const [viewphoneimage, setviewphoneimage] = useState("");
  const [phone1, setphone1] = useState("");
  const [phone2, setphone2] = useState("");

  const   [user1, setuser1] = useState([]);
  const   [user2, setuser2] = useState([]);
 
  const onFileChange = async (e) => {
    e.preventDefault();
      setphoneimage(e.target.files[0]);
  };
  const onFileChange1 = async (e) => {
    e.preventDefault();
      setphoneimage1(e.target.files[0]);
  };
  
  let handleSubmit =  (e) => {
    e.preventDefault()
   setLoading(true) ;
   // navigate("/user");
    //alert(fulltext);
    
    let getloginuser = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userregister_userid: user1,
        userregister_formName: userregister_formName,
        userregister_replyTo: userregister_replyTo,
        userregister_subject: userregister_subject,
        userregister_message: userregister_message,
       id:id,
        website: website,
        
        contactadmin_userid: user2,
        contactadmin_formName: contactadmin_formName,
        contactadmin_replyTo: contactadmin_replyTo,
        contactadmin_subject: contactadmin_subject,
        contactadmin_message: contactadmin_message,


        newapproval_formName: newapproval_formName,
        newapproval_replyTo: newapproval_replyTo,
        newapproval_subject: newapproval_subject,
        newapproval_message: newapproval_message,
       
        userapproval_notification_subject:userapproval_notification_subject,
        userapproval_notification_message:userapproval_notification_message,

        resetpassword_formName: resetpassword_formName,
        resetpassword_replyTo: resetpassword_replyTo,
        resetpassword_subject: resetpassword_subject,
        resetpassword_message: resetpassword_message,

        presentation_formName: presentation_formName,
        presentation_replyTo: presentation_replyTo,
        presentation_subject: presentation_subject,
        presentation_message: presentation_message,

        presentation_notification_subject:presentation_notification_subject,
        presentation_notification_message:presentation_notification_message,

        product_formName: product_formName,
        product_replyTo: product_replyTo,
        product_subject: product_subject,
        product_message: product_message,

        product_notification_subject:product_notification_subject,
        product_notification_message:product_notification_message,

        traning_formName: traning_formName,
        traning_replyTo: traning_replyTo,
        traning_subject: traning_subject,
        traning_message: traning_message,

        traning_notification_subject:traning_notification_subject,
        traning_notification_message:traning_notification_message,

        forms_formName: forms_formName,
        forms_replyTo: forms_replyTo,
        forms_subject: forms_subject,
        forms_message: forms_message,

        forms_notification_subject:forms_notification_subject,
        forms_notification_message:forms_notification_message,
        
      }),
    };
    fetch(config.backend_URL + "/updateSettings", getloginuser)
    .then((response) => response.json())
    .then((responseJson) => {
      console.log(responseJson);
      
         
       
        

          if (responseJson.status) {
            /*        localStorage.setItem('Id',data.data.data.ADMIN_ID); */
            setLoading(false)
          
            
            
            toast(responseJson.msz);
            
            
          
        } else {
          toast(responseJson.msz);
          e.target.reset();
          setLoading(false)
          
        }
      
    })
    .catch((error) => {
      toast('It will take some time!');
      setLoading(false)
      console.error(error);
    });


    
  };
  let handleSubmit1 =  (e) => {
    e.preventDefault()
   setLoading(true) ;
   // navigate("/user");
    //alert(fulltext);
    let formData = new FormData(); //formdata object
        
    formData.append("filePhone", phoneimage);
    formData.append("fileIpad", phoneimage1);
    formData.append("url1", phone1);
    formData.append("url2", phone2);
   
    formData.append("website", website);
    formData.append("id", id);
   
    axios.post(`${config.backend_URL}/updateSettingsForm1`, formData)
    .then( (data) => {
     
      setLoading(false)
      toast(data.data.msz);
      //e.target.reset();
    }).catch((err) => {
      console.log("error", err);
    });

   


    
  };
  const fetchData = () => {
    //e.preventDefault()
    setLoading(true)
    let getloginuser = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: id,
        
      }),
    };
    return setLoading(true) ,fetch(config.backend_URL + "/getSettings", getloginuser)
    .then((response) => response.json())
    .then((responseJson) => {
      setLoading(false)
      console.log(responseJson.data)
          if (responseJson.status) {
          
            setWebsite(responseJson.data[0].website)
            setphone1(responseJson.data[0].filePhone)
            setphone2(responseJson.data[0].fileIpad)
            setId(responseJson.data[0]._id)
            setuser1(responseJson.data[0].userRegister_userId)
            setuserregister_formName(responseJson.data[0].userRegister_formName)
            setuserregister_replyTo(responseJson.data[0].userRegister_replyTo)
            setuserregister_subject(responseJson.data[0].userRegister_subject)
            setuserregister_message(responseJson.data[0].userRegister_message)


            setuser2(responseJson.data[0].contactAdmin_userId)
            setcontactadmin_formName(responseJson.data[0].contactAdmin_formName)
            setcontactadmin_replyTo(responseJson.data[0].contactAdmin_replyTo)
            setcontactadmin_subject(responseJson.data[0].contactAdmin_subject)
            setcontactadmin_message(responseJson.data[0].contactAdmin_message)


            setnewapproval_formName(responseJson.data[0].newapproval_formName)
            setnewapproval_replyTo(responseJson.data[0].newapproval_replyTo)
            setnewapproval_subject(responseJson.data[0].newapproval_subject)
            setnewapproval_message(responseJson.data[0].newapproval_message)

            setuserapproval_notification_subject(responseJson.data[0].userapproval_notification_subject)
            setuserapproval_notification_message(responseJson.data[0].userapproval_notification_message)
          
            setresetpassword_formName(responseJson.data[0].resetpassword_formName)
            setresetpassword_replyTo(responseJson.data[0].resetpassword_replyTo)
            setresetpassword_subject(responseJson.data[0].resetpassword_subject)
            setresetpassword_message(responseJson.data[0].resetpassword_message)

            setpresentation_notification_subject(responseJson.data[0].presentation_notification_subject)
            setpresentation_notification_message(responseJson.data[0].presentation_notification_message)
          
            setpresentation_formName(responseJson.data[0].presentation_formName)
            setpresentation_replyTo(responseJson.data[0].presentation_replyTo)
            setpresentation_subject(responseJson.data[0].presentation_subject)
            setpresentation_message(responseJson.data[0].presentation_message)

            setproduct_notification_subject(responseJson.data[0].product_notification_subject)
            setproduct_notification_message(responseJson.data[0].product_notification_message)
          
            setproduct_formName(responseJson.data[0].product_formName)
            setproduct_replyTo(responseJson.data[0].product_replyTo)
            setproduct_subject(responseJson.data[0].product_subject)
            setproduct_message(responseJson.data[0].product_message)

            settraning_notification_subject(responseJson.data[0].traning_notification_subject)
            settraning_notification_message(responseJson.data[0].traning_notification_message)
          
            settraning_formName(responseJson.data[0].traning_formName)
            settraning_replyTo(responseJson.data[0].traning_replyTo)
            settraning_subject(responseJson.data[0].traning_subject)
            settraning_message(responseJson.data[0].traning_message)

            setforms_notification_subject(responseJson.data[0].forms_notification_subject)
            setforms_notification_message(responseJson.data[0].forms_notification_message)
          
            setforms_formName(responseJson.data[0].forms_formName)
            setforms_replyTo(responseJson.data[0].forms_replyTo)
            setforms_subject(responseJson.data[0].forms_subject)
            setforms_message(responseJson.data[0].forms_message)
        } else {
          toast(responseJson.msz);
          
          //setLoading(false)
          
        }
      
    })
    .catch((error) => {
      toast('It will take some time!');
      setLoading(false)
      //console.error(error);
    });

      setLoading(false)
  };
  
const options = []
const fetchData2 = async () => {
  //e.preventDefault()
  setLoading(true)
  let getloginuser = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      type: 'type',
      
    }),
  };
  return setLoading(true) ,fetch(config.backend_URL + "/getAdmin", getloginuser)
  .then((response) => response.json())
  .then((responseJson) => {
    setLoading(false)
        if (responseJson.status) {
         
          responseJson.data.forEach(element => {
            const entries = { value: element._id, label: element.firstName,email: element.email }  ;
            options.push(entries);
            
           
         });
         setuser(options);
         setuser_1(options);
         
      } else {
        toast(responseJson.msz);
       
      }
    
  })
  .catch((error) => {
    toast('It will take some time!');
    setLoading(false)
    //console.error(error);
  });

    
};
function handleSelect(data) {
  setuser1(data);
  console.log(user1)
}
function handleSelect1(data) {
  setuser2(data);
  console.log(user2)
}
  useEffect(() => {
    //setLoading(true) ;
    fetchData();
    fetchData2();
  }, []);
  return (
    <div className="container-scroller">
      {loadingInProgress ? <div className="parentdiv"><div className="loaderclsdiv"><ClipLoader className="loadercls" text-align="center" color={'#000'} loading={loadingInProgress}  size={35} /></div></div>: ""}
      {" "}
      <ToastContainer />
    
      <div class="layout-wrapper layout-content-navbar">
      <div class="layout-container">
    
<Aside/>
<div class="layout-page">


<Header/>
<div class="content-wrapper">


<div class="container-xxl flex-grow-1 container-p-y">
  
  

<h4 class="py-3 mb-4">
<span class="text-muted fw-light">Settings</span> 
</h4>


<form onSubmit={(e) => handleSubmit1(e)}>
<div class="row">
<div class="col-md-12">
    <div class="card mb-4">
      <h5 class="card-header">General Settings</h5>
      <div class="card-body">
      <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Website URL</label>
          <input type="text"  class="form-control" placeholder="Enter URL" onChange={(e) => setWebsite(e.target.value)} value={website}/>
          
        </div>
        <div class="row">
        <div class="col-md-6">
              <div>
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Image For Phone</label>
          <div class="mb-6 pt-4">
      

        <input type="file"  onChange={(e) => onFileChange(e)}  accept="image/png, image/gif, image/jpeg"/>
        
      <div class="formbold-mb-5 formbold-file-input">
        <label >
          <div>
          <span class="formbold-drop-file" ><i class="fa fa-cloud-download"></i></span>
          <span class="formbold-drop-file" ><img src={phone1} width="100px"/></span>
          {/* <span class="formbold-or">DownLoad File </span> */}
           
          </div>
        </label>
      </div>
        
      </div>
          
        </div></div>
        <div class="col-md-6">
              <div>
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Image For Ipad</label>
          <div class="mb-6 pt-4">
      

        <input type="file"  onChange={(e) => onFileChange1(e)}  accept="image/png, image/gif, image/jpeg"/>
        
      <div class="formbold-mb-5 formbold-file-input">
        <label >
          <div>
          <span class="formbold-drop-file" ><i class="fa fa-cloud-download"></i></span>
          <span class="formbold-drop-file" ><img src={phone2} width="100px"/></span>
          {/* <span class="formbold-or">DownLoad File </span> */}
           
          </div>
        </label>
      </div>
        
      </div>
          
        </div></div>
        </div>
        


        <div class="row mt-3">
          <div class="d-grid gap-2 col-lg-3 mx-auto">
            <button class="btn btn-primary btn-lg waves-effect waves-light" type="submit">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</div>
</form>
<form onSubmit={(e) => handleSubmit(e)}>
<div class="row">
<div class="col-md-12">
    <div class="card mb-4">
      <h5 class="card-header">New user registration email to Admins</h5>
      <div class="card-body">
      <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Send email to</label>
          
          <Select required options={user} closeMenuOnSelect={false} isMulti onChange={handleSelect} value={user1}/>
        </div>
        <div class="row mrgBtm4">
            <div class="col-md-4">
            <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>From name</label>
          <input type="text"  class="form-control" placeholder="Enter Email" onChange={(e) => setuserregister_formName(e.target.value)} value={userregister_formName}/>
          
        </div>
            </div>
            <div class="col-md-4">
            <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Reply to</label>
          <input type="text"  class="form-control" placeholder="Enter Email" onChange={(e) => setuserregister_replyTo(e.target.value)} value={userregister_replyTo}/>
          
        </div>
            </div>
            <div class="col-md-4">
            <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Subject</label>
          <input type="text"  class="form-control" placeholder="Enter Email" onChange={(e) => setuserregister_subject(e.target.value)} value={userregister_subject}/>
          
        </div>
            </div>
        </div>
        <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Message</label>
        <CKEditor activeClass="editor" content={userregister_message} 
       
       events={{
              
               "change": (e) => setuserregister_message(e.editor.getData())
             }} />

</div>
        <div class="row mt-3">
          <div class="d-grid gap-2 col-lg-3 mx-auto">
            <button class="btn btn-primary btn-lg waves-effect waves-light" type="submit">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</div>

<div class="row">
<div class="col-md-12">
    <div class="card mb-4">
      <h5 class="card-header">New contact us form email to Admin</h5>
      <div class="card-body">
      <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Send email to</label>
          <Select  options={user_1} closeMenuOnSelect={false} isMulti onChange={handleSelect1} value={user2}/>
          
        </div>
        <div class="row mrgBtm4">
            <div class="col-md-4">
            <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>From name</label>
          <input type="text"  class="form-control" placeholder="Enter Email" onChange={(e) => setcontactadmin_formName(e.target.value)} value={contactadmin_formName}/>
          
        </div>
            </div>
            <div class="col-md-4">
            <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Reply to</label>
          <input type="text"  class="form-control" placeholder="Enter Email" onChange={(e) => setcontactadmin_replyTo(e.target.value)} value={contactadmin_replyTo}/>
          
        </div>
            </div>
            <div class="col-md-4">
            <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Subject</label>
          <input type="text"  class="form-control" placeholder="Enter Email" onChange={(e) => setcontactadmin_subject(e.target.value)} value={contactadmin_subject}/>
          
        </div>
            </div>
        </div>
        <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Message</label>
        <CKEditor activeClass="editor" content={contactadmin_message} 
       
       events={{
              
               "change": (e) => setcontactadmin_message(e.editor.getData())
             }} />

</div>
        <div class="row mt-3">
          <div class="d-grid gap-2 col-lg-3 mx-auto">
            <button class="btn btn-primary btn-lg waves-effect waves-light" type="submit">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</div>

<div class="row">
<div class="col-md-12">
    <div class="card mb-4">
      <h5 class="card-header">New user approval email</h5>
      <div class="card-body">
     
        <div class="row mrgBtm4">
            <div class="col-md-4">
            <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>From name</label>
          <input type="text"  class="form-control" placeholder="Enter Email" onChange={(e) => setnewapproval_formName(e.target.value)} value={newapproval_formName}/>
          
        </div>
            </div>
            <div class="col-md-4">
            <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Reply to</label>
          <input type="text"  class="form-control" placeholder="Enter Email" onChange={(e) => setnewapproval_replyTo(e.target.value)} value={newapproval_replyTo}/>
          
        </div>
            </div>
            <div class="col-md-4">
            <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Subject</label>
          <input type="text"  class="form-control" placeholder="Enter Email" onChange={(e) => setnewapproval_subject(e.target.value)} value={newapproval_subject}/>
          
        </div>
            </div>
        </div>
        <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Message</label>
        <CKEditor activeClass="editor" content={newapproval_message} 
       
       events={{
              
               "change": (e) => setnewapproval_message(e.editor.getData())
             }} />

</div>
        <div class="row mt-3">
          <div class="d-grid gap-2 col-lg-3 mx-auto">
            <button class="btn btn-primary btn-lg waves-effect waves-light" type="submit">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</div>

<div class="row">
<div class="col-md-12">
    <div class="card mb-4">
      <h5 class="card-header">New user approval push notification to users</h5>
      <div class="card-body">
     
        <div class="row mrgBtm4">
            
            
            <div class="col-md-4">
            <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Subject</label>
          <input type="text"  class="form-control" placeholder="Enter Email" onChange={(e) => setuserapproval_notification_subject(e.target.value)} value={userapproval_notification_subject}/>
          
        </div>
            </div>
            <div class="col-md-8">
            <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Message</label>
          <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" onChange={(e) => setuserapproval_notification_message(e.target.value)} value={userapproval_notification_message}>{userapproval_notification_message}</textarea>

</div>
            </div>
        </div>
        
        <div class="row mt-3">
          <div class="d-grid gap-2 col-lg-3 mx-auto">
            <button class="btn btn-primary btn-lg waves-effect waves-light" type="submit">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</div>

<div class="row">
<div class="col-md-12">
    <div class="card mb-4">
      <h5 class="card-header">Reset password email to users</h5>
      <div class="card-body">
     
        <div class="row mrgBtm4">
            <div class="col-md-4">
            <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>From name</label>
          <input type="text"  class="form-control" placeholder="Enter Email" onChange={(e) => setresetpassword_formName(e.target.value)} value={resetpassword_formName}/>
          
        </div>
            </div>
            <div class="col-md-4">
            <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Reply to</label>
          <input type="text"  class="form-control" placeholder="Enter Email" onChange={(e) => setresetpassword_replyTo(e.target.value)} value={resetpassword_replyTo}/>
          
        </div>
            </div>
            <div class="col-md-4">
            <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Subject</label>
          <input type="text"  class="form-control" placeholder="Enter Email" onChange={(e) => setresetpassword_subject(e.target.value)} value={resetpassword_subject}/>
          
        </div>
            </div>
        </div>
        <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Message</label>
        <CKEditor activeClass="editor" content={resetpassword_message} 
       
       events={{
              
               "change": (e) => setresetpassword_message(e.editor.getData())
             }} />

</div>
        <div class="row mt-3">
          <div class="d-grid gap-2 col-lg-3 mx-auto">
            <button class="btn btn-primary btn-lg waves-effect waves-light" type="submit">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</div>

<div class="row">
<div class="col-md-12">
    <div class="card mb-4">
      <h5 class="card-header">Presentation file update email to users</h5>
      <div class="card-body">
     
        <div class="row mrgBtm4">
            <div class="col-md-4">
            <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>From name</label>
          <input type="text"  class="form-control" placeholder="Enter Email" onChange={(e) => setpresentation_formName(e.target.value)} value={presentation_formName}/>
          
        </div>
            </div>
            <div class="col-md-4">
            <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Reply to</label>
          <input type="text"  class="form-control" placeholder="Enter Email" onChange={(e) => setpresentation_replyTo(e.target.value)} value={presentation_replyTo}/>
          
        </div>
            </div>
            <div class="col-md-4">
            <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Subject</label>
          <input type="text"  class="form-control" placeholder="Enter Email" onChange={(e) => setpresentation_subject(e.target.value)} value={presentation_subject}/>
          
        </div>
            </div>
        </div>
        <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Message</label>
        <CKEditor activeClass="editor" content={presentation_message} 
       
       events={{
              
               "change": (e) => setpresentation_message(e.editor.getData())
             }} />

</div>
        <div class="row mt-3">
          <div class="d-grid gap-2 col-lg-3 mx-auto">
            <button class="btn btn-primary btn-lg waves-effect waves-light" type="submit">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</div>

<div class="row">
<div class="col-md-12">
    <div class="card mb-4">
      <h5 class="card-header">Presentation file update Push notification to users</h5>
      <div class="card-body">
     
        <div class="row mrgBtm4">
            
            
            <div class="col-md-4">
            <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Subject</label>
          <input type="text"  class="form-control" placeholder="Enter Email" onChange={(e) => setpresentation_notification_subject(e.target.value)} value={presentation_notification_subject}/>
          
        </div>
            </div>
            <div class="col-md-8">
            <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Message</label>
          <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" onChange={(e) => setpresentation_notification_message(e.target.value)} value={presentation_notification_message}>{presentation_notification_message}</textarea>

</div>
            </div>
        </div>
        
        <div class="row mt-3">
          <div class="d-grid gap-2 col-lg-3 mx-auto">
            <button class="btn btn-primary btn-lg waves-effect waves-light" type="submit">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</div>

<div class="row">
<div class="col-md-12">
    <div class="card mb-4">
      
      <h5 class="card-header">Product file update email to users</h5>
      <div class="card-body">
     
        <div class="row mrgBtm4">
            <div class="col-md-4">
            <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>From name</label>
          <input type="text"  class="form-control" placeholder="Enter Email" onChange={(e) => setproduct_formName(e.target.value)} value={product_formName}/>
          
        </div>
            </div>
            <div class="col-md-4">
            <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Reply to</label>
          <input type="text"  class="form-control" placeholder="Enter Email" onChange={(e) => setproduct_replyTo(e.target.value)} value={product_replyTo}/>
          
        </div>
            </div>
            <div class="col-md-4">
            <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Subject</label>
          <input type="text"  class="form-control" placeholder="Enter Email" onChange={(e) => setproduct_subject(e.target.value)} value={product_subject}/>
          
        </div>
            </div>
        </div>
        <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Message</label>
        <CKEditor activeClass="editor" content={product_message} 
       
       events={{
              
               "change": (e) => setproduct_message(e.editor.getData())
             }} />

</div>
        <div class="row mt-3">
          <div class="d-grid gap-2 col-lg-3 mx-auto">
            <button class="btn btn-primary btn-lg waves-effect waves-light" type="submit">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</div>

<div class="row">
<div class="col-md-12">
    <div class="card mb-4">
    <h5 class="card-header">Product file update Push notification to users</h5>
      <div class="card-body">
     
        <div class="row mrgBtm4">
            
            
            <div class="col-md-4">
            <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Subject</label>
          <input type="text"  class="form-control" placeholder="Enter Email" onChange={(e) => setproduct_notification_subject(e.target.value)} value={product_notification_subject}/>
          
        </div>
            </div>
            <div class="col-md-8">
            <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Message</label>
          <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" onChange={(e) => setproduct_notification_message(e.target.value)} value={product_notification_message}>{product_notification_message}</textarea>

</div>
            </div>
        </div>
        
        <div class="row mt-3">
          <div class="d-grid gap-2 col-lg-3 mx-auto">
            <button class="btn btn-primary btn-lg waves-effect waves-light" type="submit">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</div>

<div class="row">
<div class="col-md-12">
    <div class="card mb-4">
      <h5 class="card-header">Training update email to users</h5>
      <div class="card-body">
     
      <div class="row mrgBtm4">
            <div class="col-md-4">
            <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>From name</label>
          <input type="text"  class="form-control" placeholder="Enter Email" onChange={(e) => settraning_formName(e.target.value)} value={traning_formName}/>
          
        </div>
            </div>
            <div class="col-md-4">
            <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Reply to</label>
          <input type="text"  class="form-control" placeholder="Enter Email" onChange={(e) => settraning_replyTo(e.target.value)} value={traning_replyTo}/>
          
        </div>
            </div>
            <div class="col-md-4">
            <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Subject</label>
          <input type="text"  class="form-control" placeholder="Enter Email" onChange={(e) => settraning_subject(e.target.value)} value={traning_subject}/>
          
        </div>
            </div>
        </div>
        <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Message</label>
        <CKEditor activeClass="editor" content={traning_message} 
       
       events={{
              
               "change": (e) => settraning_message(e.editor.getData())
             }} />

</div>
        <div class="row mt-3">
          <div class="d-grid gap-2 col-lg-3 mx-auto">
            <button class="btn btn-primary btn-lg waves-effect waves-light" type="submit">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</div>

<div class="row">
<div class="col-md-12">
    <div class="card mb-4">
      <h5 class="card-header">Training update Push notification to users</h5>
      <div class="card-body">
     
        <div class="row mrgBtm4">
            
            
            <div class="col-md-4">
            <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Subject</label>
          <input type="text"  class="form-control" placeholder="Enter Email" onChange={(e) => settraning_notification_subject(e.target.value)} value={traning_notification_subject}/>
          
        </div>
            </div>
            <div class="col-md-8">
            <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Message</label>
          <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" onChange={(e) => settraning_notification_message(e.target.value)} value={traning_notification_message}>{traning_notification_message}</textarea>

</div>
            </div>
        </div>
        
        <div class="row mt-3">
          <div class="d-grid gap-2 col-lg-3 mx-auto">
            <button class="btn btn-primary btn-lg waves-effect waves-light" type="submit">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</div>

<div class="row">
<div class="col-md-12">
    <div class="card mb-4">
    <h5 class="card-header">Form update email to users</h5>
      <div class="card-body">
     
      <div class="row mrgBtm4">
            <div class="col-md-4">
            <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>From name</label>
          <input type="text"  class="form-control" placeholder="Enter Email" onChange={(e) => setforms_formName(e.target.value)} value={forms_formName}/>
          
        </div>
            </div>
            <div class="col-md-4">
            <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Reply to</label>
          <input type="text"  class="form-control" placeholder="Enter Email" onChange={(e) => setforms_replyTo(e.target.value)} value={forms_replyTo}/>
          
        </div>
            </div>
            <div class="col-md-4">
            <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Subject</label>
          <input type="text"  class="form-control" placeholder="Enter Email" onChange={(e) => setforms_subject(e.target.value)} value={forms_subject}/>
          
        </div>
            </div>
        </div>
        <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Message</label>
        <CKEditor activeClass="editor" content={forms_message} 
       
       events={{
              
               "change": (e) => setforms_message(e.editor.getData())
             }} />

</div>
        <div class="row mt-3">
          <div class="d-grid gap-2 col-lg-3 mx-auto">
            <button class="btn btn-primary btn-lg waves-effect waves-light" type="submit">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</div>

<div class="row">
<div class="col-md-12">
    <div class="card mb-4">
      
      <h5 class="card-header">Form update Push notification to users</h5>
      <div class="card-body">
     
        <div class="row mrgBtm4">
            
            
            <div class="col-md-4">
            <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Subject</label>
          <input type="text"  class="form-control" placeholder="Enter Email" onChange={(e) => setforms_notification_subject(e.target.value)} value={forms_notification_subject}/>
          
        </div>
            </div>
            <div class="col-md-8">
            <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Message</label>
          <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" onChange={(e) => setforms_notification_message(e.target.value)} value={forms_notification_message}>{forms_notification_message}</textarea>

</div>
            </div>
        </div>
        
        <div class="row mt-3">
          <div class="d-grid gap-2 col-lg-3 mx-auto">
            <button class="btn btn-primary btn-lg waves-effect waves-light" type="submit">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</div>
</form>
            </div>
           
            <Footer/>

  
 
</div>
</div>
           
            
</div>



</div>
</div>
  );
}

export { Settings };
