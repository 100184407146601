import React, { useState,useEffect } from "react";
import config from "../config";
import axios from "axios";
import { useNavigate,Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader"; 
import "./login.css";
import { Header } from "./header";
import { Aside } from "./aside";
import { Footer } from "./footer";
import CKEditor from "react-ckeditor-component";
import {
  CitySelect,
  CountrySelect,
  StateSelect,
  LanguageSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
function Adduser() {
  const navigate = useNavigate();
  let   [loadingInProgress, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [position, setPosition] = useState("");
  const [positionarr, setPositionarr] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [countryid, setCountryid] = useState('');
  const [stateid, setstateid] = useState('');
  const [password, setPassword] = useState('');
  const [confirmpassword, setConfirmpassword] = useState('');
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [city, setCity ]= useState('');

  let handleSubmit =  (e) => {
    e.preventDefault()
    if(password != confirmpassword){
      toast('Password not matched!');
      return false;
    }
    setLoading(true) ;
    
    let getloginuser = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        status: parseInt(status),
        position: position,
        firstName: firstName,
        lastName: lastname,
        email: email,
        phone: phone,
        country: country,
        state: state,
        city: city,
        password: password,
        companyName: company,
      }),
    }
    fetch(config.backend_URL + "/register", getloginuser)
    .then((response) => response.json())
    .then((responseJson) => {
      console.log(responseJson);
      
          
        

          if (responseJson.status) {
            /*        localStorage.setItem('Id',data.data.data.ADMIN_ID); */
            setLoading(false)
          e.target.reset();
            
            
            toast(responseJson.msz);
            
            
          
        } else {
          toast(responseJson.msz);
          e.target.reset();
          setLoading(false)
          
        }
      
    })
    .catch((error) => {
      toast('It will take some time!');
      setLoading(false)
      console.error(error);
    });


    
  };
  const fetchData = () => {
    //e.preventDefault()
    setLoading(true)
    let getloginuser = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      
    };
    return setLoading(true) ,fetch(config.backend_URL1 + "getPosition", getloginuser)
    .then((response) => response.json())
    .then((responseJson) => {
      setLoading(false)
      //console.log(responseJson);
          if (responseJson.status) {
           
            setPositionarr(responseJson.data)
          
        } else {
          toast(responseJson.msz);
          
          //setLoading(false)
          
        }
      
    })
    .catch((error) => {
      toast('It will take some time!');
      setLoading(false)
      //console.error(error);
    });
  
      setLoading(false)
  };
  useEffect(() => {
    //setLoading(true) ;
    //alert(id)
    fetchData();
  
  }, []);
  return (
    <div className="container-scroller">
      {loadingInProgress ? <div className="parentdiv"><div className="loaderclsdiv"><ClipLoader className="loadercls" text-align="center" color={'#000'} loading={loadingInProgress}  size={35} /></div></div>: ""}
      {" "}
      <ToastContainer />
    
      <div class="layout-wrapper layout-content-navbar">
      <div class="layout-container">
    
<Aside/>
<div class="layout-page">


<Header/>
<div class="content-wrapper">


<div class="container-xxl flex-grow-1 container-p-y">
  
  
<div class="row">
<div class="col-md-6">
<h4 class="py-3 mb-4">
<span class="text-muted fw-light">Add User</span> 
</h4>

</div>
<div class="col-md-6" >

<Link class="dt-button add-new btn btn-primary ms-2 ms-sm-0" tabindex="0" aria-controls="DataTables_Table_0" to={config.front_URL +"/user"}><span><span class="d-none d-sm-inline-block">Back</span></span></Link>
</div>
</div>

<form onSubmit={(e) => handleSubmit(e)} autocomplete="off">
<div class="row">
<div class="col-md-12">
    <div class="card mb-4">
      {/* <h5 class="card-header">Add Content</h5> */}
      <div class="card-body">
        <div class="row mrgBtm4">
              <div class="col-md-4">
                <div>
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Status</label>
          <select required class="form-control"  onChange={(e) => setStatus(e.target.value)}>
            <option value="">Select Status</option>
            {/* <option value="welcome">Welcome Page</option> */}
            <option value="0">Active</option>
            <option value="1">Pending</option>
            <option value="2">Suspending</option>

          </select>
          {/* <input type="text" required class="form-control" placeholder="Enter Type" onChange={(e) => setType(e.target.value)}/> */}
          
        </div>
        </div>
        <div class="col-md-4">
                <div>
          <label for="defaultFormControlInput" class="form-label">Position</label>
          <select  class="form-control"  onChange={(e) => setPosition(e.target.value)} value={position} >
            <option value="">Select Type</option>
            {positionarr.map((position) => {
                            return (
<option value={position.tiltle} >{position.tiltle}</option>
                              );
                            })}

          </select>
          {/* <select  class="form-control"  onChange={(e) => setPosition(e.target.value)}>
            <option value="">Select Position</option>
           
            <option value="Other">Other</option>
            <option value="Agent">Agent</option>

          </select> */}
          {/* <input type="text" required class="form-control" placeholder="Enter Type" onChange={(e) => setType(e.target.value)}/> */}
          
        </div>
        </div>
              <div class="col-md-4">
              <div>
          <label for="defaultFormControlInput" class="form-label">Distributor company</label>
          <input type="text"  class="form-control" placeholder="Enter Company" onChange={(e) => setCompany(e.target.value)}/>
          
        </div>
              </div>
        </div>
        <div class="row mrgBtm4">
              <div class="col-md-6">
                <div>
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>First Name</label>
          
          <input type="text" required class="form-control" placeholder="Enter First Name" onChange={(e) => setFirstName(e.target.value)}/>
          
        </div>
        </div>
              <div class="col-md-6">
              <div>
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Last Name</label>
          <input type="text" required class="form-control" placeholder="Enter Last Name" onChange={(e) => setLastName(e.target.value)}/>
          
        </div>
              </div>
        </div>
        
        <div class="row mrgBtm4">
              <div class="col-md-6"> 
               <div>
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Email</label>
          <input type="type" required class="form-control" placeholder="Enter Email" onChange={(e) => setEmail(e.target.value)}/>
          
        </div>
        </div>
              <div class="col-md-6">
              <div>
          <label for="defaultFormControlInput" class="form-label">Phone</label>
          <input type="type"  class="form-control" placeholder="Enter Phone" onChange={(e) => setPhone(e.target.value)}/>
          
        </div>
              </div>
        </div>
      

        <div class="row mrgBtm4">
          <div class="col-md-4">
          <label for="defaultFormControlInput" class="form-label">Country</label>
          <CountrySelect
          required
        onChange={(e) => {
          console.log(e)
          setCountryid(e.id);
          setCountry(e.name);
        }}
        placeHolder="Select Country"
      />
          </div>
          <div class="col-md-4">
          <label for="defaultFormControlInput" class="form-label">State</label>
          <StateSelect
        countryid={countryid}
        onChange={(e) => {
          setstateid(e.id);
          setState(e.name);
        }}
        placeHolder="Select State"
      />
          </div>
          <div class="col-md-4">
          <label for="defaultFormControlInput" class="form-label">City</label>
          <CitySelect
        countryid={countryid}
        stateid={stateid}
        onChange={(e) => {
          
          setCity(e.name);
        }}
        placeHolder="Select City"
      />
          </div>
        </div>
      <div class="row mrgBtm4">
        <div class="col-md-6">
        <div>
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Password</label>
          <input type="password" required class="form-control" placeholder="Enter Password" onChange={(e) => setPassword(e.target.value)}/>
          
        </div>
        </div>
        <div class="col-md-6">
        <div>
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Confirm Password</label>
          <input type="password" required class="form-control" placeholder="Enter Confirm Password" onChange={(e) => setConfirmpassword(e.target.value)}/>
          
        </div>
        </div>
      </div>

        <div class="row mt-3">
          <div class="d-grid gap-2 col-lg-6 mx-auto">
            <button class="btn btn-primary btn-lg waves-effect waves-light" type="submit">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</div>
</form>
    
            </div>
           
            <Footer/>

  
 
</div>
</div>
           
            
</div>



</div>
</div>
  );
}

export { Adduser };
