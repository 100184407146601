import React, { useEffect,useState,useCallback } from "react";
import config from "../config";
import axios from "axios";
import { useNavigate,Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader"; 
import "./login.css";
import { Header } from "./header";
import { Aside } from "./aside";
import { Footer } from "./footer";
import {Accordion, AccordionBody, AccordionHeader, AccordionItem} from "react-headless-accordion";
import 'react-tabs/style/react-tabs.css';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

function Listproductfile() {
 

  const navigate = useNavigate();
  let   [loadingInProgress, setLoading] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  const [product, setProductname] = useState("");
  const [category, setCategory] = useState([]);
  const [fileProduct, setfileProduct] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [description, setDescription] = useState("");
  const [productImage, setProductimage] = useState("");
  const dataToPass = { name: 'John Doe', age: 25 };

  const handleDragEnd2 = (result) => {
    console.log(result)
  
   const file1Product = Array.from(fileProduct);
   
var str = result.destination.droppableId.split('-');

var tt =str[1];
var ttt =str[2];
      const [movedRow] = file1Product[tt].subArray[ttt].finalArray.splice(result.source.index, 1);
      file1Product[tt].subArray[ttt].finalArray.splice(result.destination.index, 0, movedRow);

    //setfileProduct(file1Product);

    let getloginuser = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: id,
        ids: file1Product[tt].id,
        idss: file1Product[tt].subArray[ttt].id,
        mainarr: file1Product[tt].subArray[ttt].finalArray,
        
      }),
    };
    return setLoading(true) ,fetch(config.backend_URL + "/updateproductfinalfile", getloginuser)
    .then((response) => response.json())
    .then((responseJson) => {
      setLoading(false)
          if (responseJson.status) {
            toast(responseJson.msz);
            fetchData1();
        } else {
          toast(responseJson.msz);
        }
    })
    .catch((error) => {
      toast('It will take some time!');
      setLoading(false)
      //console.error(error);
    });
  }
  const handleDragEnd1 = (result) => {
    console.log(result)
  
   const file1Product = Array.from(fileProduct);
   
var str = result.destination.droppableId.split('-');

var tt =str[1];
      const [movedRow] = file1Product[tt].subArray.splice(result.source.index, 1);
      file1Product[tt].subArray.splice(result.destination.index, 0, movedRow);

    //setfileProduct(file1Product);
    
    let getloginuser = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: id,
        ids: file1Product[tt].id,
        mainarr: file1Product[tt].subArray,
        
      }),
    };
    return setLoading(true) ,fetch(config.backend_URL + "/updateproductsubfile", getloginuser)
    .then((response) => response.json())
    .then((responseJson) => {
      setLoading(false)
          if (responseJson.status) {
            toast(responseJson.msz);
            fetchData1();
        } else {
          toast(responseJson.msz);
        }
    })
    .catch((error) => {
      toast('It will take some time!');
      setLoading(false)
      //console.error(error);
    });
  };


  const handleDragEnd = (result) => {
    console.log(result)
    if (!result.destination) return;

    const newRows = Array.from(fileProduct);
    const [movedRow] = newRows.splice(result.source.index, 1);
    newRows.splice(result.destination.index, 0, movedRow);

    //setfileProduct(newRows);

    let getloginuser = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: id,
        mainarr: newRows,
        
      }),
    };
    return setLoading(true) ,fetch(config.backend_URL + "/updateproductfile", getloginuser)
    .then((response) => response.json())
    .then((responseJson) => {
      setLoading(false)
          if (responseJson.status) {
            toast(responseJson.msz);
            fetchData1();
        } else {
          toast(responseJson.msz);
        }
    })
    .catch((error) => {
      toast('It will take some time!');
      setLoading(false)
      
    });
  };
  
  let handleSubmit =  (e) => {
    e.preventDefault();
    setLoading(true)
    let formData = new FormData(); //formdata object
    formData.append("IMAGE", productImage);
    formData.append("productName", product);
    formData.append("categoryId", categoryId);
    formData.append("categoryName", categoryName);
    formData.append("description", description);
  
      axios.post(`${config.backend_URL}/addProduct`, formData)
        .then( (data) => {
         
          setLoading(false)
          toast(data.data.msz);
          e.target.reset();
        }).catch((err) => {
          console.log("error", err);
        });
  };
  
  const fetchData = () => {
    //e.preventDefault()
    setLoading(true)
    let getloginuser = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        type: 'type',
        
      }),
    };
    return setLoading(true) ,fetch(config.backend_URL + "/getCategory", getloginuser)
    .then((response) => response.json())
    .then((responseJson) => {
      setLoading(false)
          if (responseJson.status) {
            setCategory(responseJson.data)
        } else {
          toast(responseJson.msz);
        }
    })
    .catch((error) => {
      toast('It will take some time!');
      setLoading(false)
      //console.error(error);
    });
  
      setLoading(false)
  };
  const onFileChange = async (e) => {
    e.preventDefault();
    

      setProductimage(e.target.files[0]);
   
    
  };
  const handleClick = e => {
    setCategoryId(e.target.value)
    
    setCategoryName(e.target[e.target.selectedIndex].getAttribute('data-value'));

   
  };
  const fetchData1 = () => {
    //e.preventDefault()
    setLoading(true)
    let getloginuser = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: id,
        
      }),
    };
    return setLoading(true) ,fetch(config.backend_URL + "/getProductfiledata", getloginuser)
    .then((response) => response.json())
    .then((responseJson) => {
      setLoading(false)
      //console.log(responseJson);
          if (responseJson.status) {
           
           
            setfileProduct(responseJson.data[0].productFile)
            
            
            
            
            
          
        } else {
          toast(responseJson.msz);
          
          //setLoading(false)
          
        }
      
    })
    .catch((error) => {
      toast('It will take some time!');
      setLoading(false)
      //console.error(error);
    });
  
      setLoading(false)
  };
   

  const deleteData = async (e,url,id,ids,ids1,index) => {
    e.preventDefault();
   //alert(ids)
  
   let getloginuser = {
     method: "POST",
     headers: {
       Accept: "application/json",
       "Content-Type": "application/json",
     },
     body: JSON.stringify({
       id: id,
       ids: ids,
       ids1: ids1,
       index: index,
       
     }),
   };
   return setLoading(true) ,fetch(config.backend_URL + url, getloginuser)
   .then((response) => response.json())
   .then((responseJson) => {
     setLoading(false)
     //console.log(responseJson);
         if (responseJson.status) {
           fetchData1();
           toast(responseJson.msz);
       } else {
         toast(responseJson.msz);
         
         //setLoading(false)
         
       }
     
   })
   .catch((error) => {
     toast('It will take some time!');
     setLoading(false)
     //console.error(error);
   });
  };

  useEffect(() => {
    //setLoading(true) ;
    fetchData();
    fetchData1();
  }, []);

 
  return (
    <div className="container-scroller">
      {loadingInProgress ? <div className="parentdiv"><div className="loaderclsdiv"><ClipLoader className="loadercls" text-align="center" color={'#000'} loading={loadingInProgress}  size={35} /></div></div>: ""}
      {" "}
      <ToastContainer />
    
      <div class="layout-wrapper layout-content-navbar">
      <div class="layout-container">
    
<Aside/>
<div class="layout-page">


<Header/>
<div class="content-wrapper">


<div class="container-xxl flex-grow-1 container-p-y">
  
  
<div class="row">
<div class="col-md-6">
<h4 class="py-3 mb-4">
<span class="text-muted fw-light">Product Files</span> 
</h4>

</div>
<div class="col-md-6" >

<Link class="dt-button add-new btn btn-primary ms-2 ms-sm-0" tabindex="0" aria-controls="DataTables_Table_0" to={config.front_URL +"/product"}><span><span class="d-none d-sm-inline-block">Back</span></span></Link>&nbsp;
<Link class="dt-button add-new btn btn-primary ms-2 ms-sm-0" tabindex="0" aria-controls="DataTables_Table_0" to={config.front_URL +"/addProductfile?id="+id+"&folder=category"}><span><span class="d-none d-sm-inline-block">Add</span></span></Link>
</div>
</div>
{fileProduct.length != 0?
 <DragDropContext onDragEnd={handleDragEnd}>
 <Droppable droppableId="table">
 {(provided) => (
  <table  cellspacing="0" width="100%" {...provided.droppableProps} ref={provided.innerRef}>
<Accordion>

{fileProduct.sort((a,b) => a.sortKey - b.sortKey).map((contentdata,indexxxx) => {
                            return (
                              <div class="mainArr">
                                  
            
            <AccordionItem>
            <Draggable key={contentdata.id} draggableId={contentdata.id} index={indexxxx}>
            {(provided) => (
            <tr class="acctr "  ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                               
                                               <td>
                                                {(contentdata.subArray.length != 0)?
                                                
                                                <AccordionHeader className="bdRm"> <button class="btn btn-secondary " ><i class="fa fa-chevron-down"></i></button>&nbsp;</AccordionHeader>
                                                :
                                                ''
                                                }
                                                        <Link class="btn btn-info" to={{ pathname: "/editProductfile", search:"?id="+id+"&folder=category&ids="+contentdata.id+"&parentFloder="+contentdata.title}} state={{showUser: contentdata.showUser,title:contentdata.title,file:contentdata.file,fileSize:contentdata.fileSize,imageview:contentdata.file}}><i class="fa fa-pencil" aria-hidden="true"></i></Link>&nbsp; 
                                                       <Link class="btn btn-primary" to={config.front_URL +"/addProductfile?id="+id+"&folder=subcat&ids="+contentdata.id+"&parentFloder="+contentdata.title}><i class="fa fa-plus" aria-hidden="true"></i></Link>&nbsp;
                                                      
                                                       <a class="btn btn-primary" style={{color:'#fff'}} ><i class="fa fa-bars" aria-hidden="true"></i></a>&nbsp;
                                                       </td>
                                                       
                                                   <td><b>{contentdata.showUser}</b></td>
                                                   <td>{contentdata.title}</td>
                                                   <td> <button class="btn btn-danger" type="button" onClick={(e) => deleteData(e,'/deleteProductmain',id,contentdata.id,'f','0')}><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;</td>
                                               </tr>
                                                )}
                                               </Draggable>
                <AccordionBody >
                <DragDropContext onDragEnd={handleDragEnd1}>
 <Droppable droppableId={`subarr-${indexxxx}`} >
 {(provided) => (
                                                <div class="" {...provided.droppableProps} ref={provided.innerRef}>                          
                {contentdata.subArray.sort((a,b) => a.sortKey - b.sortKey).map((contentdata1,index) => {
                  var itm=index++
                  var itmtt= index - 1
                                              return (
                                                
                                                <div class="subArr" >
                          <AccordionItem>
                          <Draggable key={contentdata1.id} draggableId={contentdata1.id} index={itmtt}>
            {(provided) => (
                                                <tr  class="acctr"  ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                
                                                <td>
                                                {(contentdata1.finalArray.length != 0)?
                                                
                                                <AccordionHeader className="bdRm"> <button class="btn btn-secondary " ><i class="fa fa-chevron-down"></i></button>&nbsp;</AccordionHeader>
                                                :
                                                ''
                                                }
                                                        <Link class="btn btn-info" to={{ pathname: "/editProductfile", search:"?id="+id+"&folder=subcat&ids="+contentdata.id+"&index="+ itm +"&parentFloder="+contentdata.title+"&idss="+contentdata1.id}} state={{showUser: contentdata1.showUser,title:contentdata1.title,file:contentdata1.file,fileSize:contentdata1.fileSize,imageview:contentdata1.file}}><i class="fa fa-pencil" aria-hidden="true"></i></Link>&nbsp; 
                                                        <Link class="btn btn-primary" to={config.front_URL +"/addProductfile?id="+id+"&folder=finalcat&ids="+contentdata1.id+"&index="+ itm +"&parentFloder="+contentdata1.title}><i class="fa fa-plus" aria-hidden="true"></i></Link>&nbsp;
                                                        <a class="btn btn-primary" style={{color:'#fff'}} ><i class="fa fa-bars" aria-hidden="true"></i></a>&nbsp;
                                                        </td>
                                                        
                                                    <td><b>{contentdata1.showUser}</b></td>
                                                    <td>{contentdata1.title}</td>
                                                    <td> <button class="btn btn-danger" onClick={(e) => deleteData(e,'/deleteProductsub',id,contentdata.id,contentdata1.id,'0')}><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;</td>
                                                </tr>
                                                 )}
                                                 </Draggable>
                            <AccordionBody >
                            <DragDropContext onDragEnd={handleDragEnd2}>
 <Droppable droppableId={`subarr-${indexxxx}-${itmtt}`} >
 {(provided) => (
                                                <div class="" {...provided.droppableProps} ref={provided.innerRef}>  
                            {contentdata1.finalArray.sort((a,b) => a.sortKey - b.sortKey).map((contentdata2,index1) => {
                              var itm33= index1
                                                                return (
                                                                  <div class="finalArr">
                                                                 <Draggable key={contentdata2.id} draggableId={contentdata2.id} index={itm33}>
            {(provided) => ( 
                                                                  <tr class="acctr" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                 
                                                                 
                                                                  <td>
                                                                  <Link class="btn btn-info" to={{ pathname: "/editProductfile", search:"?id="+id+"&folder=finalcat&ids="+contentdata2.id+"&index="+ itm +"&parentFloder="+contentdata1.title+"&index1="+ index1++}} state={{showUser: contentdata2.showUser,title:contentdata2.title,file:contentdata2.file,fileSize:contentdata2.fileSize,imageview:contentdata2.file}}><i class="fa fa-pencil" aria-hidden="true"></i></Link>&nbsp; 
                                                                  <a class="btn btn-primary" style={{color:'#fff'}} ><i class="fa fa-bars" aria-hidden="true"></i></a>&nbsp;
                                                                          </td>
                                                                          
                                                                      <td><b>{contentdata2.showUser}</b></td>
                                                                      <td>{contentdata2.title}</td>
                                                                      <td> <button class="btn btn-danger" onClick={(e) => deleteData(e,'/deleteProductfinal',id,contentdata1.id,contentdata2.id,itm)}><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;</td>
                                                                  </tr>
                                                                  )}
                                                                  </Draggable>
                                                                <span ></span>
                                                                  </div>           
                                                                                   );         
                                                                                  })} 
                                                                                  </div>
            )}
            </Droppable>
</DragDropContext>
                            </AccordionBody>
                          </AccordionItem>
                      
                          </div>  
                             
            );         
           })} 
           </div>
            )}
            </Droppable>
</DragDropContext>
                </AccordionBody>
            </AccordionItem>
           
            </div>
                                            );
                                          })}
        </Accordion>
                                          </table>
                                           )}
                                          </Droppable>
    </DragDropContext>
:

<span>No Data Found</span>


}


            </div>
           
            <Footer/>

  
 
</div>
</div>
           
            
</div>



</div>
</div>
  );
}

export { Listproductfile };
