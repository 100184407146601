import React, { useEffect,useState } from "react";
import config from "../config";
import axios from "axios";
import { useNavigate,Link,useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader"; 
import "./login.css";
import { Header } from "./header";
import { Aside } from "./aside";
import { Footer } from "./footer";
import CKEditor from "react-ckeditor-component";
import moment from 'moment';
function Editinbox() {
  const location = useLocation()
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  const navigate = useNavigate();
  let   [loadingInProgress, setLoading] = useState(false);
  let   [caside, setaside] = useState(false);
  const [date, setDate] = useState(moment(location.state.date).format("DD/MM/YYYY h:m"));
  const [name, setName] = useState(location.state.name);
  const [email, setEmail] = useState(location.state.email);
  const [phone, setPhone] = useState(location.state.phone);
  const [subject, setSubject] = useState(location.state.subject);
  const [fulltext, setFulltext] = useState(location.state.description);
 

  
 
  
  const deleteData = async (e,id) => {
    e.preventDefault();
  
  
   let getloginuser = {
     method: "POST",
     headers: {
       Accept: "application/json",
       "Content-Type": "application/json",
     },
     body: JSON.stringify({
       id: id,
      
       
     }),
   };
   return setLoading(true) ,fetch(config.backend_URL + '/deleteInbox', getloginuser)
   .then((response) => response.json())
   .then((responseJson) => {
     setLoading(false)
     console.log(responseJson);
   
         if (responseJson.status) {
           toast(responseJson.msz);
           navigate("/inbox");
       } else {
         toast(responseJson.msz);
         
         //setLoading(false)
         
       }
     
   })
   .catch((error) => {
     console.error(error);
     toast('It will take some time!');
     setLoading(false)
   });
  };
  const fetchData1 = () => {
    //e.preventDefault()
    setLoading(true)
    let getloginuser = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: id,
        
      }),
    };
    return setLoading(true) ,fetch(config.backend_URL + "/updateallInbox", getloginuser)
    .then((response) => response.json())
    .then((responseJson) => {
      setLoading(false)
      setaside(true)
       
      
    })
    .catch((error) => {
      toast('It will take some time!');
      setLoading(false)
      //console.error(error);
    });
  
      setLoading(false)
  };
  useEffect(() => {
    //setLoading(true) ;
    //alert(id)
    fetchData1();
  }, []);
  return (
    <div className="container-scroller">
      {loadingInProgress ? <div className="parentdiv"><div className="loaderclsdiv"><ClipLoader className="loadercls" text-align="center" color={'#000'} loading={loadingInProgress}  size={35} /></div></div>: ""}
      {" "}
      <ToastContainer />
    
      <div class="layout-wrapper layout-content-navbar">
      <div class="layout-container">
   <Aside/>
<div class="layout-page">


<Header/>
<div class="content-wrapper">


<div class="container-xxl flex-grow-1 container-p-y">
  
<div class="row">
    <div class="col-md-6">
        <h4 class="py-3 mb-4">
<span class="text-muted fw-light">View Inbox</span> 
</h4>
</div>
    <div class="col-md-6">
    <button style={{float:'right',marginLeft:'5px'}} class="btn btn-danger " type="button" onClick={(e) => deleteData(e,id)}><i class="fa fa-trash" aria-hidden="true"></i></button>
    <Link class="dt-button add-new btn btn-primary ms-2 ms-sm-0" tabindex="0" aria-controls="DataTables_Table_0" to={config.front_URL +"/inbox"}><span><span class="d-none d-sm-inline-block">Back</span></span></Link>
    
    
    </div>
</div>




<form >
<div class="row">
<div class="col-md-12">
    <div class="card mb-4">
      {/* <h5 class="card-header">Add Content</h5> */}
      <div class="card-body">
        <div class="row">
              <div class="col-md-6">
                <div>
          <label for="defaultFormControlInput" class="form-label">Sent</label>
         
          <input type="text" required class="form-control" placeholder="Enter Type" value={date}/>
          
        </div>
        </div>
              <div class="col-md-6">
              <div>
          <label for="defaultFormControlInput" class="form-label">Name</label>
          <input type="text" required class="form-control" placeholder="Name"  value={name}/>
          
        </div>
              </div>
        </div>
        
        <div class="row">
              <div class="col-md-6">
                <div>
          <label for="defaultFormControlInput" class="form-label">Phone</label>
         
          <input type="text" required class="form-control" placeholder="Enter Type" value={phone}/>
          
        </div>
        </div>
              <div class="col-md-6">
              <div>
          <label for="defaultFormControlInput" class="form-label">Email</label>
          <input type="text" required class="form-control" placeholder="Enter Content"  value={email}/>
          
        </div>
              </div>
        </div>
        
        
        <div>
          <label for="defaultFormControlInput" class="form-label">Subject</label>
          <input type="type" required class="form-control" placeholder="Enter Title" value={subject}/>
          
        </div>
        <div>
          <label for="exampleFormControlTextarea1" class="form-label">Description</label>
          <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"  value={fulltext}></textarea>
        </div>
      
       

        {/* <div class="row mt-3">
          <div class="d-grid gap-2 col-lg-6 mx-auto">
            <button class="btn btn-primary btn-lg waves-effect waves-light" type="submit">Save</button>
          </div>
        </div> */}
      </div>
    </div>
  </div>
  
</div>
</form>
    
            </div>
           
            <Footer/>

  
 
</div>
</div>
           
            
</div>



</div>
</div>
  );
}

export { Editinbox };
