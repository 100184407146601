import React, { useEffect,useState } from "react";
import config from "../config";
import axios from "axios";
import { useNavigate,Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader"; 
import "./login.css";
import { Header } from "./header";
import { Aside } from "./aside";
import { Footer } from "./footer";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import moment from 'moment-timezone';
function Systemmanager() {
  const navigate = useNavigate();
  let   [loadingInProgress, setLoading] = useState(false);
  const [type, setType] = useState([]);
  const [admin, setAdmin] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    firstName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    type: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    phone: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
   
});
const onGlobalFilterChange = (e) => {
  const value = e.target.value;
  let _filters = { ...filters };

  _filters['global'].value = value;

  setFilters(_filters);
  setGlobalFilterValue(value);
};

  const fetchData = async () => {
    //e.preventDefault()
    setLoading(true)
    let getloginuser = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        type: 'type',
        
      }),
    };
    return setLoading(true) ,fetch(config.backend_URL + "/getAdminType", getloginuser)
    .then((response) => response.json())
    .then((responseJson) => {
      setLoading(false)
          if (responseJson.status) {
           
            console.log(responseJson)
            setType(responseJson.data)
           
        } else {
          toast(responseJson.msz);
         
        }
      
    })
    .catch((error) => {
      toast('It will take some time!');
      setLoading(false)
      //console.error(error);
    });
  
      setLoading(false)
  };
  const fetchData1 = async () => {
    //e.preventDefault()
    setLoading(true)
    let getloginuser = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        type: 'type',
        
      }),
    };
    return setLoading(true) ,fetch(config.backend_URL + "/getAdmin", getloginuser)
    .then((response) => response.json())
    .then((responseJson) => {
      setLoading(false)
          if (responseJson.status) {
           
            console.log(responseJson)
            setAdmin(responseJson.data)
           
        } else {
          toast(responseJson.msz);
         
        }
      
    })
    .catch((error) => {
      toast('It will take some time!');
      setLoading(false)
      //console.error(error);
    });
  
      setLoading(false)
  };
  const renderHeader = () => {
    return (
      <div class="row">
<div class="col-md-6">

              
<InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search Name" />
         
   
</div>
<div class="col-md-6">
<Link class="dt-button add-new btn btn-primary ms-2 ms-sm-0" tabindex="0" aria-controls="DataTables_Table_0" to={config.front_URL +"/addAdmin"} ><span><i class="fa fa-plus me-0 me-sm-1 ti-xs"></i><span class="d-none d-sm-inline-block">Add</span></span></Link></div>

      </div>
      
    );
};
const renderHeaderType = () => {
  return (
    <div class="row">
<div class="col-md-6">

            
<InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search Name" />
       
 
</div>
<div class="col-md-6">
<Link class="dt-button add-new btn btn-primary ms-2 ms-sm-0" tabindex="0" aria-controls="DataTables_Table_0" to={config.front_URL +"/addAdmintype"} ><span><i class="fa fa-plus me-0 me-sm-1 ti-xs"></i><span class="d-none d-sm-inline-block">Add</span></span></Link></div>

    </div>
    
  );
};
  const header = renderHeader();
  const headerType = renderHeaderType();
  const rowClass = (data) => {
    return {
        'bg-primary': data.category === 'Fitness'
    };
};
const actionBodyTemplate3 = (rowData) => {
  //console.log(index)
 
    return (
      <React.Fragment>
    
       { moment(rowData.dateTime).tz("Israel").format("DD/MM/YYYY h:m")}
      </React.Fragment>
    )
            
   
  
  };
  const actionBodyTemplate4 = (rowData) => {
    //console.log(index)
   
      return (
        <React.Fragment>
        
          { moment(rowData.createdDate).tz("Israel").format("DD/MM/YYYY h:m")}
        </React.Fragment>
      )
              
     
    
    };
    const actionBodyTemplate3type = (rowData) => {
      //console.log(index)
     
        return (
          <React.Fragment>
        
           { moment(rowData.dateTime).tz("Israel").format("DD/MM/YYYY h:m")}
          </React.Fragment>
        )
                
       
      
      };
      const actionBodyTemplate4type = (rowData) => {
        //console.log(index)
       
          return (
            <React.Fragment>
            
              { moment(rowData.createdDate).tz("Israel").format("DD/MM/YYYY h:m")}
            </React.Fragment>
          )
                  
         
        
        };
    const actionBodyTemplate = (rowData) => {
      return (
          <React.Fragment>
              <Link   className="btn btn-info" to={{ pathname: "/editadmin", search:"?id="+rowData._id}} state={{type:rowData.type,status:rowData.status,firstName:rowData.firstName,lastName:rowData.lastName,phone:rowData.phone,email:rowData.email}} ><i class="fa fa-pencil"></i></Link>&nbsp;
              <button class="btn btn-danger " type="button" onClick={(e) => deleteData(e,rowData._id)}><i class="fa fa-trash" aria-hidden="true"></i></button> &nbsp;
             
            
          </React.Fragment>
      );
    };
    const actionBodyTemplateType = (rowData) => {
      return (
          <React.Fragment>
              <Link   className="btn btn-info"  to={{ pathname: "/editadmintype", search:"?id="+rowData._id}} state={{type:rowData.type,UsersPermission:rowData.UsersPermission,PresentationPermission:rowData.PresentationPermission,ProductsPermission:rowData.ProductsPermission,TraningPermission:rowData.TraningPermission,FormsPermission:rowData.FormsPermission,ContactusPermission:rowData.ContactusPermission,AppcontentPermission:rowData.AppcontentPermission,InboxPermission:rowData.InboxPermission,SystemmanagerPermission:rowData.SystemmanagerPermission,SettingsPermission:rowData.SettingsPermission,MessagePermission:rowData.MessagePermission}} ><i class="fa fa-pencil"></i></Link>&nbsp;
              <button class="btn btn-danger " type="button" onClick={(e) => deleteData1(e,rowData._id)}><i class="fa fa-trash" aria-hidden="true"></i></button> &nbsp;
             
            
          </React.Fragment>
      );
    };
    const deleteData = async (e,id) => {
      e.preventDefault();
    
    
     let getloginuser = {
       method: "POST",
       headers: {
         Accept: "application/json",
         "Content-Type": "application/json",
       },
       body: JSON.stringify({
         id: id,
        
         
       }),
     };
     return setLoading(true) ,fetch(config.backend_URL + '/deleteadmin', getloginuser)
     .then((response) => response.json())
     .then((responseJson) => {
       setLoading(false)
       //console.log(responseJson);
       fetchData();
       fetchData1();
           if (responseJson.status) {
             toast(responseJson.msz);
         } else {
           toast(responseJson.msz);
           
           //setLoading(false)
           
         }
       
     })
     .catch((error) => {
       console.error(error);
       toast('It will take some time!');
       setLoading(false)
     });
    };
    const deleteData1 = async (e,id) => {
      e.preventDefault();
    
    
     let getloginuser = {
       method: "POST",
       headers: {
         Accept: "application/json",
         "Content-Type": "application/json",
       },
       body: JSON.stringify({
         id: id,
        
         
       }),
     };
     return setLoading(true) ,fetch(config.backend_URL + '/deleteadmintype', getloginuser)
     .then((response) => response.json())
     .then((responseJson) => {
       setLoading(false)
       //console.log(responseJson);
       fetchData();
           if (responseJson.status) {
             toast(responseJson.msz);
         } else {
           toast(responseJson.msz);
           
           //setLoading(false)
           
         }
       
     })
     .catch((error) => {
       console.error(error);
       toast('It will take some time!');
       setLoading(false)
     });
    };
  useEffect(() => {
    //setLoading(true) ;
    
    fetchData();
    fetchData1();
  }, []);
  return (
    <div className="container-scroller">
    {loadingInProgress ? <div className="parentdiv"><div className="loaderclsdiv"><ClipLoader className="loadercls" text-align="center" color={'#000'} loading={loadingInProgress}  size={35} /></div></div>: ""}
    {" "}
    <ToastContainer />
  
    <div class="layout-wrapper layout-content-navbar">
    <div class="layout-container">
  
<Aside/>
<div class="layout-page">


<Header/>
    
    <div class="content-wrapper">


<div class="container-xxl flex-grow-1 container-p-y">
  
  

<h4 class="py-3 mb-4">
<span class="text-muted fw-light">System admins</span> 
</h4>






<div class="card">
<div class="card-datatable">
  
   
                                
                                <Tabs>
    <TabList>
      <Tab>Admin list</Tab>
      <Tab>Admin types</Tab>
    </TabList>

    <TabPanel>
    
     
    
                    <DataTable showGridlines  rowClassName={rowClass} header={header} globalFilterFields={['type','firstName','email','phone']} dataKey="id"  filters={filters} filterDisplay="row" value={admin} tableStyle={{ minWidth: '10rem'}} paginator  rows={10} rowsPerPageOptions={[5, 10, 25, 50]}  emptyMessage="No customers found.">
      <Column body={actionBodyTemplate} exportable={false} style={{ width:'17%',minWidth: '2rem',borderTop: "1px solid #ADB5BD" }}></Column>
      <Column field="_id"  sortable header="Id" style={{ minWidth: '2rem',borderTop: "1px solid #ADB5BD" }}></Column>
      <Column field="status" sortable header="Status"   style={{ minWidth: '3rem',borderTop: "1px solid #ADB5BD",padding:"10px" }}></Column>
      <Column field="type" sortable header="User Type"   style={{ minWidth: '3rem',borderTop: "1px solid #ADB5BD",padding:"10px" }}></Column>
      <Column field="firstName" sortable header="Name"   style={{ minWidth: '3rem',borderTop: "1px solid #ADB5BD",padding:"10px" }}></Column>
      <Column field="email" sortable header="Email"   style={{ minWidth: '3rem',borderTop: "1px solid #ADB5BD",padding:"10px" }}></Column>
    <Column field="phone" sortable header="phone"   style={{ minWidth: '3rem',borderTop: "1px solid #ADB5BD",padding:"10px" }}></Column>
   
    <Column field="dateTime" sortable header="Updated" body={actionBodyTemplate3} style={{ minWidth: '2rem',borderTop: "1px solid #ADB5BD",padding:"10px" }}></Column>
    <Column field="createdDate" sortable header="Created" body={actionBodyTemplate4} style={{ minWidth: '2rem',borderTop: "1px solid #ADB5BD",padding:"10px" }}></Column>
   
</DataTable>                
                               
    
    
           
    </TabPanel>
    <TabPanel>
    
    
    <DataTable showGridlines  rowClassName={rowClass} header={headerType} globalFilterFields={['type']} dataKey="id"  filters={filters} filterDisplay="row" value={type} tableStyle={{ minWidth: '10rem'}} paginator  rows={10} rowsPerPageOptions={[5, 10, 25, 50]}  emptyMessage="No customers found.">
      <Column body={actionBodyTemplateType} exportable={false} style={{ width:'17%',minWidth: '2rem',borderTop: "1px solid #ADB5BD" }}></Column>
      <Column field="_id"  sortable header="Id" style={{ minWidth: '2rem',borderTop: "1px solid #ADB5BD" }}></Column>
   
      <Column field="type" sortable header="Type Name"   style={{ minWidth: '3rem',borderTop: "1px solid #ADB5BD",padding:"10px" }}></Column>
     
   
    <Column field="dateTime" sortable header="Last Seen" body={actionBodyTemplate3type} style={{ minWidth: '2rem',borderTop: "1px solid #ADB5BD",padding:"10px" }}></Column>
    <Column field="createdDate" sortable header="Updates" body={actionBodyTemplate4type} style={{ minWidth: '2rem',borderTop: "1px solid #ADB5BD",padding:"10px" }}></Column>
   
</DataTable> 
                                    
                               
    </TabPanel>
  </Tabs>
  
    
  </div>
</div>


  </div>
 

  
  



<Footer/>

  
 
</div>
</div>
           
            
</div>



</div>
</div>
    
  );
}

export { Systemmanager };
