import React, { useState } from "react";
import config from "../config";
import axios from "axios";
import { useNavigate,Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader"; 
import "./login.css";
import { Header } from "./header";
import { Aside } from "./aside";
import { Footer } from "./footer";
import CKEditor from "react-ckeditor-component";

function Addcontact() {
  const navigate = useNavigate();
  let   [loadingInProgress, setLoading] = useState(false);
  const [Officename, setOfficename] = useState("");
  const [address, setAddress] = useState("");
  const [teltitle, setTeltitle] = useState("");
  const [phonenumber, setPhonenumber] = useState("");
  const [faxtitle, setFaxtitle] = useState("");
  const [fax, setFax] = useState("");
  
  const [tel2title, setTel2title] = useState("");
  const [phone2number, setPhone2number] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [linkedinaddress, setLinkedinaddress] = useState("");
  
 

  let handleSubmit =  (e) => {
    e.preventDefault()
   setLoading(true) ;
   // navigate("/user");
    //alert(fulltext);
    
    let getloginuser = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        officeName: Officename,
        address: address,
        telTitle: teltitle,
        phoneNumber: phonenumber,
        faxTitle: faxtitle,
        fax: fax,
        tel2Title: tel2title,
        email: email,
        website: website,
        linkedinAddress: linkedinaddress,
        phone2Number: phone2number,
        
        
      }),
    };
    fetch(config.backend_URL + "/addContact", getloginuser)
    .then((response) => response.json())
    .then((responseJson) => {
      console.log(responseJson);
      
         
       
        

          if (responseJson.status) {
            /*        localStorage.setItem('Id',data.data.data.ADMIN_ID); */
            setLoading(false)
          e.target.reset();
            
            
            toast(responseJson.msz);
            
            
          
        } else {
          toast(responseJson.msz);
          e.target.reset();
          setLoading(false)
          
        }
      
    })
    .catch((error) => {
      toast('It will take some time!');
      setLoading(false)
      console.error(error);
    });


    
  };
 
  return (
    <div className="container-scroller">
      {loadingInProgress ? <div className="parentdiv"><div className="loaderclsdiv"><ClipLoader className="loadercls" text-align="center" color={'#000'} loading={loadingInProgress}  size={35} /></div></div>: ""}
      {" "}
      <ToastContainer />
    
      <div class="layout-wrapper layout-content-navbar">
      <div class="layout-container">
    
<Aside/>
<div class="layout-page">


<Header/>
<div class="content-wrapper">


<div class="container-xxl flex-grow-1 container-p-y">
  
  

<div class="row">
    <div class="col-md-6">
        <h4 class="py-3 mb-4">
<span class="text-muted fw-light">Add Contact</span> 
</h4>
</div>
    <div class="col-md-6">
    <Link class="dt-button add-new btn btn-primary ms-2 ms-sm-0" tabindex="0" aria-controls="DataTables_Table_0" to={config.front_URL +"/contact"}><span><span class="d-none d-sm-inline-block">Back</span></span></Link>
    </div>
</div>


<form onSubmit={(e) => handleSubmit(e)}>
<div class="row">
<div class="col-md-12">
    <div class="card mb-4">
      {/* <h5 class="card-header">Add Content</h5> */}
      <div class="card-body">
      <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label">Office Name</label>
          {/* <input type="text"  class="form-control" placeholder="Enter Office Name" onChange={(e) => setOfficename(e.target.value)}/> */}
          <textarea class="form-control" id="exampleFormControlTextarea1" rows="2"  onChange={(e) => setOfficename(e.target.value)}></textarea>
        </div>
        <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label">Address</label>
          <input type="text"  class="form-control" placeholder="Enter Address" onChange={(e) => setAddress(e.target.value)}/>
          
        </div>
      <div class="row mrgBtm4">
              
              <div class="col-md-6">
              <div>
          <label for="defaultFormControlInput" class="form-label">Tel Title</label>
          <input type="text"  class="form-control" placeholder="Enter Tel Title" onChange={(e) => setTeltitle(e.target.value)}/>
          
        </div>
              </div>
              <div class="col-md-6">
              <div>
          <label for="defaultFormControlInput" class="form-label">Phone Number</label>
          <input type="text"  class="form-control" placeholder="Enter Phone Number" onChange={(e) => setPhonenumber(e.target.value)}/>
          
        </div>
              </div>
        </div>
        <div class="row mrgBtm4" >
              
              <div class="col-md-6">
              <div>
          <label for="defaultFormControlInput" class="form-label">Fax Title</label>
          <input type="text"  class="form-control" placeholder="Enter Fax Title" onChange={(e) => setFaxtitle(e.target.value)}/>
          
        </div>
              </div>
              <div class="col-md-6">
              <div>
          <label for="defaultFormControlInput" class="form-label">Fax</label>
          <input type="text"  class="form-control" placeholder="Enter Fax" onChange={(e) => setFax(e.target.value)}/>
          
        </div>
              </div>
        </div>
        <div class="row mrgBtm4">
              
              <div class="col-md-6">
              <div>
          <label for="defaultFormControlInput" class="form-label">Tel 2 Title </label>
          <input type="text"  class="form-control" placeholder="Enter Tel 2 Title" onChange={(e) => setTel2title(e.target.value)}/>
          
        </div>
              </div>
              <div class="col-md-6">
              <div>
          <label for="defaultFormControlInput" class="form-label">Phone Number</label>
          <input type="text"  class="form-control" placeholder="Enter Phone" onChange={(e) => setPhone2number(e.target.value)}/>
          
        </div>
              </div>
        </div>
        <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label">Email Address</label>
          <input type="email"  class="form-control" placeholder="Enter Email" onChange={(e) => setEmail(e.target.value)}/>
          
        </div>
        <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label">Website</label>
          <input type="text"  class="form-control" placeholder="Enter Website" onChange={(e) => setWebsite(e.target.value)}/>
          
        </div>
        <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label">Linkedin Address</label>
          <input type="text"  class="form-control" placeholder="Enter Linkedin Address" onChange={(e) => setLinkedinaddress(e.target.value)}/>
          
        </div>
       
        
       
       

        <div class="row mt-3">
          <div class="d-grid gap-2 col-lg-3 mx-auto">
            <button class="btn btn-primary btn-lg waves-effect waves-light" type="submit">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</div>
</form>
    
            </div>
           
            <Footer/>

  
 
</div>
</div>
           
            
</div>



</div>
</div>
  );
}

export { Addcontact };
