import React, { useEffect,useState } from "react";
import config from "../config";
import axios from "axios";
import { useNavigate,Link,useLocation} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader"; 
import "./login.css";
import { Header } from "./header";
import { Aside } from "./aside";
import { Footer } from "./footer";
import CKEditor from "react-ckeditor-component";
import Select,{
  components,
  MultiValueGenericProps,
  MultiValueProps,
  OnChangeValue,
  Props,
} from 'react-select'
import {
    CitySelect,
    CountrySelect,
    StateSelect,
    LanguageSelect,
  } from "react-country-state-city";
  import "react-country-state-city/dist/react-country-state-city.css";
function EditMessage() {

 
  const navigate = useNavigate();
  const location = useLocation()
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");

  let   [loadingInProgress, setLoading] = useState(false);
  const [sendto, setSendto] = useState(location.state.sendTo);
  const [pageapp, setPageapp] = useState(location.state.pageapp);
  const [title, setTitle] = useState(location.state.title);
  const [description, setDescription] = useState(location.state.message);
  const [position, setPosition] = useState([]);
  const [productImage, setProductimage] = useState("");
  const [country, setCountry] = useState(0);
  const [type, setType] = useState(location.state.status);
  const   [user, setuser] = useState([]);
  const   [user1, setuser1] = useState(location.state.user);
  const [product, setProduct] = useState([]);
  const [fileProduct, setfileProduct] = useState([]);
  const [fileProduct1, setfileProduct1] = useState([]);
  const [subpage, setSubpage] = useState(location.state.subpage);
  const [position1, setPosition1] = useState(location.state.position);
  
  let handleSubmit =  (e) => {
    e.preventDefault();
    setLoading(true)
  console.log(subpage);
    let getloginuser = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        status: type,
        title: title,
        message: description,
        sendto: sendto,
        subpage: (pageapp == 'product')?product[subpage]:(pageapp == 'forms')?fileProduct[subpage]:(pageapp == 'training')?fileProduct1[subpage]:{},
        user: (position1 == "" && sendto == "")?user:user1,
        position: position1,
        pageapp: pageapp,
        subpagekey:subpage,
        id:id
        
        
      }),
    };
    fetch(config.backend_URL + "/editMessage", getloginuser)
    .then((response) => response.json())
    .then((responseJson) => {
      console.log(responseJson);
      
         
       
        

          if (responseJson.status) {
            
            e.target.reset();
            setLoading(false)
            
            
            toast(responseJson.msz);
            
            
          
        } else {
          toast(responseJson.msz);
          e.target.reset();
          setLoading(false)
          
        }
      
    })
    .catch((error) => {
      toast('It will take some time!');
      setLoading(false)
      console.error(error);
    });
  
    
    
  };
  const onFileChange = async (e) => {
    e.preventDefault();
    

      setProductimage(e.target.files[0]);
   
    
  };
  const fetchData = () => {
    //e.preventDefault()
    setLoading(true)
    let getloginuser = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      
    };
    return setLoading(true) ,fetch(config.backend_URL1 + "getPosition", getloginuser)
    .then((response) => response.json())
    .then((responseJson) => {
      setLoading(false)
      //console.log(responseJson);
          if (responseJson.status) {
           
            setPosition(responseJson.data)
          
        } else {
          toast(responseJson.msz);
          
          //setLoading(false)
          
        }
      
    })
    .catch((error) => {
      toast('It will take some time!');
      setLoading(false)
      //console.error(error);
    });
  
      setLoading(false)
  };
  const options = []

  const fetchData1 = () => {
    //e.preventDefault()
    setLoading(true)
    let getloginuser = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        type: 'type',
        
      }),
    };
    return setLoading(true) ,fetch(config.backend_URL + "/getuserdataforadmin", getloginuser)
    .then((response) => response.json())
    .then((responseJson) => {
      setLoading(false)
          if (responseJson.status) {
            /*        localStorage.setItem('Id',data.data.data.ADMIN_ID); */
            responseJson.data.forEach(element => {
              var un= element.firstName+' '+element.lastName+' '+element.companyName+' '+element.position+' '+element.country;
              const entries = { value: element._id, label:un  ,readStatus:0 }  ;
              options.push(entries);
              
             
           });
           setuser(options);
            //console.log(user)
            
            //toast(responseJson.msz);
            
            
          
        } else {
          toast(responseJson.msz);
          
          //setLoading(false)
          
        }
      
    })
    .catch((error) => {
      toast('It will take some time!');
      setLoading(false)
      //console.error(error);
    });

      setLoading(false)
  };
  const getproduct = () => {
    //e.preventDefault()
    setLoading(true)
    let getloginuser = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        type: 'type',
        
      }),
    };
    return setLoading(true) ,fetch(config.backend_URL + "/getProducts", getloginuser)
    .then((response) => response.json())
    .then((responseJson) => {
      setLoading(false)
          if (responseJson.status) {
            /*        localStorage.setItem('Id',data.data.data.ADMIN_ID); */
            setProduct(responseJson.data)
            
            //toast(responseJson.msz);
            
            
          
        } else {
          toast(responseJson.msz);
          
          //setLoading(false)
          
        }
      
    })
    .catch((error) => {
      toast('It will take some time!');
      setLoading(false)
      //console.error(error);
    });

      setLoading(false)
  };
  const getforms = () => {
    //e.preventDefault()
    setLoading(true)
    let getloginuser = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        type: 'type',
        
      }),
    };
    return setLoading(true) ,fetch(config.backend_URL + "/getForms", getloginuser)
    .then((response) => response.json())
    .then((responseJson) => {
      setLoading(false)
          if (responseJson.status) {
            /*        localStorage.setItem('Id',data.data.data.ADMIN_ID); */
            setfileProduct(responseJson.data)
            
            //toast(responseJson.msz);
            
            
          
        } else {
          toast(responseJson.msz);
          
          //setLoading(false)
          
        }
      
    })
    .catch((error) => {
      toast('It will take some time!');
      setLoading(false)
      //console.error(error);
    });

      setLoading(false)
  };
  const gettraning = () => {
    //e.preventDefault()
    setLoading(true)
    let getloginuser = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        type: 'type',
        
      }),
    };
    return setLoading(true) ,fetch(config.backend_URL + "/getTraning", getloginuser)
    .then((response) => response.json())
    .then((responseJson) => {
      setLoading(false)
          if (responseJson.status) {
            /*        localStorage.setItem('Id',data.data.data.ADMIN_ID); */
            setfileProduct1(responseJson.data)
            
            //toast(responseJson.msz);
            
            
          
        } else {
          toast(responseJson.msz);
          
          //setLoading(false)
          
        }
      
    })
    .catch((error) => {
      toast('It will take some time!');
      setLoading(false)
      //console.error(error);
    });

      setLoading(false)
  };

  function handleSelect(data) {
    setuser1(data);
    console.log(user1)
  }
  useEffect(() => {
    //setLoading(true) ;
    //alert(id)
    fetchData();
    fetchData1();
    getproduct();
    getforms();
    gettraning();
  }, []);
  return (
    <div className="container-scroller">
      {loadingInProgress ? <div className="parentdiv"><div className="loaderclsdiv"><ClipLoader className="loadercls" text-align="center" color={'#000'} loading={loadingInProgress}  size={35} /></div></div>: ""}
      {" "}
      <ToastContainer />
    
      <div class="layout-wrapper layout-content-navbar">
      <div class="layout-container">
    
<Aside/>
<div class="layout-page">


<Header/>
<div class="content-wrapper">


<div class="container-xxl flex-grow-1 container-p-y">
  
  
<div class="row">
<div class="col-md-6">
<h4 class="py-3 mb-4">
<span class="text-muted fw-light">Edit Message</span> 
</h4>

</div>
<div class="col-md-6" >

<Link class="dt-button add-new btn btn-primary ms-2 ms-sm-0" tabindex="0" aria-controls="DataTables_Table_0" to={config.front_URL +"/message"}><span><span class="d-none d-sm-inline-block">Back</span></span></Link>&nbsp;

</div>
</div>



<form onSubmit={(e) => handleSubmit(e)}>
<div class="row">
<div class="col-md-12">
    <div class="card mb-4">
      {/* <h5 class="card-header">Add Content</h5> */}
      <div class="card-body">
        <div class="row mrgBtm4">
              <div class="col-md-6">
                <div>
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Status</label>
          <select class="form-control" required onChange={(e) => setType(e.target.value)} value={type} >
<option value="">Select Status</option>
<option value="send now">Send Now</option>
<option value="send later">Send Later</option>
          </select>
          
        </div>
        </div>
              <div class="col-md-6">
              <div>
              <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Message title</label>
          <input required type="text"  class="form-control" placeholder="Enter Title" onChange={(e) => setTitle(e.target.value)} value={title}/>
          
        </div>
              </div>
        </div>
        
        
        
        
        <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Message text</label>
          <textarea required class="form-control" id="exampleFormControlTextarea1" rows="3" onChange={(e) => setDescription(e.target.value)} value={description}>{description}</textarea>
          
        </div>
        <div class="row mrgBtm4">
        <div class="col-md-6 mrgBtm4">
          <label for="defaultFormControlInput" class="form-label">Send to</label>
          <select  class="form-control"  onChange={(e) => setSendto(e.target.value)} value={sendto} >
            <option value="">All Users</option>
        
            {/* <option value="All Users">All Users</option> */}
            <option value="USA users">Usa Users</option>
            <option value="Non Usa Users">Non Usa Users</option>
            <option value="Target User">Target User</option>

          </select>
          
        </div><div class="col-md-6 mrgBtm4">
          <label for="defaultFormControlInput" class="form-label">Send to user position</label>
          <select  class="form-control"  onChange={(e) => setPosition1(e.target.value)} value={position1}>
          <option value=""> Send To All</option>
            {position.map((position) => {
                            return (
<option value={position.tiltle} >{position.tiltle}</option>
                              );
                            })}

          </select>
          
        </div>
        
        <div class="col-md-12 mrgBtm4">
          <label for="defaultFormControlInput" class="form-label">{(position1 == "" && sendto == "")?'':<span style={{color:'red'}}>*</span>}Users</label>
         
          <Select   value={user1} required={(position1 == "" && sendto == "")?false:true} options={user} closeMenuOnSelect={false} isMulti onChange={handleSelect}/>
        </div>
        <div class="col-md-6 mrgBtm4">
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Open page in the application</label>
          <select  class="form-control"  onChange={(e) => setPageapp(e.target.value)} required value={pageapp}>
            <option value="">Select Page</option>
        
            <option value="Message Page">Message Page</option>
            <option value="Presentation page">Presentation page</option>
            <option value="product">Product page</option>
            <option value="forms">For Distributors</option>
            <option value="training">Distributors Training</option>

          </select>
          
        </div>
        
        {(pageapp == 'product')?
        
        <div class="col-md-6 mrgBtm4">
          <label for="defaultFormControlInput" class="form-label">Open subpage</label>
          <select  class="form-control"  onChange={(e) => setSubpage(e.target.value)} value={subpage}>
            <option value="">Select Product</option>
        
            {product.map((position,key) => {
                            return (
<option value={key}  >{position.productName}</option>
                              );
                            })}

          </select>
          
        </div>

        :(pageapp == 'forms')? 
        <div class="col-md-6 mrgBtm4">
        <label for="defaultFormControlInput" class="form-label">Open subpage</label>
        <select  class="form-control"  onChange={(e) => setSubpage(e.target.value)} value={subpage}>
          <option value="">Select Forms</option>
      
          {fileProduct.map((position,key) => {
                            return (
<option value={key}  >{position.title}</option>
                              );
                            })}

        </select>
        
      </div>
        
        :(pageapp == 'training')? 
        <div class="col-md-6 mrgBtm4">
        <label for="defaultFormControlInput" class="form-label">Open subpage</label>
        <select  class="form-control"  onChange={(e) => setSubpage(e.target.value)} value={subpage}>
          <option value="">Select Traning</option>
      
          {fileProduct1.map((position,key) => {
                            return (
<option value={key}  selected={(subpage == key)?true:false}>{position.title}</option>
                              );
                            })}

        </select>
        
      </div>
      :
        ""
        
        }
        
        </div>
        <div class="row mt-3">
          <div class="d-grid gap-2 col-lg-6 mx-auto">
            <button class="btn btn-primary btn-lg waves-effect waves-light" type="submit">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</div>
</form>
    
            </div>
           
            <Footer/>

  
 
</div>
</div>
           
            
</div>



</div>
</div>
  );
}

export { EditMessage };
