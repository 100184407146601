import React, { useEffect, useState } from "react";
import { useNavigate,useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import config from "../config";
import axios from "axios";
import defaltimg from "../images/defalt.jpg"
import { Header } from "./header";
function Aside() {
  const navigate = useNavigate();
  const location = useLocation()

  const [role, setrole] = useState(JSON.parse(localStorage.getItem("roletype")));
  
  const [user, setuser] = useState([]);
  const [permission, setprem] = useState([]);
  const [count, setcount] = useState('');
  const [countuser, setcountuser] = useState('0');
  const [checkuser, setcheckuser] = useState(['/user','/adduser','/edituser']);
  const [checkcontact, setcheckcontact] = useState(['/contact','/editcontact','/addContact']);
  const [checkcontent, setcheckcontent] = useState(['/editcontent','/addcontent','/appcontent']);
  const [checksm, setchecksm] = useState(['/addAdmin','/systemmanager','/addAdmintype']);
  const [checktraning, setchecktraning] = useState(['/traning','/addTraning','/editTraning']);
  const [checkform, setcheckform] = useState(['/forms','/addForm','/editForm']);
  const [checkmessage, setcheckmessage] = useState(['/message','/addMessage']);
  const [checksystemmanager, setchecksystemmanager] = useState(['/systemmanager','/addAdmin','/editadmin','/addAdmintype','/editadmintype']);
  const [checkproduct, setcheckproduct] = useState(['/product','/addproduct','/editproduct','/listProductfile','/addProductfile','/editProductfile']);
  const [notification, setNotification] = useState([]);
  const fetchData = async () => {
    //e.preventDefault()
    
    let getloginuser = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        type: 'type',
        
      }),
    };
    return fetch(config.backend_URL + "/getInboxcount", getloginuser)
    .then((response) => response.json())
    .then((responseJson) => {
     console.log('oreeee',permission);
          if (responseJson.status) {
            /*        localStorage.setItem('Id',data.data.data.ADMIN_ID); */
           
            
           
            console.log(responseJson)
            setcount(responseJson.count)
            
            
            
          
        } else {
          //toast(responseJson.msz);
          
          //setLoading(false)
          
        }
      
    })
    .catch((error) => {
      //toast('It will take some time!');
     
      //console.error(error);
    });
  
      
  };
  const fetchData1 = async () => {
    //e.preventDefault()
    
    let getloginuser = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        type: 'type',
        
      }),
    };
    return fetch(config.backend_URL + "/getpendinguser", getloginuser)
    .then((response) => response.json())
    .then((responseJson) => {
     
          if (responseJson.status) {
            /*        localStorage.setItem('Id',data.data.data.ADMIN_ID); */
           
            
           
            console.log(responseJson)
            setcountuser(responseJson.count)
            
            
            
          
        } else {
          //toast(responseJson.msz);
          
          //setLoading(false)
          
        }
      
    })
    .catch((error) => {
      //toast('It will take some time!');
     
      //console.error(error);
    });
  
      
  };
  useEffect(() => {
    if (localStorage.getItem("status") == 'true') {
      
      setrole(JSON.parse(localStorage.getItem("roletype")));
      setuser(JSON.parse(localStorage.getItem("UserData")));
      setprem(JSON.parse(localStorage.getItem("UserPermission")));
      
      fetchData();
      fetchData1()
    } else {
      //navigate("/login");
    }
  }, []);
  return (
    <>
    <aside id="layout-menu" class="layout-menu menu-vertical menu bg-menu-theme" data-bg-class="bg-menu-theme" >

  
  <div class="app-brand demo ">
    <Link to={config.front_URL +"/dashboard"} class="app-brand-link">
     
     <img src="images/unamed.png" height="60px" />
    </Link>

    
  </div>

  <div class="menu-inner-shadow" ></div>

  
  
  <ul class="menu-inner py-1 ps ps--active-y">
  <li class="menu-header small text-uppercase">
      <span class="menu-header-text"></span>
    </li>
  {(permission.UsersPermission == "Read&write")?
  <li class={(checkuser.some(item => location.pathname === item))? 'active menu-item':'menu-item'}>
  <Link  to={config.front_URL +"/user"} class="menu-link" >
        <i class="menu-icon tf-icons  fa fa-user"></i>
        <div data-i18n="Email">User List</div>
        <div class="badge bg-danger rounded-pill ms-auto">{countuser}</div>
        </Link>
    </li>
   :''}
    {/* <li class="menu-header small text-uppercase">
      <span class="menu-header-text">presentation</span>
    </li> */}
     {(permission.PresentationPermission == "Read&write")?
    <li class={(location.pathname == '/presentation')? 'active menu-item':'menu-item'}>
  <Link  to={config.front_URL +"/presentation"} class="menu-link ">
        <i class="menu-icon tf-icons  fa fa-file"></i>
        <div data-i18n="Email">Presentation</div>
        </Link>
    </li>
    :''}
    {/* <li class="menu-header small text-uppercase">
      <span class="menu-header-text">Product</span>
    </li> */}
      {(permission.ProductsPermission == "Read&write")?
    <li class={(checkproduct.some(item => location.pathname === item))? 'active menu-item':'menu-item'}>
  <Link  to={config.front_URL +"/product"} class="menu-link">
        <i class="menu-icon tf-icons  fa fa-shopping-bag"></i>
        <div data-i18n="Email">Product</div>
        </Link>
    </li>
    :''}
   {/*  <li class="menu-header small text-uppercase">
      <span class="menu-header-text">Traning</span>
    </li> */}
    {(permission.TraningPermission == "Read&write")?
    <li class={(checktraning.some(item => location.pathname === item))? 'active menu-item':'menu-item'}>
  <Link  to={config.front_URL +"/traning"} class="menu-link">
        <i class="menu-icon tf-icons  fa fa-graduation-cap"></i>
        <div data-i18n="Email">Traning</div>
        </Link>
    </li>
    :''}
    {/* <li class="menu-header small text-uppercase">
      <span class="menu-header-text">Forms</span>
    </li> */}
    {(permission.FormsPermission == "Read&write")?
    <li class={(checkform.some(item => location.pathname === item))? 'active menu-item':'menu-item'}>
  <Link  to={config.front_URL +"/forms"} class="menu-link">
        <i class="menu-icon tf-icons  fa fa-pencil-square"></i>
        <div data-i18n="Email">Forms</div>
        </Link>
    </li>
    :''}
    {/* <li class="menu-header small text-uppercase">
      <span class="menu-header-text">Message</span>
    </li> */}
      {(permission.MessagePermission == "Read&write")?
    <li class={(checkmessage.some(item => location.pathname === item))? 'active menu-item':'menu-item'}>
  <Link  to={config.front_URL +"/message"} class="menu-link">
        <i class="menu-icon tf-icons  fa fa-commenting"></i>
        <div data-i18n="Email">Message</div>
        </Link>
    </li>
    :''}
     {(permission.ContactusPermission == "Read&write")?
    <li class={(checkcontact.some(item => location.pathname === item))? 'active menu-item':'menu-item'}>
  <Link  to={config.front_URL +"/contact"} class="menu-link">
        <i class="menu-icon tf-icons  fa fa-message"></i>
        <div data-i18n="Email">Contact</div>
        </Link>
    </li>
    :''}
      {(permission.InboxPermission == "Read&write")?
    <li class={(location.pathname == '/inbox')? 'active menu-item':'menu-item'}>
  <Link  to={config.front_URL +"/inbox"} class="menu-link">
        <i class="menu-icon tf-icons  fa fa-envelope"></i>
        <div data-i18n="Email">Inbox</div>
        <div class="badge bg-danger rounded-pill ms-auto">{count}</div>
        </Link>
    </li>
:''}
    {/* <li class="menu-header small text-uppercase">
      <span class="menu-header-text">General Settings</span>
    </li> */}
    {(permission.AppcontentPermission == "Read&write")?
    <li class={(checkcontent.some(item => location.pathname === item))? 'active menu-item':'menu-item'}>
  <Link  to={config.front_URL +"/appcontent"} class="menu-link">
        <i class="menu-icon tf-icons  fa fa-pencil"></i>
        <div data-i18n="Email">App Content</div>
        </Link>
    </li>
    :''}
      {(permission.SystemmanagerPermission == "Read&write")?
    <li class={(checksystemmanager.some(item => location.pathname === item))? 'active menu-item':'menu-item'}>
  <Link  to={config.front_URL +"/systemmanager"} class="menu-link">
        <i class="menu-icon tf-icons  fa fa-users"></i>
        <div data-i18n="Email">System Manager</div>
        </Link>
    </li>
    :''}
      {(permission.SettingsPermission == "Read&write")?
    <li class={(location.pathname == '/settings')? 'active menu-item':'menu-item'}>
  <Link  to={config.front_URL +"/settings"} class="menu-link">
        <i class="menu-icon tf-icons  fa fa-cog"></i>
        <div data-i18n="Email">Settings</div>
        </Link>
    </li>
    :''}
    


    
    
  
    
    
    
    
    
    
    
    
    
    
    
    

  
    
    
   
    

   
    

    

 
    
    
    
    
    
   
    
  
    
  <div class="ps__rail-x" ><div class="ps__thumb-x" tabindex="0" ></div></div><div class="ps__rail-y" ><div class="ps__thumb-y" tabindex="0" ></div></div></ul>
  
  

</aside>

      
</>   






   
  );
}

export { Aside };
