import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import config from "../config";
import axios from "axios";
import defaltimg from "../images/defalt.jpg"
function Header() {
  const navigate = useNavigate();
  const [role, setrole] = useState(JSON.parse(localStorage.getItem("roletype")));
  
  const [user, setuser] = useState([]);
  const [notification, setNotification] = useState([]);




  const logout = () => {
    //alert('ggg')
    localStorage.clear(); //for localStorage 
    navigate("/");
  // window.location.reload()
  };
  

  useEffect(() => {
    console.log('check data',localStorage.getItem("UserData"))
    if (JSON.parse(localStorage.getItem("status")) == true) {
      setrole(JSON.parse(localStorage.getItem("roletype")));
      setuser(JSON.parse(localStorage.getItem("UserData")));
      
      //fetchData();
    } else {
      navigate("/login");
    }
  }, []);
  return (
    <nav class="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme" id="layout-navbar">
  

  

  

      
      

      
      
      {/* <div class="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0   d-xl-none ">
        <a class="nav-item nav-link px-0 me-xl-4" href="javascript:void(0)">
          <i class="ti ti-menu-2 ti-sm"></i>
        </a>
      </div> */}
      

      <div class="navbar-nav-right d-flex align-items-center" id="navbar-collapse">

        
      
        <div class="navbar-nav align-items-center">
          <div class="nav-item navbar-search-wrapper mb-0">
            <a class="nav-item nav-link search-toggler d-flex align-items-center px-0" href="javascript:void(0);">
              <i class="fa fa-search ti-md me-2"></i>
              <span class="d-none d-md-inline-block text-muted">Search (Ctrl+/)</span>
            </a>
          </div>
        </div>
       
        
        

        

        <ul class="navbar-nav flex-row align-items-center ms-auto">
          
         
          
          
          
         
          
          
          
          
          {/* <li class="nav-item dropdown-notifications navbar-dropdown dropdown me-3 me-xl-1">
            <Link class="nav-link dropdown-toggle hide-arrow" to={config.front_URL +"/inbox"} >
              <i class="fa fa-bell ti-md"></i>
              <span class="badge bg-danger rounded-pill badge-notifications">5</span>
            </Link>
            
          </li> */}
         
          <li class="nav-item navbar-dropdown dropdown-user dropdown">
            <a class="nav-link dropdown-toggle hide-arrow" href="javascript:void(0);" data-bs-toggle="dropdown">
              <div class="avatar avatar-online">
                <img src="https://demos.pixinvent.com/vuexy-html-admin-template/assets/img/avatars/1.png" alt class="h-auto rounded-circle"/>
              </div>
            </a>
            <ul class="dropdown-menu dropdown-menu-end">
              <li>
                <a class="dropdown-item" href="javascript:void(0)">
                  <div class="d-flex">
                    <div class="flex-shrink-0 me-3">
                      <div class="avatar avatar-online">
                        <img src="https://demos.pixinvent.com/vuexy-html-admin-template/assets/img/avatars/1.png" alt class="h-auto rounded-circle"/>
                      </div>
                    </div>
                    <div class="flex-grow-1">
                      <span class="fw-medium d-block">{user.firstName} {user.lastName}</span>
                      <small class="text-muted">{user.type}</small>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <div class="dropdown-divider"></div>
              </li>
              <li>
                <Link class="dropdown-item" to={{ pathname: "/userprofile", search:"?id="+user._id}} state={{type:user.type,status:user.status,firstName:user.firstName,lastName:user.lastName,phone:user.phone,email:user.email}}>
                  <i class="fa fa-user me-2 ti-sm"></i>
                  <span class="align-middle">My Profile</span>
                </Link>
              </li>
              
             
              
              
              
              <li>
                <div class="dropdown-divider"></div>
              </li>
              <li>
                <Link class="dropdown-item" to="javascript:void(0)" onClick={() => logout()} >
                  <i class="fa fa-lock me-2 ti-sm"></i>
                  <span class="align-middle">Log Out</span>
                </Link>
              </li>
            </ul>
          </li>
          
          


        </ul>
      </div>

      
      
      <div class="navbar-search-wrapper search-input-wrapper  d-none">
        <input type="text" class="form-control search-input container-xxl border-0" placeholder="Search..." aria-label="Search..."/>
        <i class="ti ti-x ti-sm search-toggler cursor-pointer"></i>
      </div>
</nav>
  );
}

export { Header };
