import React, { useEffect,useState } from "react";
import config from "../config";
import axios from "axios";
import { useNavigate,Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader"; 
import "./login.css";
import { Header } from "./header";
import { Aside } from "./aside";
import { Footer } from "./footer";
import CKEditor from "react-ckeditor-component";
import moment from 'moment-timezone';
import { ProgressBar } from "react-bootstrap"
function Presentation() {
  const navigate = useNavigate();
  let   [loadingInProgress, setLoading] = useState(false);
  const [text, setText] = useState("");
  const [text1, setText1] = useState("");
  const [text2, setText2] = useState("");
  const [text3, setText3] = useState("");
  const [title1, setTitle1] = useState("");
  const [title2, setTitle2] = useState("");
  const [title3, setTitle3] = useState("");
  const [title4, setTitle4] = useState("");
  const [lastupdate, setLastupdate] = useState("");
  const [lastPresentationfile, setLastPresentationfile] = useState("");
  const [lastDownloadfile, setLastDownloadfile] = useState("");
  const [lastDownloadfile1, setLastDownloadfile1] = useState("");
  const [description, setDescription] = useState("");
  const [appleAppStoreLink, setAppleAppStoreLink] = useState("");
  const [googleAppStoreLink, setGoogleAppStoreLink] = useState("");
  const [homeBackground, sethomeBackground] = useState("");
  const [presentationFile, setPresentationFile] = useState("");
  const [homeBackground1, setHomeBackground1] = useState("");
  const [psize, setpsize] = useState("");
  const [dsize, setdsize] = useState("");
  const [Presentation, setPresentation] = useState([]);
  const [Presentation1, setPresentation1] = useState([]);
  const [Presentation2, setPresentation2] = useState([]);
  const [Presentation3, setPresentation3] = useState([]);
  const [progress, setProgress] = useState()
  const onFileChange = async (e,data) => {
    e.preventDefault();
    console.log('awerqaewr',data);
    if(data == 'sethomeBackground'){

      sethomeBackground(e.target.files[0]);
    }
    if(data == 'setPresentationFile'){

      setPresentationFile(e.target.files[0]);
    }
    if(data == 'setHomeBackground1'){

      setHomeBackground1(e.target.files[0]);
    }
    
  };
 
  let handleSubmit =  (e) => {
  e.preventDefault();
  setLoading(true)
  let formData = new FormData(); //formdata object
  formData.append("IMAGE", homeBackground);
  formData.append("text", text);

    axios.post(`${config.backend_URL}/addPresentationHome`, formData)
      .then( (data) => {
       
        setLoading(false)
        toast(data.data.msz);
      }).catch((err) => {
        console.log("error", err);
      });
};
let handleSubmit1 =  (e) => {
  e.preventDefault();
  setLoading(true)
  let formData = new FormData(); //formdata object
  formData.append("presentationFile", presentationFile);
  formData.append("backgroundImage", homeBackground1);
  formData.append("text", text1);
  formData.append("title", title1);
  formData.append("purl", lastPresentationfile);
  formData.append("durl", lastDownloadfile);
  formData.append("psize", parseInt(psize));
  formData.append("dsize", parseInt(dsize));
//console.log(formData)
axios.post(`${config.backend_URL}/addPresentationPage`, formData, {
  
  onUploadProgress: data => {
    //Set the progress value to show the progress bar
    console.log(data)
    var tt = Math.round((100 * data.loaded) / data.total)
    if(tt == '100'){

      setLoading(false)
    }
    setProgress(tt)
  },
})
   /*  axios.post(`${config.backend_URL}/addPresentationPage`, formData)
      .then( (data) => {
        setLoading(false)
        toast(data.data.msz);
      }).catch((err) => {
        console.log("error", err);
      }); */
};

const handleSubmit2 = (e) => {
  e.preventDefault()
  setLoading(true)
  let getloginuser = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      text: text2,
      title: title2,
     
      
    }),
  };
  return setLoading(true) ,fetch(config.backend_URL + "/addPresentationFilesize", getloginuser)
  .then((response) => response.json())
  .then((responseJson) => {
    setLoading(false)
        if (responseJson.status) {
          
          toast(responseJson.msz);
      } else {
        toast(responseJson.msz);
       
      }
    
  })
  .catch((error) => {
    toast('It will take some time!');
    setLoading(false)
    //console.error(error);
  });

    setLoading(false)
};
const handleSubmit3 = (e) => {
  e.preventDefault()
  setLoading(true)
  let getloginuser = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      description: description,
      title: title3,
      appleAppStoreLink: appleAppStoreLink,
      googleAppStoreLink: googleAppStoreLink,
      
    }),
  };
  return setLoading(true) ,fetch(config.backend_URL + "/addPresentationView", getloginuser)
  .then((response) => response.json())
  .then((responseJson) => {
    setLoading(false)
        if (responseJson.status) {
          
          toast(responseJson.msz);
      } else {
        toast(responseJson.msz);
       
      }
    
  })
  .catch((error) => {
    toast('It will take some time!');
    setLoading(false)
    //console.error(error);
  });

    setLoading(false)
};
const handleSubmit4 = (e) => {
  e.preventDefault()
  setLoading(true)
  let getloginuser = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      text: text3,
      title: title4,
     
      
    }),
  };
  return setLoading(true) ,fetch(config.backend_URL + "/addPresentationservice", getloginuser)
  .then((response) => response.json())
  .then((responseJson) => {
    setLoading(false)
        if (responseJson.status) {
          
          toast(responseJson.msz);
      } else {
        toast(responseJson.msz);
       
      }
    
  })
  .catch((error) => {
    toast('It will take some time!');
    setLoading(false)
    //console.error(error);
  });

    setLoading(false)
};
const fetchData = async () => {
  //e.preventDefault()
  setLoading(true)
  let getloginuser = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      type: 'type',
      
    }),
  };
  return setLoading(true) ,fetch(config.backend_URL + "/getPresentation", getloginuser)
  .then((response) => response.json())
  .then((responseJson) => {
    setLoading(false)
        if (responseJson.status) {
          /*        localStorage.setItem('Id',data.data.data.ADMIN_ID); */
         
          
         
          console.log(responseJson)
          setText(responseJson.homePage[0].text)
          setTitle1(responseJson.presentationPage[0].title)
          setText1(responseJson.presentationPage[0].text)
          setpsize(responseJson.presentationPage[0].presentationFileSize)
          setdsize(responseJson.presentationPage[0].backgroundImageSize)
          setTitle2(responseJson.fileSize[0].title)
          setText2(responseJson.fileSize[0].text)
          setText3(responseJson.presentationService[0].text)
          setTitle3(responseJson.presentationView[0].title)
          setTitle4(responseJson.presentationService[0].title)
          setDescription(responseJson.presentationView[0].description)
          setAppleAppStoreLink(responseJson.presentationView[0].appleAppStoreLink)
          setGoogleAppStoreLink(responseJson.presentationView[0].googleAppStoreLink)
          setLastupdate(responseJson.presentationPage[0].dateTime)
          setLastPresentationfile(responseJson.presentationPage[0].presentationFile)
          setLastDownloadfile(responseJson.presentationPage[0].backgroundImage)
          setLastDownloadfile1(responseJson.homePage[0].backgroundImage)
          //toast(responseJson.msz);
          
          
        
      } else {
        toast(responseJson.msz);
        
        //setLoading(false)
        
      }
    
  })
  .catch((error) => {
    toast('It will take some time!');
    setLoading(false)
    //console.error(error);
  });

    setLoading(false)
};
useEffect(() => {
  //setLoading(true) ;
  
  fetchData();
}, []);
  return (
    <div className="container-scroller">
      {loadingInProgress ? <div className="parentdiv"><div className="loaderclsdiv"><ClipLoader className="loadercls" text-align="center" color={'#000'} loading={loadingInProgress}  size={35} /></div></div>: ""}
      {" "}
      <ToastContainer />
    
      <div class="layout-wrapper layout-content-navbar">
      <div class="layout-container">
    
<Aside/>
<div class="layout-page">


<Header/>
<div class="content-wrapper">


<div class="container-xxl flex-grow-1 container-p-y">
  
  

<h4 class="py-3 mb-4">
<span class="text-muted fw-light">Presentation</span> 
</h4>



<div class="row mrgBtm4">
<div class="col-md-6">
<form onSubmit={(e) => handleSubmit(e)}>
    <div class="card mb-4">
      <h5 class="card-header">Home Page Content</h5>
      <div class="card-body">
        <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label">Text</label>
          <input type="text" required class="form-control" placeholder="Text" onChange={(e) => setText(e.target.value)} value={text}/>
          
        </div>
        <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Background image</label>
          <div class="mb-6 pt-4">
          <input type="file"  required onChange={(e) => onFileChange(e,'sethomeBackground')}/>
          
      <div class="formbold-mb-5 formbold-file-input">
        
        
        <label >
          <div>
          <span class="formbold-drop-file" ><i class="fa fa-cloud-download"></i></span>
          <span class="formbold-drop-file" ><img src={lastDownloadfile1} width="100px"/></span>
            {/* <span class="formbold-or">Download File </span> */}
           
          </div>
        </label>
      </div>

      </div>
          
        </div>
        <div class="row mt-3 mrgBtm4">
          <div class="d-grid gap-2 col-lg-6 mx-auto">
            <button class="btn btn-primary btn-lg waves-effect waves-light" type="submit">Save</button>
          </div>
        </div>
      </div>
    </div>
</form>
  </div>
  <div class="col-md-6">
    <form onSubmit={(e) => handleSubmit1(e)}>
    <div class="card mb-4">
      <h5 class="card-header">Presentation page {progress && <ProgressBar now={progress} label={`${progress}%`} />}</h5>
      <div class="card-body">
        <div class="row mrgBtm4">
<div class="col-md-6"><div>
          <label for="defaultFormControlInput" class="form-label">Title</label>
          <input type="text" required class="form-control" placeholder="Title"  onChange={(e) => setTitle1(e.target.value)} value={title1}/>
          
        </div></div>
<div class="col-md-6"> <div>
          <label for="defaultFormControlInput" class="form-label">Text</label>
          <input type="text" required class="form-control" placeholder="Text" onChange={(e) => setText1(e.target.value)} value={text1}/>
          
        </div></div>
        </div>
        
        <div class="row">

            <div class="col-md-6"><div>
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Presentation file</label>
          <div class="mb-6 pt-4">
      

        <input type="file"   onChange={(e) => onFileChange(e,'setPresentationFile')}/>
        <a href={lastPresentationfile} download>
      <div class="formbold-mb-5 formbold-file-input">
        <label >
          <div>
          <span class="formbold-drop-file" ><i class="fa fa-cloud-download"></i></span>
          
            {/* <span class="formbold-or">DownLoad File</span> */}
           
          </div>
        </label>
      </div>
      </a>
      </div>
          
        </div></div>
            <div class="col-md-6">
              <div>
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Background image</label>
          <div class="mb-6 pt-4">
      

        <input type="file"  onChange={(e) => onFileChange(e,'setHomeBackground1')} />
        
      <div class="formbold-mb-5 formbold-file-input">
        <label >
          <div>
          <span class="formbold-drop-file" ><i class="fa fa-cloud-download"></i></span>
          <span class="formbold-drop-file" ><img src={lastDownloadfile} width="100px"/></span>
          {/* <span class="formbold-or">DownLoad File </span> */}
           
          </div>
        </label>
      </div>
        
      </div>
          
        </div></div>
        </div>
        <div class="col-md-6"><div>
          <label for="defaultFormControlInput" class="form-label">Last Update</label>
         
      

        <input type="text" id="file" readOnly value={moment(lastupdate).tz("Israel").format("DD/MM/YYYY h:m")} class="form-control" />
      
     
          
        </div></div>
        <div class="row mt-3">
          <div class="d-grid gap-2 col-lg-6 mx-auto">
            <button class="btn btn-primary btn-lg waves-effect waves-light" type="submit">Save</button>
          </div>
        </div>


      </div>
    </div>
    </form>
  </div>
</div>

<div class="row">
<div class="col-md-6">
  <form onSubmit={(e) => handleSubmit2(e)}>
    <div class="card mb-4">
      <h5 class="card-header">File size content</h5>
      <div class="card-body">
        <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label">Title</label>
          <input type="text" required class="form-control" placeholder="Title" onChange={(e) => setTitle2(e.target.value)} value={title2}/>
          
        </div>
        <div>
          <label for="exampleFormControlTextarea1" class="form-label">Text</label>
          <textarea required class="form-control" id="exampleFormControlTextarea1" rows="3" onChange={(e) => setText2(e.target.value)} value={text2}></textarea>
        </div>
        <div class="row mt-3">
          <div class="d-grid gap-2 col-lg-6 mx-auto">
            <button class="btn btn-primary btn-lg waves-effect waves-light" type="submit">Save</button>
          </div>
        </div>
      </div>
    </div>
    </form> 
  </div>
  <div class="col-md-6">
  <form onSubmit={(e) => handleSubmit4(e)}>
    <div class="card mb-4">
      <h5 class="card-header">Service content</h5>
      <div class="card-body">
        <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label">Title</label>
          <input type="text" required class="form-control" placeholder="Title" onChange={(e) => setTitle4(e.target.value)} value={title4}/>
          
        </div>
        <div>
          <label for="exampleFormControlTextarea1" class="form-label">Text</label>
          <textarea required class="form-control" id="exampleFormControlTextarea1" rows="3" onChange={(e) => setText3(e.target.value)} value={text3}></textarea>
        </div>
        <div class="row mt-3">
          <div class="d-grid gap-2 col-lg-6 mx-auto">
            <button class="btn btn-primary btn-lg waves-effect waves-light" type="submit">Save</button>
          </div>
        </div>
      </div>
    </div>
    </form> 
  </div>
</div>
<div class="col-md-12">
    <form onSubmit={(e) => handleSubmit3(e)}>
    <div class="card mb-4">
      <h5 class="card-header">View the presentation content</h5>
      <div class="card-body">
       
<div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label">Title</label>
          <input type="text" required class="form-control" placeholder="Title" onChange={(e) => setTitle3(e.target.value)} value={title3}/>
          
        </div>

<div class="mrgBtm4">
          <label for="exampleFormControlTextarea1" class="form-label">Description</label>
          {/* <textarea class="form-control" required id="exampleFormControlTextarea1" rows="3" onChange={(e) => setDescription(e.target.value)} value={description}></textarea> */}
        
          <CKEditor activeClass="editor" content={description} 
       
       events={{
              
               "change": (e) => setDescription(e.editor.getData())
             }} />
             
        </div>
        
        
        
        <div class="row">

            <div class="col-md-6"><div>
            <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Link for Apple App Store
</label>
          <input type="text" required class="form-control" placeholder="Link for Apple App Store" onChange={(e) => setAppleAppStoreLink(e.target.value)} value={appleAppStoreLink}/>
          
          
        </div>
        </div>
            <div class="col-md-6">
                <div>
         
            <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Link for Google Play Store</label>
          <input type="text" required class="form-control" placeholder="Link for Google Play Store" onChange={(e) => setGoogleAppStoreLink(e.target.value)} value={googleAppStoreLink}/>
          
        </div>
        </div>
        </div>
        
    
        <div class="row mt-3">
          <div class="d-grid gap-2 col-lg-6 mx-auto">
            <button class="btn btn-primary btn-lg waves-effect waves-light" type="submit">Save</button>
          </div>
        </div>

      </div>
    </div>
    </form>
  </div>
   
    
            </div>
           
            <Footer/>

  
 
</div>
</div>
           
            
</div>



</div>
</div>
  );
}

export { Presentation };
