import React, { useEffect,useState } from "react";
import config from "../config";
import axios from "axios";
import { useNavigate,Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader"; 
import "./login.css";
import { Header } from "./header";
import { Aside } from "./aside";
import { Footer } from "./footer";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import {Accordion, AccordionBody, AccordionHeader, AccordionItem} from "react-headless-accordion";
import 'react-tabs/style/react-tabs.css';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
function Traning() {
  const navigate = useNavigate();
  let   [loadingInProgress, setLoading] = useState(false);
  

 
  const [fileProduct, setfileProduct] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  
  const handleDragEnd2 = (result) => {
    console.log(result)
  
   const file1Product = Array.from(fileProduct);
   
var str = result.destination.droppableId.split('-');

var tt =str[1];
var ttt =str[2];
      const [movedRow] = file1Product[tt].subArray[ttt].finalArray.splice(result.source.index, 1);
      file1Product[tt].subArray[ttt].finalArray.splice(result.destination.index, 0, movedRow);

    setfileProduct(file1Product);

    let getloginuser = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
      
        id: file1Product[tt].id,
        ids: file1Product[tt].subArray[ttt].id,
        mainarr: file1Product[tt].subArray[ttt].finalArray,
        
      }),
    };
    return setLoading(true) ,fetch(config.backend_URL + "/updatetraningfinalfile", getloginuser)
    .then((response) => response.json())
    .then((responseJson) => {
      setLoading(false)
          if (responseJson.status) {
            toast(responseJson.msz);
            fetchData1();
        } else {
          toast(responseJson.msz);
        }
    })
    .catch((error) => {
      toast('It will take some time!');
      setLoading(false)
      //console.error(error);
    });
  }
  const handleDragEnd1 = (result) => {
    
    const file1Product = Array.from(fileProduct);
    //console.log(file1Product)
   
var str = result.destination.droppableId.split('-');

var tt =str[1];
      const [movedRow] = file1Product[tt].subArray.splice(result.source.index, 1);
      file1Product[tt].subArray.splice(result.destination.index, 0, movedRow);

    setfileProduct(file1Product);
    
    let getloginuser = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
      
        id: file1Product[tt].id,
        mainarr: file1Product[tt].subArray,
        
      }),
    };
    return setLoading(true) ,fetch(config.backend_URL + "/updatetraningsubfile", getloginuser)
    .then((response) => response.json())
    .then((responseJson) => {
      setLoading(false)
          if (responseJson.status) {
            toast(responseJson.msz);
            fetchData1();
        } else {
          toast(responseJson.msz);
        }
    })
    .catch((error) => {
      toast('It will take some time!');
      setLoading(false)
      //console.error(error);
    });
  };
  const handleDragEnd = (result) => {
    console.log(result)
    if (!result.destination) return;

    const newRows = Array.from(fileProduct);
    const [movedRow] = newRows.splice(result.source.index, 1);
    newRows.splice(result.destination.index, 0, movedRow);

    setfileProduct(newRows);

    let getloginuser = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        
        mainarr: newRows,
        
      }),
    };
    return setLoading(true) ,fetch(config.backend_URL + "/updatetraningfile", getloginuser)
    .then((response) => response.json())
    .then((responseJson) => {
      setLoading(false)
          if (responseJson.status) {
            toast(responseJson.msz);
            fetchData1();
        } else {
          toast(responseJson.msz);
        }
    })
    .catch((error) => {
      toast('It will take some time!');
      setLoading(false)
      
    });
  };
  const fetchData1 = () => {
    //e.preventDefault()
   // alert('saddas')
    setLoading(true)
    let getloginuser = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: 'hh',
        
      }),
    };
    return setLoading(true) ,fetch(config.backend_URL + "/getTraning", getloginuser)
    .then((response) => response.json())
    .then((responseJson) => {
      setLoading(false)
      console.log(responseJson);
          if (responseJson.status) {
            setfileProduct(responseJson.data)
        } else {
           toast(responseJson.msz);
         }
      
    })
    .catch((error) => {
      toast('It will take some time!');
      setLoading(false)
      console.error(error);
    });
  };
  const fetchData2 = () => {
    //e.preventDefault()
   // alert('saddas')
    setLoading(true)
    let getloginuser = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        page: 'traning',
        
      }),
    };
    return setLoading(true) ,fetch(config.backend_URL + "/getPageContentTraningForms", getloginuser)
    .then((response) => response.json())
    .then((responseJson) => {
      setLoading(false)
      console.log(responseJson);
          if (responseJson.status) {
            setTitle(responseJson.data.title)
            setDescription(responseJson.data.description)
        } else {
           toast(responseJson.msz);
         }
      
    })
    .catch((error) => {
      toast('It will take some time!');
      setLoading(false)
      console.error(error);
    });
  };
   
  let handleSubmit =  (e) => {
    e.preventDefault()
   setLoading(true) ;
   // navigate("/user");
    //alert(fulltext);
    
    let getloginuser = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        title: title,
        description: description,
        page: 'traning',
       
        
        
      }),
    };
    fetch(config.backend_URL + "/updateMisdata", getloginuser)
    .then((response) => response.json())
    .then((responseJson) => {
      console.log(responseJson);
      
         
       
        

          if (responseJson.status) {
            /*        localStorage.setItem('Id',data.data.data.ADMIN_ID); */
            setLoading(false)
          e.target.reset();
            
            
            toast(responseJson.msz);
            
            
          
        } else {
          toast(responseJson.msz);
          e.target.reset();
          setLoading(false)
          
        }
      
    })
    .catch((error) => {
      toast('It will take some time!');
      setLoading(false)
      console.error(error);
    });


    
  };
  const deleteData = async (e,url,id,ids,ids1,index) => {
    e.preventDefault();
   //alert(ids)
  
   let getloginuser = {
     method: "POST",
     headers: {
       Accept: "application/json",
       "Content-Type": "application/json",
     },
     body: JSON.stringify({
       id: id,
       ids: ids,
       ids1: ids1,
       index: index,
       
     }),
   };
   return setLoading(true) ,fetch(config.backend_URL + url, getloginuser)
   .then((response) => response.json())
   .then((responseJson) => {
     setLoading(false)
     //console.log(responseJson);
         if (responseJson.status) {
           fetchData1();
           toast(responseJson.msz);
       } else {
         toast(responseJson.msz);
         
         //setLoading(false)
         
       }
     
   })
   .catch((error) => {
     console.error(error);
     toast('It will take some time!');
     setLoading(false)
   });
  };

  useEffect(() => {
    //setLoading(true) ;
    fetchData2();
    fetchData1();
  }, []);
  return (
    <div className="container-scroller">
      {loadingInProgress ? <div className="parentdiv"><div className="loaderclsdiv"><ClipLoader className="loadercls" text-align="center" color={'#000'} loading={loadingInProgress}  size={35} /></div></div>: ""}
      {" "}
      <ToastContainer />
    
      <div class="layout-wrapper layout-content-navbar">
      <div class="layout-container">
    
<Aside/>
<div class="layout-page">


<Header/>
      
      <div class="content-wrapper">


  <div class="container-xxl flex-grow-1 container-p-y">
    
    


<div class="row">
<div class="col-md-6">
<h4 class="py-3 mb-4">
<span class="text-muted fw-light">Traning</span> 
</h4>

</div>
<div class="col-md-6" >

<Link class="dt-button add-new btn btn-primary ms-2 ms-sm-0" tabindex="0" aria-controls="DataTables_Table_0" to={config.front_URL +"/addTraning?folder=category"}><span><span class="d-none d-sm-inline-block">Add</span></span></Link>
</div>
</div>

<div class="card">

  <div class="card-datatable table-responsive">
    <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap5 no-footer">
   
                                <Tabs>
    <TabList>
      <Tab>Traning List</Tab>
      <Tab>Home Page Content</Tab>
    </TabList>

    <TabPanel>
   
    <div class="table-responsive m-t-40">
    {fileProduct.length != 0?
    <DragDropContext onDragEnd={handleDragEnd}>
    <Droppable droppableId="table">
    {(provided) => (
  <table  cellspacing="0" width="100%" {...provided.droppableProps} ref={provided.innerRef}>
<Accordion>

{fileProduct.sort((a,b) => a.sortKey - b.sortKey).map((contentdata,indexxxx) => {
                            return (
                              <div class="mainArr">
            <AccordionItem>
            <Draggable key={contentdata.id} draggableId={contentdata.id} index={indexxxx}>
            {(provided) => (
            <tr class="acctr" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                               
                                               <td>
                                                {(contentdata.subArray.length != 0)?
                                                
                                                <AccordionHeader className="bdRm"> <button class="btn btn-secondary " ><i class="fa fa-chevron-down"></i></button>&nbsp;</AccordionHeader>
                                                :
                                                ''
                                                }
                                                        <Link class="btn btn-info" to={{ pathname: "/editTraning", search:"?id="+contentdata._id+"&folder=category&parentFloder="+contentdata.title}} state={{type:contentdata.type,description:contentdata.description,country: contentdata.country,title:contentdata.title,file:contentdata.file,fileSize:contentdata.fileSize}} ><i class="fa fa-pencil" aria-hidden="true"></i></Link>&nbsp;
                                                       <Link class="btn btn-primary" to={config.front_URL +"/addTraning?id="+contentdata._id+"&folder=subcat&parentFloder="+contentdata.title}><i class="fa fa-plus" aria-hidden="true"></i></Link>&nbsp;
                                                       <a class="btn btn-primary" style={{color:'#fff'}} ><i class="fa fa-bars" aria-hidden="true"></i></a>&nbsp;
                                                       </td>
                                                       
                                                   
                                                   <td>{contentdata.title}</td>
                                                   <td> <button class="btn btn-danger" type="button" onClick={(e) => deleteData(e,'/deleteTraningmain',contentdata._id,contentdata.id,'f','0')}><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;</td>
                                               </tr>
                 )}
                 </Draggable>

                <AccordionBody >
                <DragDropContext onDragEnd={handleDragEnd1}>
 <Droppable droppableId={`subarr-${indexxxx}`} >
 {(provided) => (
                                                <div class="" {...provided.droppableProps} ref={provided.innerRef}> 
                {contentdata.subArray.sort((a,b) => a.sortKey - b.sortKey).map((contentdata1,index) => {
                   var itmtt= index 
                                              return (
                                                <div class="subArr">
                          <AccordionItem>
                          <Draggable key={contentdata1.id} draggableId={contentdata1.id} index={itmtt}>
            {(provided) => (
                                                <tr  class="acctr" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                
                                                <td>
                                                {(contentdata1.finalArray.length != 0)?
                                                
                                                <AccordionHeader className="bdRm"> <button class="btn btn-secondary " ><i class="fa fa-chevron-down"></i></button>&nbsp;</AccordionHeader>
                                                :
                                                ''
                                                }
                                                        <Link class="btn btn-info" to={{ pathname: "/editTraning", search:"?id="+contentdata._id+"&folder=subcat&ids="+contentdata1.id+"&parentFloder="+contentdata.title}} state={{type:contentdata.type,description:contentdata1.description,country: contentdata1.country,title:contentdata1.title,file:contentdata1.file,fileSize:contentdata1.fileSize}} ><i class="fa fa-pencil" aria-hidden="true"></i></Link>&nbsp;
                                                        <Link class="btn btn-primary" to={config.front_URL +"/addTraning?id="+contentdata._id+"&folder=finalcat&ids="+contentdata1.id+"&index="+ index++ +"&parentFloder="+contentdata1.title}><i class="fa fa-plus" aria-hidden="true"></i></Link>&nbsp;
                                                        <a class="btn btn-primary" style={{color:'#fff'}} ><i class="fa fa-bars" aria-hidden="true"></i></a>&nbsp;
                                                        </td>
                                                        
                                                    
                                                    <td>{contentdata1.title}</td>
                                                    <td> <button class="btn btn-danger" onClick={(e) => deleteData(e,'/deleteTraningsub',contentdata._id,contentdata1.id,contentdata1.id,'0')}><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;</td>
                                                </tr>
                                                 )}
                                                 </Draggable>
                            <AccordionBody >
                            <DragDropContext onDragEnd={handleDragEnd2}>
 <Droppable droppableId={`subarr-${indexxxx}-${itmtt}`} >
 {(provided) => (
                                                <div class="" {...provided.droppableProps} ref={provided.innerRef}> 
                            {contentdata1.finalArray.sort((a,b) => a.sortKey - b.sortKey).map((contentdata2,index1) => {
                               var itm33= index1
                                                                return (
                                                                  <div class="finalArr">
                                                                   <Draggable key={contentdata2.id} draggableId={contentdata2.id} index={itm33}>
            {(provided) => ( 
                                                                  <tr class="acctr" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                 
                                                                 
                                                                  <td>
                                                                  <Link class="btn btn-info" to={{ pathname: "/editTraning", search:"?id="+contentdata._id+"&folder=finalcat&ids="+contentdata2.id+"&index="+ index1++ +"&parentFloder="+contentdata1.title}} state={{type:contentdata.type,description:contentdata2.description,country: contentdata2.country,title:contentdata2.title,file:contentdata2.file,fileSize:contentdata2.fileSize}} ><i class="fa fa-pencil" aria-hidden="true"></i></Link>&nbsp;
                                                                  <a class="btn btn-primary" style={{color:'#fff'}} ><i class="fa fa-bars" aria-hidden="true"></i></a>&nbsp;
                                                                          </td>
                                                                          
                                                                     
                                                                      <td>{contentdata2.title}</td>
                                                                      <td> <button class="btn btn-danger" onClick={(e) => deleteData(e,'/deleteTraningfinal',contentdata._id,contentdata1.id,contentdata2.id,'0')}><i class="fa fa-trash" aria-hidden="true"></i></button>&nbsp;</td>
                                                                  </tr>
                                                                   )}
                                                                   </Draggable>
                                                                <span ></span>
                                                                  </div>           
                                                                                   );         
                                                                                  })} 
                                                                                  </div>
            )}
            </Droppable>
</DragDropContext>
                            </AccordionBody>
                          </AccordionItem>
                      
                          </div>  
            );         
           })} 
           </div>
            )}
            </Droppable>
</DragDropContext>
                </AccordionBody>
            </AccordionItem>
            
            </div>
                                            );
                                          })}
        </Accordion>
                                          </table>
                                          )}
                                          </Droppable>
    </DragDropContext>
:

<span>No Data Found</span>


}
                                </div>
    </TabPanel>
    <TabPanel>
    <form onSubmit={(e) => handleSubmit(e)}>
<div class="row">
<div class="col-md-12">
    <div class="card mb-4">
      {/* <h5 class="card-header">Add Content</h5> */}
      <div class="card-body">
      
        
        
        
        
        <div>
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Title</label>
          <input type="text" required class="form-control" placeholder="Enter Title" onChange={(e) => setTitle(e.target.value)} value={title}/>
          
        </div>
        <div>
          <label for="exampleFormControlTextarea1" class="form-label">Description</label>
          <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" onChange={(e) => setDescription(e.target.value)} value={description}>{description}</textarea>
        </div>
        
        

        <div class="row mt-3">
          <div class="d-grid gap-2 col-lg-6 mx-auto">
            <button class="btn btn-primary btn-lg waves-effect waves-light" type="submit">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</div>
</form>
    </TabPanel>
  </Tabs>
  
    </div>
  </div>
</div>


  </div>
 

  
  



<Footer/>

  
 
</div>
</div>
           
            
</div>



</div>
</div>
    
  );
}

export { Traning };
