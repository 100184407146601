import React, { useEffect,useState } from "react";
import config from "../config";
import axios from "axios";
import { useNavigate,Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader"; 
import "./login.css";
import { Header } from "./header";
import { Aside } from "./aside";
import { Footer } from "./footer";
import CKEditor from "react-ckeditor-component";
import {
    CitySelect,
    CountrySelect,
    StateSelect,
    LanguageSelect,
  } from "react-country-state-city";
  import "react-country-state-city/dist/react-country-state-city.css";
function Addform() {
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  const par = queryParams.get("folder");
  const ids = queryParams.get("ids");
  const index = queryParams.get("index");
  const parentTitle = queryParams.get("parentFloder");
  const navigate = useNavigate();
  let   [loadingInProgress, setLoading] = useState(false);
  const [url, setUrl] = useState("");
  const [folder, setFolder] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [countryid, setCountryid] = useState(0);
  const [productImage, setProductimage] = useState("");
  const [country, setCountry] = useState(0);
  const [type, setType] = useState('');
  
  let handleSubmit =  (e) => {
    e.preventDefault();
    setLoading(true)
    
  
    if(par == "category"){
        let formData = new FormData(); //formdata object
        formData.append("file", productImage);
        formData.append("title", title);
        formData.append("url", url);
        formData.append("country", country);
        formData.append("type", type);
        formData.append("description", description);
        axios.post(`${config.backend_URL}/addForm`, formData)
        .then( (data) => {
         
          setLoading(false)
          toast(data.data.msz);
          e.target.reset();
        }).catch((err) => {
          console.log("error", err);
        });
    }else if(par == "subcat"){
        let formData = new FormData(); //formdata object
        formData.append("file", productImage);
        formData.append("title", title);
        formData.append("url", url);
        formData.append("country", country);
        formData.append("id", id);
        formData.append("type", type);
        formData.append("description", description);
        axios.post(`${config.backend_URL}/addFormsub`, formData)
        .then( (data) => {
         
          setLoading(false)
          toast(data.data.msz);
          e.target.reset();
        }).catch((err) => {
          console.log("error", err);
        });
    }else if(par == "finalcat"){
        //alert(par);
        let formData = new FormData(); //formdata object
        formData.append("file", productImage);
        formData.append("title", title);
        formData.append("url", url);
        formData.append("country", country);
        formData.append("id", id);
        formData.append("ids", ids);
        formData.append("index", index);
        formData.append("description", description);
        formData.append("type", type);
        axios.post(`${config.backend_URL}/addFormfinal`, formData)
        .then( (data) => {
         
          setLoading(false)
          toast(data.data.msz);
          e.target.reset();
        }).catch((err) => {
          console.log("error", err);
        });
    }
  };
  const onFileChange = async (e) => {
    e.preventDefault();
    

      setProductimage(e.target.files[0]);
   
    
  };
  useEffect(() => {
    //setLoading(true) ;
    //alert(id)
    //fetchData();
  }, []);
  return (
    <div className="container-scroller">
      {loadingInProgress ? <div className="parentdiv"><div className="loaderclsdiv"><ClipLoader className="loadercls" text-align="center" color={'#000'} loading={loadingInProgress}  size={35} /></div></div>: ""}
      {" "}
      <ToastContainer />
    
      <div class="layout-wrapper layout-content-navbar">
      <div class="layout-container">
    
<Aside/>
<div class="layout-page">


<Header/>
<div class="content-wrapper">


<div class="container-xxl flex-grow-1 container-p-y">
  
  
<div class="row">
<div class="col-md-6">
<h4 class="py-3 mb-4">
<span class="text-muted fw-light">Add Forms</span> 
</h4>

</div>
<div class="col-md-6" >

<Link class="dt-button add-new btn btn-primary ms-2 ms-sm-0" tabindex="0" aria-controls="DataTables_Table_0" to={config.front_URL +"/forms"}><span><span class="d-none d-sm-inline-block">Back</span></span></Link>&nbsp;

</div>
</div>



<form onSubmit={(e) => handleSubmit(e)}>
<div class="row">
<div class="col-md-12">
    <div class="card mb-4">
      {/* <h5 class="card-header">Add Content</h5> */}
      <div class="card-body">
        <div class="row mrgBtm4">
              <div class="col-md-6">
                <div>
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Parent Folder</label>
          <input type="text" readOnly class="form-control" value={(parentTitle)?parentTitle:'None'} />
          {/* <input type="text" required class="form-control" placeholder="Enter Type" onChange={(e) => setType(e.target.value)}/> */}
          
        </div>
        </div>
              <div class="col-md-6">
              <div>
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Show to Country</label>
          <select required class="form-control" onChange={(e) => {
        
        setCountryid(e.target.value);
        setCountry(e.target.value);
      }}>
          <option value="">Select Country</option>
        <option value="All Users">All Users</option>
        <option value="USA users">Usa Users</option>
        <option value="Non Usa Users">Non Usa Users</option>
        
        </select>
          {/* <CountrySelect
        onChange={(e) => {
          console.log(e)
          setCountryid(e.id);
          setCountry(e.name);
        }}
        placeHolder="Select Country"
      /> */}
          
        </div>
              </div>
        </div>
        
        
        
        
        <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Title</label>
          <input type="text" required class="form-control" placeholder="Enter Title" onChange={(e) => setTitle(e.target.value)} value={title}/>
          
        </div>
        {(par == "category")?
        <div class="mrgBtm4">
          <label for="exampleFormControlTextarea1" class="form-label">Description</label>
          {/* <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" onChange={(e) => setDescription(e.target.value)} value={description}>{description}</textarea> */}
          <CKEditor activeClass="editor" content={description} 
       
       events={{
              
               "change": (e) => setDescription(e.editor.getData())
             }} />
        
        </div>
         :
         ""
         }
        <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Type</label>
          <select  class="form-control"  onChange={(e) => setType(e.target.value)} >
            <option value="">Select Type</option>
        
            <option value="url">Url</option>
            <option value="choosefile">Choose File</option>

          </select>
          
        </div>
        {(type == 'url')?
        
        <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Url</label>
          <input type="text" required class="form-control" placeholder="Enter Url" onChange={(e) => setUrl(e.target.value)} value={url}/>
          
        </div>

        :(type == 'choosefile')? 
        <div class="mrgBtm4">
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span> Image</label>
          
          <input type="file" required class="form-control" onChange={(e) => onFileChange(e)} accept="application/pdf"/>
          
        </div>
        
        :
        ""
        
        }
        

        <div class="row mt-3">
          <div class="d-grid gap-2 col-lg-6 mx-auto">
            <button class="btn btn-primary btn-lg waves-effect waves-light" type="submit">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</div>
</form>
    
            </div>
           
            <Footer/>

  
 
</div>
</div>
           
            
</div>



</div>
</div>
  );
}

export { Addform };
