import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import config from "../config";
import axios from "axios";
import defaltimg from "../images/defalt.jpg"
function Footer() {
  const navigate = useNavigate();
  const [role, setrole] = useState(JSON.parse(localStorage.getItem("roletype")));
  
  const [user, setuser] = useState([]);
  const [notification, setNotification] = useState([]);




  
  

  /*  console.log("header",window.location.href);
  if(window.location.href==`${config.front_URL}/login`){  //  http://localhost:3000/login
    return null;
  }  */

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("status")) == true) {
      setrole(JSON.parse(localStorage.getItem("roletype")));
      setuser(JSON.parse(localStorage.getItem("UserData")));
      
      //fetchData();
    } else {
     // navigate("/login");
    }
  }, []);
  return (
    
    <footer class="content-footer footer bg-footer-theme">

</footer>
          
          
  );
}

export { Footer };
