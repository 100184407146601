import React, { useEffect,useState } from "react";
import config from "../config";
import axios from "axios";
import { useNavigate,Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader"; 
import "./login.css";
import { Header } from "./header";
import { Aside } from "./aside";
import { Footer } from "./footer";
import CKEditor from "react-ckeditor-component";
import moment from 'moment-timezone';
import {
  CitySelect,
  CountrySelect,
  StateSelect,
  LanguageSelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
function Edituser() {
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  const navigate = useNavigate();
  let   [loadingInProgress, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [position, setPosition] = useState("");
  const [positionarr, setPositionarr] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [countryid, setCountryid] = useState("");
  const [stateid, setstateid] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmpassword] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity ]= useState("");
  const [registration, setregistration ]= useState("");
  const [lastseen, setLastseen ]= useState("");
  const [device, setDevice ]= useState("");
  const [version, setVersion ]= useState("");

  let handleSubmit =  (e) => {
    e.preventDefault()
   
    
    setLoading(true) ;
    
    let getloginuser = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        status: parseInt(status),
        position: position,
        firstName: firstName,
        lastName: lastname,
        email: email,
        phone: phone,
        country: country,
        state: state,
        city: city,
        
        companyName: company,
        id:id
      }),
    }
    fetch(config.backend_URL + "/editRegister", getloginuser)
    .then((response) => response.json())
    .then((responseJson) => {
      console.log(responseJson);
      
          
        

          if (responseJson.status) {
            /*        localStorage.setItem('Id',data.data.data.ADMIN_ID); */
            setLoading(false)
          //e.target.reset();
            
            
            toast(responseJson.msz);
            
            
          
        } else {
          toast(responseJson.msz);
          //e.target.reset();
          setLoading(false)
          
        }
      
    })
    .catch((error) => {
      toast('It will take some time!');
      setLoading(false)
      console.error(error);
    });


    
  };
  let handleSubmit1 =  (e) => {
    e.preventDefault()
   
    if(password != confirmpassword){
      toast('Password not matched!');
      return false;
    }
    setLoading(true) ;
    
    let getloginuser = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
       
        password: password,
        
        id:id
      }),
    }
    fetch(config.backend_URL + "/editPassword", getloginuser)
    .then((response) => response.json())
    .then((responseJson) => {
      console.log(responseJson);
      
          
        

          if (responseJson.status) {
            /*        localStorage.setItem('Id',data.data.data.ADMIN_ID); */
            setLoading(false)
          //e.target.reset();
            
            
            toast(responseJson.msz);
            
            
          
        } else {
          toast(responseJson.msz);
          //e.target.reset();
          setLoading(false)
          
        }
      
    })
    .catch((error) => {
      toast('It will take some time!');
      setLoading(false)
      console.error(error);
    });


    
  };
  const fetchData = () => {
    //e.preventDefault()
    setLoading(true)
    let getloginuser = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: id,
        
      }),
    };
    return setLoading(true) ,fetch(config.backend_URL + "/getUserdata", getloginuser)
    .then((response) => response.json())
    .then((responseJson) => {
      setLoading(false)
      console.log(responseJson.data)
          if (responseJson.status) {
            /*        localStorage.setItem('Id',data.data.data.ADMIN_ID); */
           
           setStatus(responseJson.data[0].status)
           setPosition(responseJson.data[0].position)
           setCompany(responseJson.data[0].companyName)
           setFirstName(responseJson.data[0].firstName)
           setLastName(responseJson.data[0].lastName)
           setEmail(responseJson.data[0].email)
           setPhone(responseJson.data[0].phone)
           setPassword(responseJson.data[0].password)
           setCountry(responseJson.data[0].country)
           setState(responseJson.data[0].state)
           setCity(responseJson.data[0].city)
           setregistration(responseJson.data[0].registerDateTime)
           setDevice(responseJson.data[0].device)
           setLastseen(responseJson.data[0].dateTime)
           setVersion(responseJson.data[0].version)
           setConfirmpassword(responseJson.data[0].password)
           //alert(company)
            
            
            //toast(responseJson.msz);
            
            
          
        } else {
          toast(responseJson.msz);
          
          //setLoading(false)
          
        }
      
    })
    .catch((error) => {
      toast('It will take some time!');
      setLoading(false)
      //console.error(error);
    });

      setLoading(false)
  };
  const fetchData2 = () => {
    //e.preventDefault()
    setLoading(true)
    let getloginuser = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      
    };
    return setLoading(true) ,fetch(config.backend_URL1 + "getPosition", getloginuser)
    .then((response) => response.json())
    .then((responseJson) => {
      setLoading(false)
      //console.log(responseJson);
          if (responseJson.status) {
           
            setPositionarr(responseJson.data)
          
        } else {
          toast(responseJson.msz);
          
          //setLoading(false)
          
        }
      
    })
    .catch((error) => {
      toast('It will take some time!');
      setLoading(false)
      //console.error(error);
    });
  
      setLoading(false)
  };
  useEffect(() => {
    //setLoading(true) ;
    //alert(id)
    fetchData2();
    fetchData();
  }, []);
  const confirmit = (e,id) => {
    if (window.confirm("Are you sure you want to delete this record ?")) {
      deleteData(id)
    }}
    const deleteData = async (id) => {
      //e.preventDefault();
     //alert(ids)
    
     let getloginuser = {
       method: "POST",
       headers: {
         Accept: "application/json",
         "Content-Type": "application/json",
       },
       body: JSON.stringify({
         id: id,
         
         
       }),
     };
     return setLoading(true) ,fetch(config.backend_URL +'/deleteUserProfile',getloginuser)
     .then((response) => response.json())
     .then((responseJson) => {
       setLoading(false)
       //console.log(responseJson);
       
           if (responseJson.status) {
             toast(responseJson.msz);
             navigate("/user");
         } else {
           toast(responseJson.msz);
           navigate("/user");
           //setLoading(false)
           
         }
       
     })
     .catch((error) => {
       console.error(error);
       toast('It will take some time!');
       setLoading(false)
     });
    };
  return (
    <div className="container-scroller">
      {loadingInProgress ? <div className="parentdiv"><div className="loaderclsdiv"><ClipLoader className="loadercls" text-align="center" color={'#000'} loading={loadingInProgress}  size={35} /></div></div>: ""}
      {" "}
      <ToastContainer />
    
      <div class="layout-wrapper layout-content-navbar">
      <div class="layout-container">
    
<Aside/>
<div class="layout-page">


<Header/>
<div class="content-wrapper">


<div class="container-xxl flex-grow-1 container-p-y">
  
  


<div class="row">
<div class="col-md-6">
<h4 class="py-3 mb-4">
<span class="text-muted fw-light">Edit User</span> 
</h4>

</div>
<div class="col-md-6" >

<button  className="btn btn-danger add-new"  type="button" onClick={(e) => confirmit(e,id)} >Delete</button>
<Link class="dt-button add-new btn btn-primary ms-2 ms-sm-0" tabindex="0" aria-controls="DataTables_Table_0" to={config.front_URL +"/user"}><span><span class="d-none d-sm-inline-block">Back</span></span></Link>
</div>
</div>

<form onSubmit={(e) => handleSubmit(e)}>
<div class="row">
<div class="col-md-12">
    <div class="card mb-4">
      {/* <h5 class="card-header">Add Content</h5> */}
      <div class="card-body">
        <div class="row mrgBtm4">
              <div class="col-md-4">
                <div>
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Status</label>
          <select required class="form-control"  onChange={(e) => setStatus(e.target.value)} value={status}>
            <option value="">Select Status</option>
            {/* <option value="welcome">Welcome Page</option> */}
            <option value="0">Active</option>
            <option value="1">Pending</option>
            <option value="2">Suspending</option>

          </select>
          {/* <input type="text" required class="form-control" placeholder="Enter Type" onChange={(e) => setType(e.target.value)}/> */}
          
        </div>
        </div>
        <div class="col-md-4">
                <div>
          <label for="defaultFormControlInput" class="form-label">Position</label>
          <select  class="form-control"  onChange={(e) => setPosition(e.target.value)} value={position} >
            <option value="">Select Type</option>
            {positionarr.map((position) => {
                            return (
<option value={position.tiltle} >{position.tiltle}</option>
                              );
                            })}

          </select>
          {/* <select  class="form-control"  onChange={(e) => setPosition(e.target.value)} value={position}>
            <option value="">Select Position</option>
          
            <option value="Other">Other</option>
            <option value="Agent">Agent</option>

          </select> */}
          {/* <input type="text" required class="form-control" placeholder="Enter Type" onChange={(e) => setType(e.target.value)}/> */}
          
        </div>
        </div>
              <div class="col-md-4">
              <div>
          <label for="defaultFormControlInput" class="form-label">Distributor company</label>
          <input type="text"  class="form-control" placeholder="Enter Company" value={company} onChange={(e) => setCompany(e.target.value)}/>
          
        </div>
              </div>
        </div>
        <div class="row mrgBtm4">
              <div class="col-md-6">
                <div>
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>First Name</label>
          
          <input type="text" required class="form-control" value={firstName} placeholder="Enter First Name" onChange={(e) => setFirstName(e.target.value)}/>
          
        </div>
        </div>
              <div class="col-md-6">
              <div>
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Last Name</label>
          <input type="text" required class="form-control" value={lastname} placeholder="Enter Last Name" onChange={(e) => setLastName(e.target.value)}/>
          
        </div>
              </div>
        </div>
        
        <div class="row mrgBtm4">
              <div class="col-md-6"> 
               <div>
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Email</label>
          <input type="type" required class="form-control" value={email} placeholder="Enter Email" onChange={(e) => setEmail(e.target.value)}/>
          
        </div>
        </div>
              <div class="col-md-6">
              <div>
          <label for="defaultFormControlInput" class="form-label">Phone</label>
          <input type="type"  class="form-control" value={phone} placeholder="Enter Phone" onChange={(e) => setPhone(e.target.value)}/>
          
        </div>
              </div>
        </div>
      

        <div class="row mrgBtm4">
          <div class="col-md-4">
          <label for="defaultFormControlInput" class="form-label">Country</label>
          <CountrySelect
        onChange={(e) => {
          console.log(e)
          setCountryid(e.id);
          setCountry(e.name);
        }}
       
        placeHolder={country}
      />
          </div>
          <div class="col-md-4">
          <label for="defaultFormControlInput" class="form-label">State</label>
          <StateSelect
        countryid={countryid}
        onChange={(e) => {
          setstateid(e.id);
          setState(e.name);
        }}
        placeHolder={state}
      />
          </div>
          <div class="col-md-4">
          <label for="defaultFormControlInput" class="form-label">City</label>
          <CitySelect
        countryid={countryid}
        stateid={stateid}
        onChange={(e) => {
          console.log(e);
          setCity(e.name);
        }}
        placeHolder={city}
      />
          </div>
        </div>
      {/* <div class="row mrgBtm4">
        <div class="col-md-6">
        <div>
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Password</label>
          <input type="password" required class="form-control" value={password} placeholder="Enter Password" onChange={(e) => setPassword(e.target.value)}/>
          
        </div>
        </div>
        <div class="col-md-6">
        <div>
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Confirm Password</label>
          <input type="password" required class="form-control" value={password} placeholder="Enter Confirm Password" onChange={(e) => setConfirmpassword(e.target.value)}/>
          
        </div>
        </div>
      </div> */}
<div class="row">
<div class="col-md-2">
<div>
          <label for="defaultFormControlInput" class="form-label">Registration</label>
          <input type="text" readOnly class="form-control" value={moment(registration).tz("Israel").format("DD/MM/YYYY h:m")} placeholder="Enter Registration"/>
          
        </div>
</div>
<div class="col-md-2">
<div>
          <label for="defaultFormControlInput" class="form-label">Last Seen</label>
          <input readOnly type="text"  class="form-control" value={moment(lastseen).tz("Israel").format("DD/MM/YYYY h:m")} placeholder="Enter Last Seen" />
          
        </div>
</div>
<div class="col-md-2">
<div>
          <label for="defaultFormControlInput" class="form-label">Device</label>
          <input readOnly type="text"  class="form-control" value={device} placeholder="Enter Device" />
          
        </div>
</div>
<div class="col-md-2">
<div>
          <label for="defaultFormControlInput" class="form-label">Application version</label>
          <input readOnly type="text"  class="form-control" value={version} placeholder="Enter Application version" />
          
        </div>
</div>
<div class="col-md-4">
<div>
          <label for="defaultFormControlInput" class="form-label">Onesignal push token</label>
          <input readOnly type="text"  class="form-control" value="" placeholder="Enter Onesignal push token" />
          
        </div>
</div>
</div>
        <div class="row mt-3">
          <div class="d-grid gap-2 col-lg-6 mx-auto">
            <button class="btn btn-primary btn-lg waves-effect waves-light" type="submit">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</div>
</form>
<div class="card mb-4">
      <h5 class="card-header">Password</h5>
      <div class="card-body">
    <form onSubmit={(e) => handleSubmit1(e)}>
    <div class="row mrgBtm4">
        <div class="col-md-6">
        <div>
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Password</label>
          <input type="password" required class="form-control" value={password} placeholder="Enter Password" onChange={(e) => setPassword(e.target.value)}/>
          
        </div>
        </div>
        <div class="col-md-6">
        <div>
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Confirm Password</label>
          <input type="password" required class="form-control" value={confirmpassword} placeholder="Enter Confirm Password" onChange={(e) => setConfirmpassword(e.target.value)}/>
          
        </div>
        </div>
      </div>
      <div class="row mt-3">
          <div class="d-grid gap-2 col-lg-6 mx-auto">
            <button class="btn btn-primary btn-lg waves-effect waves-light" type="submit">Save</button>
          </div>
        </div>
    </form>
    </div>
    </div>
            </div>
           
            <Footer/>

  
 
</div>
</div>
           
            
</div>



</div>
</div>
  );
}

export { Edituser };
