import React, { useEffect,useState } from "react";
import config from "../config";
import axios from "axios";
import { useNavigate,Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader"; 
import "./login.css";
import { Header } from "./header";
import { Aside } from "./aside";
import { Footer } from "./footer";
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import moment from 'moment-timezone';

import Draggable from 'react-draggable';
function Product() {
  const navigate = useNavigate();
  let   [loadingInProgress, setLoading] = useState(false);
  const [email, setemail] = useState("");
  const [password, setPassword] = useState("");
  const [product, setProduct] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    productName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    categoryName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
   
});
const onGlobalFilterChange = (e) => {
  const value = e.target.value;
  let _filters = { ...filters };

  _filters['global'].value = value;

  setFilters(_filters);
  setGlobalFilterValue(value);
};
  let handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true) ;
    let formData = new FormData();

    formData.append("email", email);
    formData.append("password", password);
   

    //console.log(formData);
    //api call starts using axios
    axios
      .post(`${config.backend_URL}/login/login_verify`, formData)
      .then(async (data) => {
        console.log("in api", data);
        if (data.data.status) {
          setemail("");
          setPassword("");
        

          if (data.data.status) {
            /*        localStorage.setItem('Id',data.data.data.ADMIN_ID); */
            setLoading(false)
            localStorage.setItem("status", true);
            localStorage.setItem("UserData", JSON.stringify(data.data.data)); 
            
            toast(data.data.msg);
            
            navigate("/home");
          }
        } else {
          toast(data.data.msg);
          e.target.reset();
          setLoading(false)
          navigate("/home");
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  const getreorder = async (data,datakey) => {
    //console.log(data)
    setProduct(data)
    let getloginuser = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        productarray: data,
       
        
      }),
    };
    return setLoading(true) ,fetch(config.backend_URL + '/reorderProduct', getloginuser)
    .then((response) => response.json())
    .then((responseJson) => {
      setLoading(false)
      //console.log(responseJson);
      //fetchData();
          if (responseJson.status) {
            toast(responseJson.msz);
        } else {
          toast(responseJson.msz);
          
          setLoading(false)
          
        }
      
    })
    .catch((error) => {
      console.error(error);
      toast('It will take some time!');
      setLoading(false)
    });
  }
  const renderHeader = () => {
    return (
      <div class="row">
<div class="col-md-6">

              
<InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search...." />
         
   
</div>
<div class="col-md-6">
<Link class="dt-button add-new btn btn-primary ms-2 ms-sm-0" tabindex="0" aria-controls="DataTables_Table_0" to={config.front_URL +"/addproduct"} ><span><i class="fa fa-plus me-0 me-sm-1 ti-xs"></i><span class="d-none d-sm-inline-block">Add</span></span></Link></div>

      </div>
      
    );
};
  const header = renderHeader();
  const rowClass = (data) => {
    return {
        'bg-primary': data.category === 'Fitness'
    };
};
const fetchData = () => {
  //e.preventDefault()
  setLoading(true)
  let getloginuser = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      type: 'type',
      
    }),
  };
  return setLoading(true) ,fetch(config.backend_URL + "/getProducts", getloginuser)
  .then((response) => response.json())
  .then((responseJson) => {
    setLoading(false)
        if (responseJson.status) {
          /*        localStorage.setItem('Id',data.data.data.ADMIN_ID); */
         
          setProduct(responseJson.data)
          
          
          //toast(responseJson.msz);
          
          
        
      } else {
        toast(responseJson.msz);
        
        //setLoading(false)
        
      }
    
  })
  .catch((error) => {
    toast('It will take some time!');
    setLoading(false)
    //console.error(error);
  });

    setLoading(false)
};
const actionBodyTemplate1 = (rowData) => {

  return (
      <React.Fragment>
        
           <span class={rowData.status == 0?"badge bg-label-success":"badge bg-label-danger"} text-capitalized="">{rowData.status == 0?"Active":"Inactive"}</span>
          
      </React.Fragment>
  );
};

const actionBodyTemplate2 = (rowData,index) => {
//console.log(index)
let index2 = parseInt(index.rowIndex + 1);
  return (
      <React.Fragment>
           <span class="badge bg-label-success" >{index2}</span>
          
      </React.Fragment>
  );
};
const actionBodyTemplate = (rowData) => {
  return (
      <React.Fragment>
          <Link   className="btn btn-info btn-sm"  to={config.front_URL +"/editproduct?id="+rowData._id}><i class="fa fa-pencil"></i></Link>&nbsp;
          <button class="btn btn-danger btn-sm" type="button" onClick={(e) => deleteData(e,rowData._id)}><i class="fa fa-trash" aria-hidden="true"></i></button> &nbsp;
          <Link    className="btn btn-warning btn-sm"  to={config.front_URL +"/listProductfile?id="+rowData._id}><i class="fa fa-eye"></i></Link>&nbsp;
          &nbsp;
         {/*  <Link    className="btn btn-primary btn-sm"  to={config.front_URL +"/listProductfile?id="+rowData._id}><i class="fa fa-bars"></i></Link>&nbsp;
          &nbsp; */}
      </React.Fragment>
  );
};
const deleteData = async (e,id) => {
  e.preventDefault();
 //alert(id)

 let getloginuser = {
   method: "POST",
   headers: {
     Accept: "application/json",
     "Content-Type": "application/json",
   },
   body: JSON.stringify({
     id: id,
    
     
   }),
 };
 return setLoading(true) ,fetch(config.backend_URL + '/deleteProduct', getloginuser)
 .then((response) => response.json())
 .then((responseJson) => {
   setLoading(false)
   //console.log(responseJson);
       if (responseJson.status) {
         toast(responseJson.msz);
         fetchData();
     } else {
       toast(responseJson.msz);
       
       //setLoading(false)
       
     }
   
 })
 .catch((error) => {
   console.error(error);
   toast('It will take some time!');
   setLoading(false)
 });
};

const actionBodyTemplate3 = (rowData) => {
  //console.log(index)
 
    return (
      <React.Fragment>
    
       { moment(rowData.dateTime).tz("Israel").format("DD/MM/YYYY h:m")
       
       //moment(rowData.dateTime).format("DD/MM/YYYY h:m")
       }
      </React.Fragment>
    )
            
   
  
  };
  const actionBodyTemplate4 = (rowData) => {
    //console.log(index)
   
      return (
        <React.Fragment>
        
          { moment(rowData.createdDate).tz("Israel").format("DD/MM/YYYY h:m")
          //moment(rowData.createdDate).format("DD/MM/YYYY h:m")
        }
        </React.Fragment>
      )
              
     
    
    };
useEffect(() => {
  //setLoading(true) ;
  fetchData();
  console.log(product)
}, []);
  return (
    <div className="container-scroller">
      {loadingInProgress ? <div className="parentdiv"><div className="loaderclsdiv"><ClipLoader className="loadercls" text-align="center" color={'#000'} loading={loadingInProgress}  size={35} /></div></div>: ""}
      {" "}
      <ToastContainer />
    
      <div class="layout-wrapper layout-content-navbar">
      <div class="layout-container">
    
<Aside/>
<div class="layout-page">


<Header/>
      
      <div class="content-wrapper">


  <div class="container-xxl flex-grow-1 container-p-y">
    
    

<h4 class="py-3 mb-4">
<span class="text-muted fw-light">Product</span> 
</h4>






<div class="card">
 
  <div class="card-datatable table-responsive">
    <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap5 no-footer">
     
      <DataTable onRowReorder={(e) => getreorder(e.value,e.value.key)}  reorderableColumns reorderableRows showGridlines  rowClassName={rowClass} header={header} globalFilterFields={['productName','categoryName']} dataKey="id"  filters={filters} filterDisplay="row" value={product} tableStyle={{ minWidth: '10rem'}} paginator  rows={10} rowsPerPageOptions={[5, 10, 25, 50]}  emptyMessage="No customers found.">
      <Column  body={actionBodyTemplate} exportable={false} style={{ width:'16%',minWidth: '2rem',borderTop: "1px solid #ADB5BD" }}></Column>
      <Column rowReorder  style={{ width:'3%',minWidth: '2rem',borderTop: "1px solid #ADB5BD" }}></Column>
      <Column  field="_id"   sortable header="Id" style={{ minWidth: '2rem',borderTop: "1px solid #ADB5BD" }}></Column>
    <Column field="status" header="Status" body={actionBodyTemplate1} sortable style={{ minWidth: '3rem',borderTop: "1px solid #ADB5BD",padding:"10px" }}></Column>
    <Column field="categoryName" sortable header="Category" style={{ minWidth: '2rem',borderTop: "1px solid #ADB5BD",padding:"10px" }}></Column>
    <Column field="productName" sortable header="Product Name" style={{ minWidth: '2rem',borderTop: "1px solid #ADB5BD",padding:"10px" }}></Column>
    <Column field="dateTime" sortable header="Updated" body={actionBodyTemplate3} style={{ minWidth: '2rem',borderTop: "1px solid #ADB5BD",padding:"10px" }}></Column>
    <Column field="createdDate" sortable header="Created" body={actionBodyTemplate4} style={{ minWidth: '2rem',borderTop: "1px solid #ADB5BD",padding:"10px" }}></Column>
   
</DataTable>
                                    
                                  
    </div>
  </div>
</div>


  </div>
 

  
  



<Footer/>

  
 
</div>
</div>
           
            
</div>



</div>
</div>
   
    
  );
}

export { Product };
