import React, { useEffect,useState } from "react";
import config from "../config";
import axios from "axios";
import { useNavigate,Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader"; 
import "./login.css";
import { Header } from "./header";
import { Aside } from "./aside";
import { Footer } from "./footer";
import CKEditor from "react-ckeditor-component";

function Editcontent() {
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  const navigate = useNavigate();
  let   [loadingInProgress, setLoading] = useState(false);
  const [type, setType] = useState("");
  const [contents, setContents] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [fulltext, setFulltext] = useState("");
 

  let handleSubmit =  (e) => {
    e.preventDefault()
   setLoading(true) ;
   // navigate("/user");
    //alert(fulltext);
    
    let getloginuser = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        type: type,
        content: contents,
        title: title,
        description: description,
        fulltext: fulltext,
        id: id,
      }),
    };
    fetch(config.backend_URL + "/editcontents", getloginuser)
    .then((response) => response.json())
    .then((responseJson) => {
    
          if (responseJson.status) {
           
            setLoading(false)
      
            
            
            toast(responseJson.msz);
            
            
          
        } else {
          toast(responseJson.msz);
          e.target.reset();
          setLoading(false)
          
        }
      
    })
    .catch((error) => {
      toast('It will take some time!');
      setLoading(false)
      console.error(error);
    });


    
  };
 
  const fetchData = () => {
    //e.preventDefault()
    setLoading(true)
    let getloginuser = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: id,
        
      }),
    };
    return setLoading(true) ,fetch(config.backend_URL + "/getAppcontentforedit", getloginuser)
    .then((response) => response.json())
    .then((responseJson) => {
      setLoading(false)
      console.log(responseJson.data)
          if (responseJson.status) {
            /*        localStorage.setItem('Id',data.data.data.ADMIN_ID); */
           
            setType(responseJson.data.type)
            setContents(responseJson.data.content)
            setTitle(responseJson.data.title)
            setDescription(responseJson.data.description)
            setFulltext(responseJson.data.fullText)
           
            
            
            //toast(responseJson.msz);
            
            
          
        } else {
          toast(responseJson.msz);
          
          //setLoading(false)
          
        }
      
    })
    .catch((error) => {
      toast('It will take some time!');
      setLoading(false)
      //console.error(error);
    });

      setLoading(false)
  };
  useEffect(() => {
    //setLoading(true) ;
    //alert(id)
    fetchData();
  }, []);
  return (
    <div className="container-scroller">
      {loadingInProgress ? <div className="parentdiv"><div className="loaderclsdiv"><ClipLoader className="loadercls" text-align="center" color={'#000'} loading={loadingInProgress}  size={35} /></div></div>: ""}
      {" "}
      <ToastContainer />
    
      <div class="layout-wrapper layout-content-navbar">
      <div class="layout-container">
    
<Aside/>
<div class="layout-page">


<Header/>
<div class="content-wrapper">


<div class="container-xxl flex-grow-1 container-p-y">
  
<div class="row">
    <div class="col-md-6">
        <h4 class="py-3 mb-4">
<span class="text-muted fw-light">Edit Contents</span> 
</h4>
</div>
    <div class="col-md-6">
    <Link class="dt-button add-new btn btn-primary ms-2 ms-sm-0" tabindex="0" aria-controls="DataTables_Table_0" to={config.front_URL +"/appcontent"}><span><span class="d-none d-sm-inline-block">Back</span></span></Link>
    </div>
</div>




<form onSubmit={(e) => handleSubmit(e)}>
<div class="row">
<div class="col-md-12">
    <div class="card mb-4">
      {/* <h5 class="card-header">Add Content</h5> */}
      <div class="card-body">
        <div class="row">
              <div class="col-md-6">
                <div>
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Type</label>
          <select required class="form-control"  onChange={(e) => setType(e.target.value)} value={type}>
            <option value="">Select Type</option>
            {/* <option value="welcome">Welcome Page</option> */}
            <option value="page">Page</option>
            <option value="popup">Popup</option>

          </select>
          {/* <input type="text" required class="form-control" placeholder="Enter Type" onChange={(e) => setType(e.target.value)}/> */}
          
        </div>
        </div>
              <div class="col-md-6">
              <div>
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Content</label>
          <input type="text" required class="form-control" placeholder="Enter Content" onChange={(e) => setContents(e.target.value)} value={contents}/>
          
        </div>
              </div>
        </div>
        
        
        
        
        <div>
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Title</label>
          <input type="type" required class="form-control" placeholder="Enter Title" onChange={(e) => setTitle(e.target.value)} value={title}/>
          
        </div>
        <div>
          <label for="exampleFormControlTextarea1" class="form-label">Description</label>
          <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" onChange={(e) => setDescription(e.target.value)} value={description}>{description}</textarea>
        </div>
        <div>
          <label for="exampleFormControlTextarea1" class="form-label">Full Text</label>
          {/* <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" onChange={(e) => setFulltext(e.target.value)}></textarea> */}
        </div>
        <CKEditor activeClass="editor" content={fulltext} events={{
               
                "change": (e) => setFulltext(e.editor.getData())
              }} />

        <div class="row mt-3">
          <div class="d-grid gap-2 col-lg-6 mx-auto">
            <button class="btn btn-primary btn-lg waves-effect waves-light" type="submit">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</div>
</form>
    
            </div>
           
            <Footer/>

  
 
</div>
</div>
           
            
</div>



</div>
</div>
  );
}

export { Editcontent };
