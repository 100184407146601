import React, { useEffect,useState } from "react";
import config from "../config";
import axios from "axios";
import { useNavigate,Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader"; 
import "./login.css";
import { Header } from "./header";
import { Aside } from "./aside";
import { Footer } from "./footer";
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import moment from 'moment-timezone';
function Inbox() {
  const navigate = useNavigate();
  let   [loadingInProgress, setLoading] = useState(false);
  const [email, setemail] = useState("");
  const [password, setPassword] = useState("");
  const [inbox, setInbox] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    subject: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    message: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    phone: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
   
});
const onGlobalFilterChange = (e) => {
  const value = e.target.value;
  let _filters = { ...filters };

  _filters['global'].value = value;

  setFilters(_filters);
  setGlobalFilterValue(value);
};
  
  const renderHeader = () => {
    return (
      <div class="row">
<div class="col-md-6">

              
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange}   placeholder="Search....." />
         
   
</div>
<div class="col-md-6">
</div>

      </div>
      
    );
};
  const header = renderHeader();
  const rowClass = (data) => {
    return {
        'bg-primary': data.category === 'Fitness'
    };
};

const fetchData = () => {
  //e.preventDefault()
  setLoading(true)
  let getloginuser = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      type: 'type',
      
    }),
  };
  return setLoading(true) ,fetch(config.backend_URL + "/getAllinbox", getloginuser)
  .then((response) => response.json())
  .then((responseJson) => {
    setLoading(false)
    console.log(responseJson);
        if (responseJson.status) {
          /*        localStorage.setItem('Id',data.data.data.ADMIN_ID); */
         
          setInbox(responseJson.data)
          
          
          //toast(responseJson.msz);
          
          
        
      } else {
        toast(responseJson.msz);
        
        //setLoading(false)
        
      }
    
  })
  .catch((error) => {
    toast('It will take some time!');
    setLoading(false)
    //console.error(error);
  });

    setLoading(false)
};
const deleteData = async (e,id) => {
  e.preventDefault();


 let getloginuser = {
   method: "POST",
   headers: {
     Accept: "application/json",
     "Content-Type": "application/json",
   },
   body: JSON.stringify({
     id: id,
    
     
   }),
 };
 return setLoading(true) ,fetch(config.backend_URL + '/deleteInbox', getloginuser)
 .then((response) => response.json())
 .then((responseJson) => {
   setLoading(false)
   //console.log(responseJson);
   fetchData();
       if (responseJson.status) {
         toast(responseJson.msz);
     } else {
       toast(responseJson.msz);
       
       //setLoading(false)
       
     }
   
 })
 .catch((error) => {
   console.error(error);
   toast('It will take some time!');
   setLoading(false)
 });
};
const actionBodyTemplate = (rowData) => {
  return (
      <React.Fragment>
         <Link   className="btn btn-info btn-sm"  to={{ pathname: "/viewinbox", search:"?id="+rowData._id}} state={{date:rowData.createdDate,description:rowData.message,name: rowData.name,subject:rowData.subject,email:rowData.email,phone:rowData.phone}} ><i class="fa fa-pencil"></i></Link>&nbsp;
          
         
          <button class="btn btn-danger btn-sm" type="button" onClick={(e) => deleteData(e,rowData._id)}><i class="fa fa-trash" aria-hidden="true"></i></button> &nbsp;
          
          &nbsp;
      </React.Fragment>
  );
};


const actionBodyTemplate1 = (rowData) => {
  return (
      <React.Fragment>
        {
        (rowData.status == 0)
        ?
        <span style={{color:'#27AE60'}}>New</span>
        :
        <span style={{color:'#6f6b7d'}}>Read</span>
        }
       
      </React.Fragment>
  );
};
const actionBodyTemplate4 = (rowData) => {
  //console.log(index)
 
    return (
      <React.Fragment>
      
        { moment(rowData.createdDate).tz("Israel").format("DD/MM/YYYY h:m")}
      </React.Fragment>
    )
            
   
  
  };
useEffect(() => {
  //setLoading(true) ;
  
  fetchData();
}, []);
  return (
    <div className="container-scroller">
    {loadingInProgress ? <div className="parentdiv"><div className="loaderclsdiv"><ClipLoader className="loadercls" text-align="center" color={'#000'} loading={loadingInProgress}  size={35} /></div></div>: ""}
    {" "}
    <ToastContainer />
  
    <div class="layout-wrapper layout-content-navbar">
    <div class="layout-container">
  
<Aside/>
<div class="layout-page">


<Header/>
    
    <div class="content-wrapper">


<div class="container-xxl flex-grow-1 container-p-y">
  
  

<h4 class="py-3 mb-4">
<span class="text-muted fw-light">Inbox</span> 
</h4>






<div class="card">

<div class="card-datatable table-responsive">
 
  <DataTable showGridlines   header={header} globalFilterFields={['subject','message','name','email','phone']} dataKey="id"  filters={filters} filterDisplay="row" value={inbox} tableStyle={{ minWidth: '10rem'}} paginator  rows={10} rowsPerPageOptions={[5, 10, 25, 50]}  emptyMessage="No customers found.">
  
  <Column body={actionBodyTemplate}  style={{ minWidth: '2rem',borderTop: "1px solid #ADB5BD" }}></Column>
    <Column field="_id"  sortable header="Id" style={{ minWidth: '2rem',borderTop: "1px solid #ADB5BD",padding:"10px" }}></Column>
    <Column field="status" sortable body={actionBodyTemplate1}  header="Status" style={{ minWidth: '2rem',borderTop: "1px solid #ADB5BD",padding:"10px" }}></Column>
    <Column field="subject" sortable header="Subject" style={{ minWidth: '2rem',borderTop: "1px solid #ADB5BD",padding:"10px" }}></Column>
    <Column field="message" sortable header="Message" style={{ minWidth: '2rem',borderTop: "1px solid #ADB5BD",padding:"10px" }}></Column>
    <Column field="name" sortable header="User" style={{ minWidth: '2rem',borderTop: "1px solid #ADB5BD",padding:"10px" }}></Column>
    <Column field="email" sortable header="Email" style={{ minWidth: '2rem',borderTop: "1px solid #ADB5BD",padding:"10px" }}></Column>
    <Column field="phone" sortable header="Phone" style={{ minWidth: '2rem',borderTop: "1px solid #ADB5BD",padding:"10px" }}></Column>
    <Column field="createdDate" sortable body={actionBodyTemplate4}  header="Sent" style={{ minWidth: '2rem',borderTop: "1px solid #ADB5BD",padding:"10px" }}></Column>
   
</DataTable>
                                    
    
  </div>
</div>


  </div>
 

  
  



<Footer/>

  
 
</div>
</div>
           
            
</div>



</div>
</div>
   
    
  );
}

export { Inbox };
