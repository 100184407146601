import React, { useState } from "react";
import config from "../config";
import axios from "axios";
import { useNavigate,Link,useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader"; 
import "./login.css";
import { Header } from "./header";
import { Aside } from "./aside";
import { Footer } from "./footer";
import CKEditor from "react-ckeditor-component";

function EditAdmintype() {
  const navigate = useNavigate();
  const location = useLocation()
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  let   [loadingInProgress, setLoading] = useState(false);
  const [type, setType] = useState(location.state.type);
  const [UsersPermission, setUsersPermission] = useState(location.state.UsersPermission);
  const [PresentationPermission, setPresentationPermission] = useState(location.state.PresentationPermission);
  const [ProductsPermission, setProductsPermission] = useState(location.state.ProductsPermission);
  const [TraningPermission, setTraningPermission] = useState(location.state.TraningPermission);
  const [FormsPermission, setFormsPermission] = useState(location.state.FormsPermission);
  const [ContactusPermission, setContactusPermission] = useState(location.state.ContactusPermission);
  const [AppcontentPermission, setAppcontent] = useState(location.state.AppcontentPermission);
  const [InboxPermission, setInbox] = useState(location.state.InboxPermission);
  const [SystemmanagerPermission, setSystemmanager] = useState(location.state.SystemmanagerPermission);
  const [SettingsPermission, setSettings] = useState(location.state.SettingsPermission);
  const [MessagePermission, setMessage] = useState(location.state.MessagePermission);
  
 

  let handleSubmit =  (e) => {
    e.preventDefault()
   setLoading(true) ;
   // navigate("/user");
    //alert(fulltext);
    
    let getloginuser = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        type: type,
        id: id,
        UsersPermission:UsersPermission,
        PresentationPermission:PresentationPermission,
        ProductsPermission:ProductsPermission,
        TraningPermission:TraningPermission,
        FormsPermission:FormsPermission,
        ContactusPermission:ContactusPermission,
        AppcontentPermission:AppcontentPermission,
        InboxPermission:InboxPermission,
        SystemmanagerPermission:SystemmanagerPermission,
        SettingsPermission:SettingsPermission,
        MessagePermission:MessagePermission,
      }),
    };
    fetch(config.backend_URL + "/editadmintype", getloginuser)
    .then((response) => response.json())
    .then((responseJson) => {
      console.log(responseJson);
      
         
       
        

          if (responseJson.status) {
            /*        localStorage.setItem('Id',data.data.data.ADMIN_ID); */
            setLoading(false)
         // e.target.reset();
            
            
            toast(responseJson.msz);
            
            
          
        } else {
          toast(responseJson.msz);
          //e.target.reset();
          setLoading(false)
          
        }
      
    })
    .catch((error) => {
      toast('It will take some time!');
      setLoading(false)
      console.error(error);
    });


    
  };
 
  return (
    <div className="container-scroller">
      {loadingInProgress ? <div className="parentdiv"><div className="loaderclsdiv"><ClipLoader className="loadercls" text-align="center" color={'#000'} loading={loadingInProgress}  size={35} /></div></div>: ""}
      {" "}
      <ToastContainer />
    
      <div class="layout-wrapper layout-content-navbar">
      <div class="layout-container">
    
<Aside/>
<div class="layout-page">


<Header/>
<div class="content-wrapper">


<div class="container-xxl flex-grow-1 container-p-y">
  
  


<div class="row">
    <div class="col-md-6">
        <h4 class="py-3 mb-4">
<span class="text-muted fw-light">Edit Admin Type</span> 
</h4>
</div>
    <div class="col-md-6">
    <Link class="dt-button add-new btn btn-primary ms-2 ms-sm-0" tabindex="0" aria-controls="DataTables_Table_0" to={config.front_URL +"/systemmanager"}><span><span class="d-none d-sm-inline-block">Back</span></span></Link>
    </div>
</div>

<form onSubmit={(e) => handleSubmit(e)}>
<div class="row">
<div class="col-md-12">
    <div class="card mb-4">
      {/* <h5 class="card-header">Add Content</h5> */}
      <div class="card-body">
        <div class="row mrgBtm4">
              
              <div class="col-md-6">
              <div>
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Type</label>
          <input type="text" required class="form-control" placeholder="Enter Admin Type" onChange={(e) => setType(e.target.value)} value={type}/>
          
        </div>
              </div>
        </div>
        <div class="row mrgBtm4">
              
              <div class="col-md-6">
              <div>
          <label for="defaultFormControlInput" class="form-label">Section</label>
          
          
        </div>
              </div>
              <div class="col-md-6">
              <label for="defaultFormControlInput" class="form-label">Permission</label>
              </div>
        </div>
        <div class="row mrgBtm4">
              
              <div class="col-md-6">
              <div>
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Users</label>
          
          
        </div>
              </div>
              <div class="col-md-6">
<select required class="form-control" name="UsersPermission" onChange={(e) => setUsersPermission(e.target.value)} value={UsersPermission}> 
<option value="">Select Permission</option>
<option value="Read&write">Read & write</option>
<option value="NoPermission">No Permission </option>
</select>
              </div>
        </div>
        <div class="row mrgBtm4">
              
              <div class="col-md-6">
              <div>
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Presentation page</label>
          
          
        </div>
              </div>
              <div class="col-md-6">
<select required class="form-control" name="PresentationPermission" onChange={(e) => setPresentationPermission(e.target.value)} value={PresentationPermission}> 
<option value="">Select Permission</option>
<option value="Read&write">Read & write</option>
<option value="NoPermission">No Permission </option>
</select>
              </div>
        </div>
        
        <div class="row mrgBtm4">
              
              <div class="col-md-6">
              <div>
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Products </label>
          
          
        </div>
              </div>
              <div class="col-md-6">
<select required class="form-control"  onChange={(e) => setProductsPermission(e.target.value)} value={ProductsPermission}> 
<option value="">Select Permission</option>
<option value="Read&write">Read & write</option>
<option value="NoPermission">No Permission </option>
</select>
              </div>
        </div>
        <div class="row mrgBtm4">
              
              <div class="col-md-6">
              <div>
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Traning </label>
          
          
        </div>
              </div>
              <div class="col-md-6">
<select required class="form-control"  onChange={(e) => setTraningPermission(e.target.value)} value={TraningPermission}> 
<option value="">Select Permission</option>
<option value="Read&write">Read & write</option>
<option value="NoPermission">No Permission </option>
</select>
              </div>
        </div>
        <div class="row mrgBtm4">
              
              <div class="col-md-6">
              <div>
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Forms </label>
          
          
        </div>
              </div>
              <div class="col-md-6">
<select required class="form-control"   onChange={(e) => setFormsPermission(e.target.value)} value={FormsPermission}> 
<option value="">Select Permission</option>
<option value="Read&write">Read & write</option>
<option value="NoPermission">No Permission </option>
</select>
              </div>
        </div>
        <div class="row mrgBtm4">
              
              <div class="col-md-6">
              <div>
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Message </label>
          
          
        </div>
              </div>
              <div class="col-md-6">
<select required class="form-control"  onChange={(e) => setMessage(e.target.value)} value={MessagePermission} > 
<option value="">Select Permission</option>
<option value="Read&write">Read & write</option>
<option value="NoPermission">No Permission </option>
</select>
              </div>
        </div>
        <div class="row mrgBtm4">
              
              <div class="col-md-6">
              <div>
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Contact Us </label>
          
          
        </div>
              </div>
              <div class="col-md-6">
<select required class="form-control"  onChange={(e) => setContactusPermission(e.target.value)} value={ContactusPermission}> 
<option value="">Select Permission</option>
<option value="Read&write">Read & write</option>
<option value="NoPermission">No Permission </option>
</select>
              </div>
        </div>
        <div class="row mrgBtm4">
              
              <div class="col-md-6">
              <div>
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>App Content </label>
          
          
        </div>
              </div>
              <div class="col-md-6">
<select required class="form-control"   onChange={(e) => setAppcontent(e.target.value)} value={AppcontentPermission}> 
<option value="">Select Permission</option>
<option value="Read&write">Read & write</option>
<option value="NoPermission">No Permission </option>
</select>
              </div>
        </div>
        <div class="row mrgBtm4">
              
              <div class="col-md-6">
              <div>
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Inbox</label>
          
          
        </div>
              </div>
              <div class="col-md-6">
<select required class="form-control"  onChange={(e) => setInbox(e.target.value)} value={InboxPermission}> 
<option value="">Select Permission</option>
<option value="Read&write">Read & write</option>
<option value="NoPermission">No Permission </option>
</select>
              </div>
        </div>
        <div class="row mrgBtm4">
              
              <div class="col-md-6">
              <div>
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>System Manager</label>
          
          
        </div>
              </div>
              <div class="col-md-6">
<select required class="form-control"  onChange={(e) => setSystemmanager(e.target.value)} value={SystemmanagerPermission}> 
<option value="">Select Permission</option>
<option value="Read&write">Read & write</option>
<option value="NoPermission">No Permission </option>
</select>
              </div>
        </div>
        <div class="row mrgBtm4">
              
              <div class="col-md-6">
              <div>
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Setting</label>
          
          
        </div>
              </div>
              <div class="col-md-6">
<select required class="form-control"  onChange={(e) => setSettings(e.target.value)} value={SettingsPermission}> 
<option value="">Select Permission</option>
<option value="Read&write">Read & write</option>
<option value="NoPermission">No Permission </option>
</select>
              </div>
        </div>
        <div class="row mt-3">
          <div class="d-grid gap-2 col-lg-3 mx-auto">
            <button class="btn btn-primary btn-lg waves-effect waves-light" type="submit">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</div>
</form>
    
            </div>
           
            <Footer/>

  
 
</div>
</div>
           
            
</div>



</div>
</div>
  );
}

export { EditAdmintype };
