import React, { useEffect,useState } from "react";
import config from "../config";
import axios from "axios";
import { useNavigate,Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader"; 
import "./login.css";
import { Header } from "./header";
import { Aside } from "./aside";
import { Footer } from "./footer";
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import moment from 'moment-timezone';
function Message() {
  const navigate = useNavigate();
  let   [loadingInProgress, setLoading] = useState(false);
  const [message, setMessage] = useState([]);
 
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    message: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    title: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    status: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
   
});
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
};
const renderHeader = () => {
  return (
    <div class="row">
<div class="col-md-6">

            
              <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search....." />
       
 
</div>
<div class="col-md-6">
<Link class="dt-button add-new btn btn-primary ms-2 ms-sm-0" tabindex="0" aria-controls="DataTables_Table_0" to={config.front_URL +"/addMessage"} ><span><i class="fa fa-plus me-0 me-sm-1 ti-xs"></i><span class="d-none d-sm-inline-block">Add</span></span></Link></div>

    </div>
    
  );
};
const header = renderHeader();
const rowClass = (data) => {
  return {
      'bg-primary': data.category === 'Fitness'
  };
};
  
  const fetchData = () => {
    //e.preventDefault()
    setLoading(true)
    let getloginuser = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        type: 'type',
        
      }),
    };
    return setLoading(true) ,fetch(config.backend_URL + "/getMessage", getloginuser)
    .then((response) => response.json())
    .then((responseJson) => {
      setLoading(false)
          if (responseJson.status) {
            /*        localStorage.setItem('Id',data.data.data.ADMIN_ID); */
           
            setMessage(responseJson.data)
            
            
            //toast(responseJson.msz);
            
            
          
        } else {
          toast(responseJson.msz);
          
          //setLoading(false)
          
        }
      
    })
    .catch((error) => {
      toast('It will take some time!');
      setLoading(false)
      //console.error(error);
    });
  
      setLoading(false)
  };
  const actionBodyTemplate = (rowData) => {
    return (
        <React.Fragment>
<Link class="btn btn-info" to={{ pathname: "/editmessage", search:"?id="+rowData._id}} state={{status:rowData.status,title:rowData.title,message: rowData.message,position: rowData.position,sendTo: rowData.sendTo,pageapp: rowData.page,user: rowData.userId,subpage: rowData.subpageKey}} ><i class="fa fa-pencil" aria-hidden="true"></i></Link>&nbsp;
            {/* <Link   className="btn btn-info"  to={config.front_URL +"/editmessage?id="+rowData._id}><i class="fa fa-pencil"></i></Link>&nbsp; */}
            <button class="btn btn-danger" type="button" onClick={(e) => deleteData(e,rowData._id)}><i class="fa fa-trash" aria-hidden="true"></i></button>
            
        </React.Fragment>
    );
  };
  const deleteData = async (e,id) => {
    e.preventDefault();
   //alert(id)
  
   let getloginuser = {
     method: "POST",
     headers: {
       Accept: "application/json",
       "Content-Type": "application/json",
     },
     body: JSON.stringify({
       id: id,
      
       
     }),
   };
   return setLoading(true) ,fetch(config.backend_URL + '/deleteMessage', getloginuser)
   .then((response) => response.json())
   .then((responseJson) => {
     setLoading(false)
     //console.log(responseJson);
         if (responseJson.status) {
           toast(responseJson.msz);
           fetchData();
       } else {
         toast(responseJson.msz);
         
         //setLoading(false)
         
       }
     
   })
   .catch((error) => {
     console.error(error);
     toast('It will take some time!');
     setLoading(false)
   });
  };
  const actionBodyTemplate3 = (rowData) => {
    //console.log(index)
   
      return (
        <React.Fragment>
      
         { moment(rowData.createdDate).tz("Israel").format("DD/MM/YYYY h:m")}
        </React.Fragment>
      )
              
     
    
    };
  useEffect(() => {
    //setLoading(true) ;
    fetchData();
  }, []);
  return (
    <div className="container-scroller">
    {loadingInProgress ? <div className="parentdiv"><div className="loaderclsdiv"><ClipLoader className="loadercls" text-align="center" color={'#000'} loading={loadingInProgress}  size={35} /></div></div>: ""}
    {" "}
    <ToastContainer />
  
    <div class="layout-wrapper layout-content-navbar">
    <div class="layout-container">
  
<Aside/>
<div class="layout-page">


<Header/>
    
    <div class="content-wrapper">


<div class="container-xxl flex-grow-1 container-p-y">
  
  

<h4 class="py-3 mb-4">
<span class="text-muted fw-light">Message</span> 
</h4>






<div class="card">

<div class="card-datatable table-responsive">
  <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap5 no-footer">
  
  <DataTable showGridlines rowClassName={rowClass} header={header} globalFilterFields={['message','title','status']} dataKey="id"  filters={filters} filterDisplay="row" value={message} tableStyle={{ minWidth: '10rem'}} paginator  rows={10} rowsPerPageOptions={[5, 10, 25, 50]}  emptyMessage="No customers found.">
  <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '2rem',borderTop: "1px solid #ADB5BD" }}></Column>
    <Column field="_id" sortable header="Id" style={{ minWidth: '2rem',borderTop: "1px solid #ADB5BD",padding:"10px" }}></Column>
    <Column field="status" sortable header="Status" style={{ minWidth: '2rem',borderTop: "1px solid #ADB5BD",padding:"10px" }}></Column>
    <Column field="title" sortable header="Message Title" style={{ minWidth: '2rem',borderTop: "1px solid #ADB5BD",padding:"10px" }}></Column>
    <Column field="message" sortable header="Message Type" style={{ minWidth: '2rem',borderTop: "1px solid #ADB5BD",padding:"10px" }}></Column>
    
    <Column field="createdDate" body={actionBodyTemplate3} sortable header="Created" style={{ minWidth: '2rem',borderTop: "1px solid #ADB5BD",padding:"10px" }}></Column>
   
</DataTable>



                                    
                                    
    </div>
  </div>
</div>


  </div>
 

  
  



<Footer/>

  
 
</div>
</div>
           
            
</div>



</div>
</div>
   
    
  );
}

export { Message };
