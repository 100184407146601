import React, { useState } from "react";
import config from "../config";
import axios from "axios";
import { useNavigate,Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader"; 
import "./login.css";

function Forgotpassword() {
  const navigate = useNavigate();
  let   [loadingInProgress, setLoading] = useState(false);
  const [email, setemail] = useState("");
  const [password, setPassword] = useState("");
 
 
  let handleSubmit =  (e) => {
    e.preventDefault()
   setLoading(true) ;
   // navigate("/user");
    
    let getloginuser = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        
      }),
    };
    fetch(config.backend_URL + "/forgotPassword", getloginuser)
    .then((response) => response.json())
    .then((responseJson) => {
      console.log(responseJson);
      
          setemail("");
         
        

          if (responseJson.status) {
            /*        localStorage.setItem('Id',data.data.data.ADMIN_ID); */
            setLoading(false)
            
            
            toast(responseJson.msz);
            
            //navigate("/user");
          
        } else {
          toast(responseJson.msz);
          e.target.reset();
          setLoading(false)
          
        }
      
    })
    .catch((error) => {
      toast('It will take some time!');
      setLoading(false)
      console.error(error);
    });
    
  };

  return (
    <div className="container-scroller">
      {loadingInProgress ? <div className="parentdiv"><div className="loaderclsdiv"><ClipLoader className="loadercls" text-align="center" color={'#000'} loading={loadingInProgress}  size={35} /></div></div>: ""}
      {" "}
      <ToastContainer />
      <div class="authentication-wrapper authentication-cover authentication-bg">
  <div class="authentication-inner row">
    
    <div class="d-none d-lg-flex col-lg-7 p-0">
      <div class="auth-cover-bg auth-cover-bg-color d-flex justify-content-center align-items-center">
        <img src="images/unamed.png" alt="auth-login-cover" class="img-fluid my-5 auth-illustration" data-app-light-img="illustrations/auth-login-illustration-light.png" data-app-dark-img="illustrations/auth-login-illustration-dark.png"/>

       
      </div>
    </div>
   
    <div class="d-flex col-12 col-lg-5 align-items-center p-sm-5 p-4">
      <div class="w-px-400 mx-auto">
       
        <div class="app-brand mb-4">
          <a href="index.html" class="app-brand-link gap-2">
            <span class="app-brand-logo demo">
<svg width="32" height="22" viewBox="0 0 32 22" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M0.00172773 0V6.85398C0.00172773 6.85398 -0.133178 9.01207 1.98092 10.8388L13.6912 21.9964L19.7809 21.9181L18.8042 9.88248L16.4951 7.17289L9.23799 0H0.00172773Z" fill="#7367F0" />
  <path opacity="0.06" fill-rule="evenodd" clip-rule="evenodd" d="M7.69824 16.4364L12.5199 3.23696L16.5541 7.25596L7.69824 16.4364Z" fill="#161616" />
  <path opacity="0.06" fill-rule="evenodd" clip-rule="evenodd" d="M8.07751 15.9175L13.9419 4.63989L16.5849 7.28475L8.07751 15.9175Z" fill="#161616" />
  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.77295 16.3566L23.6563 0H32V6.88383C32 6.88383 31.8262 9.17836 30.6591 10.4057L19.7824 22H13.6938L7.77295 16.3566Z" fill="#7367F0" />
</svg>
</span>
          </a>
        </div>
        
        <h3 class="mb-1">Welcome to Carbofix 👋</h3>
        <p class="mb-4">Forgot password</p>

        <form onSubmit={(e) => handleSubmit(e)}>
          <div class="mb-3">
            <label for="email" class="form-label">Email</label>
            <input type="email" required class="form-control" id="email" onChange={(e) => setemail(e.target.value)} name="email-username" placeholder="Enter your email" autofocus/>
          </div>
       
          <button class="btn btn-primary d-grid w-100">
            Send
          </button>
          
          
            
           
        </form>
        &nbsp;
        <p class="text-center">
        
          <Link to={config.front_URL +"/"} >
                <span>Back</span>
              </Link>
        </p>
       
      </div>
    </div>

  </div>
</div>

</div>
    
  );
}

export { Forgotpassword };
