import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";



import { Login} from "./component/login";

import { Forgotpassword} from "./component/forgotpassword";
import { Changeforgotpassword} from "./component/changeforgotpassword";
import { Changeforgotpasswordadmin} from "./component/changeforgotpasswordadmin";
import { User} from "./component/user";
import { Presentation} from "./component/presentation";
import { Product} from "./component/product";
import { Traning} from "./component/traning";
import { Form} from "./component/form";
import { Message} from "./component/message";
import { Contact} from "./component/contact";
import { Appcontent} from "./component/appcontent";
import { Inbox} from "./component/inbox";
import { Systemmanager} from "./component/systemmanager";
import { Addcontent} from "./component/addcontent";
import { Editcontent} from "./component/editcontent";
import { AddAdmintype} from "./component/addAdmintype";
import { AddAdmin} from "./component/addAdmin";
import { Addcontact} from "./component/addcontact";
import { Adduser} from "./component/adduser";
import { Edituser} from "./component/edituser";
import { Addproduct} from "./component/addproduct";
import { Editproduct} from "./component/editproduct";
import { Listproductfile} from "./component/listproductfile";
import { Addproductfile} from "./component/addProductfile";
import { AddTraning} from "./component/addTraning";
import { Addform} from "./component/addForm";
import { AddMessage} from "./component/addMessage";
import { Editcontact} from "./component/editcontact";
import { Settings} from "./component/settings";
import { Editproductfile} from "./component/editproductfile";
import { EditTraning} from "./component/editTraning";
import { Editform} from "./component/editform";
import { Editinbox} from "./component/editinbox";
import { EditAdmin} from "./component/editAdmin";
import { EditAdmintype} from "./component/editAdmintype";
import { Userprofile} from "./component/userProfile";
import { EditMessage} from "./component/editMessage";
import { Error} from "./component/error";
import { Dashborad} from "./component/dashborad";
ReactDOM.render( 
  
  <Router>


    <Routes>  
      
     <Route path="/user" element={<User />} /> 
     <Route path="/presentation" element={<Presentation />} /> 
     <Route path="/product" element={<Product />} /> 
     <Route path="/traning" element={<Traning />} /> 
     <Route path="/forms" element={<Form />} /> 
     <Route path="/message" element={<Message />} /> 
     <Route path="/contact" element={<Contact />} /> 
     <Route path="/appcontent" element={<Appcontent />} /> 
     <Route path="/inbox" element={<Inbox />} /> 
     <Route path="/systemmanager" element={<Systemmanager />} /> 
     <Route path="/" element={<Login />} />
     <Route path="/login" element={<Login />} />
     <Route path="/fogotpassword" element={<Forgotpassword />} /> 
     <Route path="/changeforgotpassword" element={<Changeforgotpassword />} /> 
     <Route path="/changeforgotpasswordadmin" element={<Changeforgotpasswordadmin />} /> 
     <Route path="/addcontent" element={<Addcontent />} /> 
     <Route path="/editcontent" element={<Editcontent />} /> 
     <Route path="/addAdmintype" element={<AddAdmintype />} /> 
     <Route path="/addAdmin" element={<AddAdmin />} /> 
     <Route path="/addContact" element={<Addcontact />} /> 
     <Route path="/adduser" element={<Adduser />} /> 
     <Route path="/edituser" element={<Edituser />} /> 
     <Route path="/addproduct" element={<Addproduct />} /> 
     <Route path="/editproduct" element={<Editproduct />} /> 
     <Route path="/listproductfile" element={<Listproductfile />} /> 
     <Route path="/addProductfile" element={<Addproductfile />} /> 
     <Route path="/addTraning" element={<AddTraning />} /> 
     <Route path="/addForm" element={<Addform />} /> 
     <Route path="/addMessage" element={<AddMessage />} /> 
     <Route path="/editcontact" element={<Editcontact />} /> 
     <Route path="/settings" element={<Settings />} /> 
     <Route path="/editProductfile" element={<Editproductfile />} /> 
     <Route path="/editTraning" element={<EditTraning />} /> 
     <Route path="/editForm" element={<Editform />} /> 
     <Route path="/viewinbox" element={<Editinbox />} /> 
     <Route path="/editadmin" element={<EditAdmin />} /> 
     <Route path="/editadmintype" element={<EditAdmintype />} /> 
     <Route path="/userprofile" element={<Userprofile />} /> 
     <Route path="/editmessage" element={<EditMessage />} /> 
     <Route path="/dashboard" element={<Dashborad />} /> 
    </Routes>
  </Router>,

  document.getElementById("root")
);


