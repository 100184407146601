import React, { useEffect,useState } from "react";
import config from "../config";
import axios from "axios";
import { useNavigate,Link,useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader"; 
import "./login.css";
import { Header } from "./header";
import { Aside } from "./aside";
import { Footer } from "./footer";
import CKEditor from "react-ckeditor-component";

function Userprofile() {
  const navigate = useNavigate();
  const location = useLocation()
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  let   [loadingInProgress, setLoading] = useState(false);
  const [type, setType] = useState(location.state.type);
  const [typedata, setTypedata] = useState([]);
  const [status, setStatus] = useState(location.state.status);
  const [firstname, setFirstName] = useState(location.state.firstName);
  const [lastname, setLastname] = useState(location.state.lastName);
  const [email, setEmail] = useState(location.state.email);
  const [phone, setPhone] = useState(location.state.phone);
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmpassword] = useState("");
 
 

  let handleSubmit =  (e) => {
    e.preventDefault()
   setLoading(true) ;
   // navigate("/user");
   
    
    let getloginuser = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        type: type,
        status: status,
        firstname: firstname,
        lastname: lastname,
        email: email,
        phone: phone,
        id: id,
        
      }),
    };
    fetch(config.backend_URL + "/editAdmin", getloginuser)
    .then((response) => response.json())
    .then((responseJson) => {
      console.log(responseJson);
      
        
        

          if (responseJson.status) {
            /*        localStorage.setItem('Id',data.data.data.ADMIN_ID); */
            setLoading(false)
          //e.target.reset();
            
          localStorage.setItem("UserData", JSON.stringify(responseJson.data)); 
            toast(responseJson.msz);
            
            
          
        } else {
          toast(responseJson.msz);
         // e.target.reset();
          setLoading(false)
          
        }
      
    })
    .catch((error) => {
      toast('It will take some time!');
      setLoading(false)
      console.error(error);
    });


    
  };
  let handleSubmit1 =  (e) => {
    e.preventDefault()
   setLoading(true) ;
   // navigate("/user");
    if(password != confirmpassword){
      setLoading(false) ;
      toast('Password & Confirm Password not matched!');
      return false;
    }
    
    let getloginuser = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
      
        password: password,
        id: id,
        
      }),
    };
    fetch(config.backend_URL + "/editpasswordadmin", getloginuser)
    .then((response) => response.json())
    .then((responseJson) => {
      console.log(responseJson);
      
        
        

          if (responseJson.status) {
            /*        localStorage.setItem('Id',data.data.data.ADMIN_ID); */
            setLoading(false)
          e.target.reset();
            
            
            toast(responseJson.msz);
            
            
          
        } else {
          toast(responseJson.msz);
          e.target.reset();
          setLoading(false)
          
        }
      
    })
    .catch((error) => {
      toast('It will take some time!');
      setLoading(false)
      console.error(error);
    });


    
  };
  const fetchData = async () => {
    //e.preventDefault()
    setLoading(true)
    let getloginuser = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        type: 'type',
        
      }),
    };
    return setLoading(true) ,fetch(config.backend_URL + "/getAdminType", getloginuser)
    .then((response) => response.json())
    .then((responseJson) => {
      setLoading(false)
          if (responseJson.status) {
          
            console.log(responseJson)
            setTypedata(responseJson.data)
           
        } else {
          toast(responseJson.msz);
          
        }
      
    })
    .catch((error) => {
      toast('It will take some time!');
      setLoading(false)
      //console.error(error);
    });
  
      setLoading(false)
  };
  useEffect(() => {
    //setLoading(true) ;
    
    fetchData();
  }, []);
  return (
    <div className="container-scroller">
      {loadingInProgress ? <div className="parentdiv"><div className="loaderclsdiv"><ClipLoader className="loadercls" text-align="center" color={'#000'} loading={loadingInProgress}  size={35} /></div></div>: ""}
      {" "}
      <ToastContainer />
    
      <div class="layout-wrapper layout-content-navbar">
      <div class="layout-container">
    
<Aside/>
<div class="layout-page">


<Header/>
<div class="content-wrapper">


<div class="container-xxl flex-grow-1 container-p-y">
  
  


<div class="row">
    <div class="col-md-6">
        <h4 class="py-3 mb-4">
<span class="text-muted fw-light">User Profile</span> 
</h4>
</div>
    <div class="col-md-6">
    {/* <Link class="dt-button add-new btn btn-primary ms-2 ms-sm-0" tabindex="0" aria-controls="DataTables_Table_0" to={config.front_URL +"/systemmanager"}><span><span class="d-none d-sm-inline-block">Back</span></span></Link> */}
    </div>
</div>


<form onSubmit={(e) => handleSubmit(e)}>
<div class="row">
<div class="col-md-12">
    <div class="card mb-4">
      {/* <h5 class="card-header">Add Content</h5> */}
      <div class="card-body">
        <div class="row mrgBtm4">
              <div class="col-md-6">
                <div>
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Status</label>
          <select required class="form-control"  onChange={(e) => setStatus(e.target.value)} value={status}>
            <option value="">Select Status</option>
            {/* <option value="welcome">Welcome Page</option> */}
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>

          </select>
          {/* <input type="text" required class="form-control" placeholder="Enter Type" onChange={(e) => setType(e.target.value)}/> */}
          
        </div>
        </div>
              <div class="col-md-6">
              <div>
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>User Type</label>
          <select required class="form-control"  onChange={(e) => setType(e.target.value)} value={type}>
            <option value="">Select Type</option>
            {/* <option value="welcome">Welcome Page</option> */}
            {typedata.map((typekey, index) => {
                            return (
                              <>
            <option value={typekey.type}>{typekey.type}</option>
            
            </>
            );
          })}
          </select>
          {/* <input type="text" required class="form-control" placeholder="Enter Type" onChange={(e) => setType(e.target.value)}/> */}
          
        </div>
              </div>
        </div>
        
        
        
        <div class="row mrgBtm4">
          <div class="col-md-6">
          <div>
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>First name</label>
          <input type="text" required class="form-control" placeholder="Enter First Name" onChange={(e) => setFirstName(e.target.value)} value={firstname}/>
          
        </div>
          </div>
          <div class="col-md-6">
          <div>
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Last name</label>
          <input type="text" required class="form-control" placeholder="Enter Last Name" onChange={(e) => setLastname(e.target.value)} value={lastname}/>
          
        </div>
          </div>
        </div>
       
        <div class="row mrgBtm4">
          <div class="col-md-6">
          <div>
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Phone</label>
          <input type="number" required class="form-control" placeholder="Enter Phone" onChange={(e) => setPhone(e.target.value)} value={phone}/>
          
        </div>
          </div>
          <div class="col-md-6">
          <div>
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Email</label>
          <input type="email" required class="form-control" placeholder="Enter Email" onChange={(e) => setEmail(e.target.value)} value={email}/>
          
        </div>
          </div>
        </div>
        <div class="row mt-3">
          <div class="d-grid gap-2 col-lg-6 mx-auto">
            <button class="btn btn-primary btn-lg waves-effect waves-light" type="submit">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</div>
</form>
<form onSubmit={(e) => handleSubmit1(e)}>
<div class="row">
<div class="col-md-12">
    <div class="card mb-4">
      {/* <h5 class="card-header">Add Content</h5> */}
      <div class="card-body">
       
        
        
        
       
       
        
        <div class="row mrgBtm4">
          <div class="col-md-6">
          <div>
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Password</label>
          <input type="password" required class="form-control" placeholder="Enter Password" onChange={(e) => setPassword(e.target.value)}/>
          
        </div>
          </div>
          <div class="col-md-6">
          <div>
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Confirm Password</label>
          <input type="password" required class="form-control" placeholder="Enter Confirm password" onChange={(e) => setConfirmpassword(e.target.value)}/>
          
        </div>
          </div>
        </div>
      
        
        
       
        

        <div class="row mt-3">
          <div class="d-grid gap-2 col-lg-6 mx-auto">
            <button class="btn btn-primary btn-lg waves-effect waves-light" type="submit">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</div>
</form>
            </div>
           
            <Footer/>

  
 
</div>
</div>
           
            
</div>



</div>
</div>
  );
}

export { Userprofile };
