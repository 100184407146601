module.exports = {
    "login_secret":{
      'key':'pokemon'
    },
    'backend_URL1': "https://carbofixspine.shtibel.com:3000/",
    'backend_URL': "https://carbofixspine.shtibel.com:3000/admin",
    //'front_URL': "http://localhost:3000",
    'front_URL': "https://carbofixspine.shtibel.com",
    //'backend_URL': "http://localhost:8080"
    
  }