import React, { useEffect,useState } from "react";
import config from "../config";
import axios from "axios";
import { useNavigate,Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader"; 
import "./login.css";
import { Header } from "./header";
import { Aside } from "./aside";
import { Footer } from "./footer";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
function Editproduct() {
  const navigate = useNavigate();
  let   [loadingInProgress, setLoading] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  const [product, setProductname] = useState("");
  const [category, setCategory] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [description, setDescription] = useState("");
  const [productImage, setProductimage] = useState("");
  const [productImage1, setProductimage1] = useState("");
  
  let handleSubmit =  (e) => {
    e.preventDefault();
    setLoading(true)
    let formData = new FormData(); //formdata object
    formData.append("IMAGE", productImage);
    formData.append("productName", product);
    formData.append("categoryId", categoryId);
    formData.append("categoryName", categoryName);
    formData.append("description", description);
    formData.append("id", id);
    formData.append("productImage1", productImage1);
  
      axios.post(`${config.backend_URL}/editProduct`, formData)
        .then( (data) => {
         
          setLoading(false)
          toast(data.data.msz);
          e.target.reset();
        }).catch((err) => {
          console.log("error", err);
        });
  };
  
  const fetchData = () => {
    //e.preventDefault()
    setLoading(true)
    let getloginuser = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        type: 'type',
        
      }),
    };
    return setLoading(true) ,fetch(config.backend_URL + "/getCategory", getloginuser)
    .then((response) => response.json())
    .then((responseJson) => {
      setLoading(false)
      console.log(responseJson);
          if (responseJson.status) {
            setCategory(responseJson.data)
        } else {
          toast(responseJson.msz);
        }
      
    })
    .catch((error) => {
      toast('It will take some time!');
      setLoading(false)
      //console.error(error);
    });
  
      setLoading(false)
  };
  const fetchData1 = () => {
    //e.preventDefault()
    setLoading(true)
    let getloginuser = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: id,
        
      }),
    };
    return setLoading(true) ,fetch(config.backend_URL + "/getProductdata", getloginuser)
    .then((response) => response.json())
    .then((responseJson) => {
      setLoading(false)
      console.log(responseJson.data)
          if (responseJson.status) {
            /*        localStorage.setItem('Id',data.data.data.ADMIN_ID); */
           
            setProductname(responseJson.data[0].productName)
            
            setCategoryId(responseJson.data[0].categoryId)
            setCategoryName(responseJson.data[0].categoryName)
            setDescription(responseJson.data[0].description)
            setProductimage1(responseJson.data[0].productImage)
           
            
            
            //toast(responseJson.msz);
            
            
          
        } else {
          toast(responseJson.msz);
          
          //setLoading(false)
          
        }
      
    })
    .catch((error) => {
      toast('It will take some time!');
      setLoading(false)
      //console.error(error);
    });

      setLoading(false)
  };
  const onFileChange = async (e) => {
    e.preventDefault();
      setProductimage(e.target.files[0]);
  };
  const handleClick = e => {
    setCategoryId(e.target.value)
    
    setCategoryName(e.target[e.target.selectedIndex].getAttribute('data-value'));

   
  };
  useEffect(() => {
    //setLoading(true) ;
    fetchData();
    fetchData1();
  }, []);
  return (
    <div className="container-scroller">
      {loadingInProgress ? <div className="parentdiv"><div className="loaderclsdiv"><ClipLoader className="loadercls" text-align="center" color={'#000'} loading={loadingInProgress}  size={35} /></div></div>: ""}
      {" "}
      <ToastContainer />
    
      <div class="layout-wrapper layout-content-navbar">
      <div class="layout-container">
    
<Aside/>
<div class="layout-page">


<Header/>
<div class="content-wrapper">


<div class="container-xxl flex-grow-1 container-p-y">
  
  
<div class="row">
<div class="col-md-6">
<h4 class="py-3 mb-4">
<span class="text-muted fw-light">Edit Product</span> 
</h4>

</div>
<div class="col-md-6" >

<Link class="dt-button add-new btn btn-primary ms-2 ms-sm-0" tabindex="0" aria-controls="DataTables_Table_0" to={config.front_URL +"/product"}><span><span class="d-none d-sm-inline-block">Back</span></span></Link>
</div>
</div>

    <form onSubmit={(e) => handleSubmit(e)}>
<div class="row">
<div class="col-md-12">
    <div class="card mb-4">
      {/* <h5 class="card-header">Add Content</h5> */}
      <div class="card-body">
        <div class="row">
              <div class="col-md-6">
                <div>
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Category</label>
          <select required class="form-control"  onChange={(e) => handleClick(e) } value={categoryId}>
            <option value="">Select Category</option>
            {category.map((category) => {
                            return (
<option value={category._id} data-value={category.name}>{category.name}</option>
                              );
                            })}

          </select>
          {/* <input type="text" required class="form-control" placeholder="Enter Type" onChange={(e) => setType(e.target.value)}/> */}
          
        </div>
        </div>
        <div class="col-md-6">
                <div>
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Product Name</label>
        
          <input type="text" required class="form-control" value={product} placeholder="Enter Product Name" onChange={(e) => setProductname(e.target.value)}/>
          
        </div>
        </div>
             
        </div>
        <div class="row">
              <div class="col-md-12">
                <div>
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Description</label>
          
          <input type="text" required class="form-control" value={description} placeholder="Write Here......" onChange={(e) => setDescription(e.target.value)}/>
          
        </div>
        </div>
              
        </div>
        <div class="row">
              <div class="col-md-3">
                <div>
          <label for="defaultFormControlInput" class="form-label"><span style={{color:'red'}}>*</span>Product Image</label>
          
          <input type="file"  class="form-control"  onChange={(e) => onFileChange(e)}/>
          
        </div>
        <label for="defaultFormControlInput" class="form-label">Image</label><br/>
          <img src={productImage1} width="100px"/>
        </div>
        
              
        </div>
        
        
      

      
      

        <div class="row mt-3">
          <div class="d-grid gap-2 col-lg-6 mx-auto">
            <button class="btn btn-primary btn-lg waves-effect waves-light" type="submit">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</div>
</form>
    

    
            </div>
           
            <Footer/>

  
 
</div>
</div>
           
            
</div>



</div>
</div>
  );
}

export { Editproduct };
