import React, { useEffect,useState } from "react";
import config from "../config";
import axios from "axios";
import { useNavigate,Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader"; 
import "./login.css";
import { Header } from "./header";
import { Aside } from "./aside";
import { Footer } from "./footer";
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import moment from 'moment-timezone';
import { confirmAlert } from "react-confirm-alert";
import { Button } from 'primereact/button';
function Dashborad() {
  const navigate = useNavigate();
  let   [loadingInProgress, setLoading] = useState(false);
  const [email, setemail] = useState("");
  let   [user, setuser] = useState([]);
 
  const [globalFilterValue, setGlobalFilterValue] = useState('');
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    firstName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    lastName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    phone: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    country: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    companyName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    device: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    version: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
   
});
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
};
  const fetchData = () => {
    //e.preventDefault()
    setLoading(true)
    let getloginuser = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        type: 'type',
        
      }),
    };
    return setLoading(true) ,fetch(config.backend_URL + "/getuserdataforadmin", getloginuser)
    .then((response) => response.json())
    .then((responseJson) => {
      setLoading(false)
          if (responseJson.status) {
            /*        localStorage.setItem('Id',data.data.data.ADMIN_ID); */
           
            setuser(responseJson.data)
            
            
            //toast(responseJson.msz);
            
            
          
        } else {
          toast(responseJson.msz);
          
          //setLoading(false)
          
        }
      
    })
    .catch((error) => {
      toast('It will take some time!');
      setLoading(false)
      //console.error(error);
    });

      setLoading(false)
  };
  const confirmit = (e,id) => {
    if (window.confirm("Are you sure you want to delete this record ?")) {
      deleteData(id)
    }}
    const deleteData = async (id) => {
      //e.preventDefault();
     //alert(ids)
    
     let getloginuser = {
       method: "POST",
       headers: {
         Accept: "application/json",
         "Content-Type": "application/json",
       },
       body: JSON.stringify({
         id: id,
         
         
       }),
     };
     return setLoading(true) ,fetch(config.backend_URL +'/deleteUserProfile',getloginuser)
     .then((response) => response.json())
     .then((responseJson) => {
       setLoading(false)
       //console.log(responseJson);
       fetchData();
           if (responseJson.status) {
             toast(responseJson.msz);
         } else {
           toast(responseJson.msz);
           
           //setLoading(false)
           
         }
       
     })
     .catch((error) => {
       console.error(error);
       toast('It will take some time!');
       setLoading(false)
     });
    };
  const actionBodyTemplate = (rowData) => {
    return (
        <React.Fragment>
            <Link icon="fa fa-pencil" rounded outlined className="btn btn-primary"  to={config.front_URL +"/edituser?id="+rowData._id}><i class="fa fa-pencil"></i></Link>
            <button  className="btn btn-danger"  type="button" onClick={(e) => confirmit(e,rowData._id)} ><i class="fa fa-trash"></i></button>
        </React.Fragment>
    );
};
  const renderHeader = () => {
    return (
      <div class="row">
<div class="col-md-6">

              
                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search....." />
         
   
</div>
<div class="col-md-6">
<Link class="dt-button add-new btn btn-primary ms-2 ms-sm-0" tabindex="0" aria-controls="DataTables_Table_0" to={config.front_URL +"/adduser"} ><span><i class="fa fa-plus me-0 me-sm-1 ti-xs"></i><span class="d-none d-sm-inline-block">Add</span></span></Link></div>

      </div>
      
    );
};
const actionBodyTemplate1 = (rowData) => {

  return (
      <React.Fragment>
        
           <span class={rowData.status == 0?"badge bg-label-success":(rowData.status == 1)?"badge bg-label-warning":"badge bg-label-danger"} text-capitalized="">{rowData.status == 0?"Active":(rowData.status == 1)?"Pending":"Suspending"}</span>
          
      </React.Fragment>
  );
};

const actionBodyTemplate2 = (rowData,index) => {
//console.log(index)
let index2 = parseInt(index.rowIndex + 1);
  return parseInt(index2)
          
 

};
const actionBodyTemplate3 = (rowData) => {
  //console.log(index)
 
    return (
      <React.Fragment>
    
       { moment(rowData.dateTime).tz("Israel").format("DD/MM/YYYY h:m")}
      </React.Fragment>
    )
            
   
  
  };
  const actionBodyTemplate4 = (rowData) => {
    //console.log(index)
   
      return (
        <React.Fragment>
          { moment(rowData.registerDateTime).tz("Israel").format("DD/MM/YYYY h:m")}
        </React.Fragment>
      )
              
     
    
    };
    const actionBodyTemplate5 = (rowData) => {

      return (
          <React.Fragment>
            
               <span>{rowData.firstName} {rowData.lastName}</span>
              
          </React.Fragment>
      );
    };
  //console.log("orders", content);
  useEffect(() => {
    //setLoading(true) ;
    fetchData();
  }, []);
  const header = renderHeader();
  const rowClass = (data) => {
    return {
        'bg-primary': data.category === 'Fitness'
    };
};
  return (
    <div className="container-scroller">
      {loadingInProgress ? <div className="parentdiv"><div className="loaderclsdiv"><ClipLoader className="loadercls" text-align="center" color={'#000'} loading={loadingInProgress}  size={35} /></div></div>: ""}
      {" "}
      <ToastContainer />
    
      <div class="layout-wrapper layout-content-navbar">
      <div class="layout-container">
    
<Aside/>
<div class="layout-page">


<Header/>
      
      <div class="content-wrapper">


  <div class="container-xxl flex-grow-1 container-p-y">
    
    








<div class="card">
  
  <div class="card-datatable table-responsive">
  
      <h3 class="mb-1">Welcome to Carbofix 👋</h3>

  </div>
</div>


  </div>
 

  
  



<Footer/>

  
 
</div>
</div>
           
            
</div>



</div>
</div>
    
  );
}

export { Dashborad };
